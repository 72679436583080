/* Validate price, do not allow entering characters other than number and decimal */
export function isPriceKey(evt) {
	var charCode = (evt.which) ? evt.which : evt.keyCode;
	if( charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57) ){
		evt.preventDefault();
	}
}

/* Validate spacing, must not be allowed to enter spaces */
export function noSpace(evt) {
	var charCode = (evt.which) ? evt.which : evt.keyCode;
	if( charCode === 32 ){
		evt.preventDefault();
	}
}

/* Generate keywords from a string - group of words such as a sentence */
export function getKeywords(string) {
	var combinationsArray = [];
	var sArray = string.split(' ');
	var n = sArray.length;
	for (var i = 0; i < n; i++) {
		for (var j = 0; j <= i; j++) {
			combinationsArray.push(sArray.slice(j, n - i + j).join(' ').toLowerCase());
		}
	}
	
	return combinationsArray;
}

// export function sameHeights(selector) {
// 	var selector = selector || '[data-key="sameHeights"]',
// 		query = document.querySelectorAll(selector),
// 		elements = query.length,
// 		max = 0;
// 	if (elements) {
// 		while (elements--) {
// 			var element = query[elements];
// 			if (element.clientHeight > max) {
// 				max = element.clientHeight;
// 			}
// 		}
// 		elements = query.length;
// 		while (elements--) {
// 			var element = query[elements];
// 			element.style.height = max + 'px';
// 		}
// 	}
// }

export function matchHeight(){
	//Grab divs with the class name 'match-height'
	var getDivs = document.getElementsByClassName('match-height');
  
	//Find out how my divs there are with the class 'match-height' 
	var arrayLength = getDivs.length;
	var heights = [];
  
	//Create a loop that iterates through the getDivs variable and pushes the heights of the divs into an empty array
	for (var i = 0; i < arrayLength; i++) {
		heights.push(getDivs[i].offsetHeight);
	}
  
	 //Find the largest of the divs
	function getHighest() {
	  return Math.max(...heights);
	}
  
	//Set a variable equal to the tallest div
	var tallest = getHighest();
  
	//Iterate through getDivs and set all their height style equal to the tallest variable
	for (var i = 0; i < getDivs.length; i++) {
		getDivs[i].style.height = tallest + "px";
	}
	
  };

  // The polling function
export function poll(fn, timeout, interval) {
    var endTime = Number(new Date()) + (timeout || 2000);
    interval = interval || 100;

    var checkCondition = function(resolve, reject) {
        // If the condition is met, we're done! 
        var result = fn();
        if(result) {
            resolve(result);
        }
        // If the condition isn't met but the timeout hasn't elapsed, go again
        else if (Number(new Date()) < endTime) {
            setTimeout(checkCondition, interval, resolve, reject);
        }
        // Didn't match and too much time, reject!
        else {
            reject(new Error('timed out for ' + fn + ': ' + arguments));
        }
    };

    return new Promise(checkCondition);
}


export function convertDate(dateString){
	if (!dateString) return;
	var p = dateString.split(/\D/g)
	return [p[2],p[1],p[0] ].join("/")
}



export function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}


export function onAddToCart(productObject, cart, cartRef) {
	const { id, product, authUser, shippingType, quantity, discount } = productObject;
	// console.log('on add to cart ', {
	// 	discount,
	// 	product,
	// 	quantity,
	// });
	
	if(authUser){ 
		let is_existing = Object.keys(cart).length && Object.values(cart).find(item => id === item.id); /* Check if item already exists in cart from state */
		if(!is_existing){
			// let price = product.salePrice > 0 ? product.salePrice : product.price;
			// if (discount) {
			// 	console.log('price ', price);
			// 	console.log('discount ', discount);
			// 	let t = percentage(price, discount);
			// 	console.log(t);
			// 	price = price - (price * parseFloat(`.${discount}`));
			// 	console.log(price);
			// }
			let newItem = {
				'id': id, 
				'quantity': quantity ? quantity : '1', 
				'sellerID': product.author, 
				'purchasePrice': product.salePrice > 0 ? product.salePrice : product.price, 
				'discount': discount ? discount : 0,
				'shippingType': shippingType, 
				'shippingValue': product[shippingType] > 0 ? product[shippingType] : 0 /* Get shipping option value base on key */
			};
			let updatedCart = Object.values(cart); /* Clone it first */
			
			/* Push new cart item */
			updatedCart.push(newItem); 
			
			/* Set updated cart in firebase, no need to use setState because we already have a realtime cart listener in the componentDidMount() */
			// cartRef.doc(authUser.uid).set(Object.assign({}, updatedCart)).then(() => {
			// 	cogoToast.success("Added to cart");
			// });

			// ReactGA.event({
			// 	category: 'Products',
			// 	action: 'Click Add To Bag',
			// 	label: `${product.name} for ${quantity} tickets ${discount ? `with ${discount}% discount` : ''}`,
			// });

			
			let totalPrice = 0;
			let tempOgTotal = parseFloat(newItem.purchasePrice * newItem.quantity).toFixed(2);
			if (newItem.discount) {
				totalPrice = parseFloat(tempOgTotal / 100 * (100 - newItem.discount)).toFixed(2);
			} else {
				totalPrice = tempOgTotal;
			}

			let tempProd = product;
			tempProd.id = id;

			// ReactPixel.track('AddToCart', {
			// 	id: id,
			// 	content_ids: [id],
			// 	content_name: product.name,
			// 	content_type: "product",
			// 	// contents: tempProd,
			// 	value: parseFloat(totalPrice).toFixed(2),
			// 	currency: 'GBP',
			// 	product_catalog_id: '2777510749188479',
			// });

			
			// const updateCatologuItem = async () => {
			// 	let response = await fetch('https://graph.facebook.com/<API_VERSION>/2777510749188479/batch?requests=<REQUESTS>')
			// }

		}
		
	} else {
		// this.props.history.push(ROUTES.SIGN_IN);
		// cogoToast.info("Sign in or register to continue");
		
		// ReactGA.event({
		// 	category: 'Products',
		// 	action: 'Click Add To Bag',
		// 	label: `Logged out user clicked add to bag`
		// });

	}
}

// Set the date we're counting down to
// var countDownDate = new Date("Jan 5, 2021 15:37:25").getTime();

// // Update the count down every 1 second
// var x = setInterval(function() {

//   // Get today's date and time
//   var now = new Date().getTime();

//   // Find the distance between now and the count down date
//   var distance = countDownDate - now;

//   // Time calculations for days, hours, minutes and seconds
//   var days = Math.floor(distance / (1000 * 60 * 60 * 24));
//   var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
//   var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
//   var seconds = Math.floor((distance % (1000 * 60)) / 1000);

//   // Display the result in the element with id="demo"
//   document.getElementById("demo").innerHTML = days + "d " + hours + "h "
//   + minutes + "m " + seconds + "s ";

//   // If the count down is finished, write some text
//   if (distance < 0) {
//     clearInterval(x);
//     document.getElementById("demo").innerHTML = "EXPIRED";
//   }
// }, 1000);