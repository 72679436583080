import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';
import cogoToast from 'cogo-toast';
import ReactPixel from 'react-facebook-pixel';
import Slider from "react-slick";
import parse from 'html-react-parser';
import ReactGA from 'react-ga';

// const firebase = require("firebase");
// Required for side-effects
require("firebase/functions");

const percentage = (percent, total) => {
	return ((percent/ 100) * total).toFixed(2)
}

const showDiscount = (total, discount) => {
	return total - percentage(discount, total);
}



class CheckoutSuccess extends Component {
	constructor(props) {
		super(props);
		this.state = {
			order: {},
			loading: true,
			authUser: JSON.parse(localStorage.getItem('authUser')),
			settings: JSON.parse(localStorage.getItem('settings')),
		};
	}

	
	
	componentDidMount() {
		setTimeout(() => {
			let order_id = this.props.match.params.id;
			let user_id = this.state.authUser.uid;
			
			
			const { order } = this.state;
			const { emailData } = order;	
			

			

			if(order_id){
				document.title = 'Success #' + order_id;
				

				/* this.props.firebase.order() -- the "order" is taken from firebase.js  */
				this.props.firebase.order(order_id).get().then(snapshot => { /* Use get() to only call it once (disable realtime) */
					const orderObject = snapshot.data();
					
					if(orderObject && orderObject.customerID === user_id){
						this.setState({
							order: orderObject,
							orderID: order_id,
							loading: false,
							cart: orderObject.cart,
						});
						
					} else {
						
						this.props.history.push(ROUTES.NOT_FOUND);
					}

				});
				


				// // Send email
				// let mailRef = this.props.firebase.mail();

				// // const budgets = arrayOfBudget.map((obj)=> {return Object.assign({}, obj)});

				// let mailDoc = mailRef.doc();
				// console.log('mail doc ', mailDoc);
				// mailDoc.set({
				// 	toUids: [user_id],
				// 	template: {
				// 		name: 'confirmEmail001',
				// 		data: emailData.map(data => Object.assign({}, data)),
				// 	}
				// })


			}

			this.mounted = true; 

			if (this.mounted) {
				this.loadProducts(false, true);
			}
		}, 2000);

		ReactGA.ga('send', 'pageview', '/success');
	}

	loadProducts(loadmore, fromComponent) {
		const { settings, lastItem, searchKeyword, searchCategory, searchFilter, searchEnabled, justAcitve, justEnded, showBadge } = this.state;
		const self = this;
		
		var pageLimit = settings && settings.perPageCollections ? parseInt(settings.perPageCollections) : 8;
		
		this.setState({ loadingBtn: true });
		
		/* Load first */
		var productQuery = this.props.firebase.products();
		
		// Only active comps
		// productQuery = productQuery.where('winnerChosen', '==', false).orderBy('stock');
		productQuery = productQuery.where('stock', '>', 0).orderBy('stock');

		// productQuery = productQuery.orderBy('highlight');
		productQuery.get().then(function(querySnapshot) {      
			self.setState({
				totalCount: querySnapshot.size ? querySnapshot.size : 0,
			});
		});
 
		productQuery.onSnapshot(snapshot => { /* The onSnapshot() method registers a continuous listener that triggers every time something has changed, use get() to only call it once (disable realtime) */
			let productChunk = [];
			
			snapshot.docChanges().forEach(function(change) {
				if (change.type === "added") {
					/* Add more items to the screen... */
					productChunk.push({ ...change.doc.data(), pid: change.doc.id });
				} else if (change.type === "modified") {
					/* If there is a change in realtime... */
					/* Apply the modification to the item directly without changing the current item index. */
					self.setState({
						products: self.state.products.map(el => (el.pid === change.doc.id ? {...change.doc.data(), pid: change.doc.id} : el)),
					});
				}

			});

			this.setState((prevState) => ({
				products: prevState.products && fromComponent ? [...prevState.products, ...productChunk]: productChunk,
				// loading: false,
				// loadingBtn: false,
				// lastItem: snapshot.docs[snapshot.docs.length - 1], 
				// showMoreBtn: productChunk.length < pageLimit ? false : true, 
			}));
			
		});


	}

	onAddToCart(productObject) {
		// console.log({productObject})
		// const { id, product, shippingType, quantity, discount } = productObject;
		const id = productObject.pid;
		const product = productObject;
		const quantity = 1;
		const discount = 0;
		const shippingType = 'Free'
		const { cart, authUser } = this.state;
		// console.log('on add to cart ', {
		// 	discount,
		// 	product,
		// 	quantity, 
		// });
		
		if(authUser){ 
			let is_existing = Object.keys(cart).length && Object.values(cart).find(item => id === item.id); /* Check if item already exists in cart from state */
			if(!is_existing){
				// let price = product.salePrice > 0 ? product.salePrice : product.price;
				// if (discount) {
				// 	console.log('price ', price);
				// 	console.log('discount ', discount);
				// 	let t = percentage(price, discount);
				// 	console.log(t);
				// 	price = price - (price * parseFloat(`.${discount}`));
				// 	console.log(price);
				// }
				let newItem = {
					'id': id, 
					'quantity': 1,
					'sellerID': product.author, 
					'purchasePrice': product.salePrice > 0 ? product.salePrice : product.price, 
					'discount': discount ? discount : 0,
					'shippingType': shippingType, 
					'shippingValue': product[shippingType] > 0 ? product[shippingType] : 0 /* Get shipping option value base on key */
				};
				let updatedCart = Object.values(cart); /* Clone it first */
				let cartRef = this.props.firebase.cartItems();
				
				/* Push new cart item */
				updatedCart.push(newItem); 
				
				/* Set updated cart in firebase, no need to use setState because we already have a realtime cart listener in the componentDidMount() */
				cartRef.doc(authUser.uid).set(Object.assign({}, updatedCart)).then(() => {
					cogoToast.success("Added to cart");
				});

				ReactGA.event({
					category: 'Add On',
					action: 'Click',
					label: `${product.name} for 1`,
				});

				
				let totalPrice = 0;
				let tempOgTotal = parseFloat(newItem.purchasePrice * newItem.quantity).toFixed(2);
				if (newItem.discount) {
					totalPrice = parseFloat(tempOgTotal / 100 * (100 - newItem.discount)).toFixed(2);
				} else {
					totalPrice = tempOgTotal;
				}

				let tempProd = product;
				tempProd.id = id;

				ReactPixel.track('AddToCart', {
					id: id,
					content_ids: [id],
					content_name: product.name,
					content_type: "product",
					// contents: tempProd,
					value: parseFloat(totalPrice).toFixed(2),
					currency: 'GBP',
					product_catalog_id: '2777510749188479',
				});

				
				// const updateCatologuItem = async () => {
				// 	let response = await fetch('https://graph.facebook.com/<API_VERSION>/2777510749188479/batch?requests=<REQUESTS>')
				// }

			}
			
		} else {
			this.props.history.push(ROUTES.SIGN_IN);
			cogoToast.info("Sign in or register to continue");
			
			ReactGA.event({
				category: 'Products',
				action: 'Click Add To Bag',
				label: `Logged out user clicked add to bag`
			});

		}
	}
	
	render() {
		const { loading, settings, order, orderID, products } = this.state;
		const { emailData } = order;		// SWAP FOR emailData.....
		// console.log(emailData);
		// console.log('order = ', order);
		// let filteredProducts;
		// if (products) {
		// 	filteredProducts = products.filter((prod) => {
		// 		console.log({prod})
		// 		if (prod && orderID) {
		// 			if (orderID == prod.pid) {
		// 				return prod;
		// 			}
		// 		}
		// 	});
		// }

		
		
		return (
			<div style={{backgroundColor: "#f2f2f2"}}>
				{loading ? <div>Loading...</div> : 
					<div>
						<nav className="navbar navbar-dark bg-blue text-center d-block">
							<span className="navbar-brand d-block text-center mx-0">Order Status</span>
						</nav>
						
						<div className="container-fluid py-3 success">
							<div className="card mb-3">
								<div className="card-body">
									<h3>Be sure to <a href="https://www.facebook.com/hotdrawuk/" targt="_blank">follow us on Facebook</a> to watch our live weekly draws!</h3>
								</div>
							</div>
							<div className="card mb-3">
								<div className="card-body">
												<div>
													<div className="text-center mb-3">
														<h3>Thank you and good luck!</h3>
														<p>We have sent you an email with your order confirmation details below.
															<br />This can sometimes land in the spam folder so be sure to check in there!</p>
														
														<div className="table-responsive">
															<table className="table res-tbl">
																<thead>
																	<tr>
																		<th scope="col">Competition</th>
																		<th scope="col">Ticket Quantity</th>
																		{/* <th scope="col">Shipping</th> */}
																		<th scope="col">Total</th>
																		<th scope="col"></th>
																	</tr>
																</thead>
																<tbody>
																	{emailData.length ? emailData.map((product, index) => (
																		<tr key={index}>
																			<td data-label="Product" className="text-left">
																				<Link to={{pathname: '/products/view/' + product.id}} className="d-block d-lg-flex">
																					<div className="d-inline-block pr-4">
																						<h5>{product.name}</h5>
																					</div>
																					<div className="mr-3 mb-2 mb-lg-0">
																						<img src={`${product.featured_image ? product.featured_image : '/placeholder.jpg'}`} width="200" alt={product.name} />
																					</div>
																				</Link>
																			</td>
																			<td data-label="Quantity">
																				{product.stock > 0 
																					?
																						<div>
																							{/* <input onChange={(event) => this.onChangeQty(event, product.id)} type="number" min="1" max={this.state.maxQty} defaultValue={product.quantity} className="form-control d-xl-inline-block d-inline-block mr-2 mb-2 mb-xl-0" style={{width: "100px"}} /> */}
																							<p className="d-inline">{product.quantity} x {product.quantity > 1 ? 'tickets' : 'ticket'}</p>
																							{/* <span className="text-success f-13">{product.stock} tickets left</span> <br />
																							<span className="text-success f-13">Max {this.state.maxQty}</span> */}
																						</div>
																					: 
																						<span className="text-danger f-13">Out of stock</span>
																				}
																			</td>
																			{/* <td data-label="Shipping">
																				{product.shippingMethod === 'Shipping options' 
																					?
																						<select onChange={(event) => this.onChangeShippingOption(event, product.id)} defaultValue={product.shippingType} className="form-control" style={{maxWidth: '220px'}}>
																							{product.shippingStandard && 
																								<option value="shippingStandard">
																									+{settings && settings.currencySymbol} {product.shippingStandard * product.quantity} on {shippingOptionsObject['shippingStandard']}
																								</option>
																							}
																							{product.shippingExpedited && 
																								<option value="shippingExpedited">
																									+{settings && settings.currencySymbol} {product.shippingExpedited * product.quantity} on {shippingOptionsObject['shippingExpedited']}
																								</option>
																							}
																							{product.shippingSameDayDelivery && 
																								<option value="shippingSameDayDelivery">
																									+{settings && settings.currencySymbol} {product.shippingSameDayDelivery * product.quantity} on {shippingOptionsObject['shippingSameDayDelivery']}
																								</option>
																							}
																						</select>
																					: 
																						<em>{product.shippingMethod}</em>
																				}
																			</td> */}
																			<td data-label="Total">
																				{settings && settings.currencySymbol}{product.discount ? showDiscount((product.purchasePrice * product.quantity) + (product.quantity * product.shippingValue), product.discount) : (product.purchasePrice * product.quantity) + (product.quantity * product.shippingValue)}
																				{product.discount ? <p className="discount">Was <span>{settings && settings.currencySymbol}{(product.purchasePrice * product.quantity) + (product.quantity * product.shippingValue)}</span> {product.discount}% Off</p> : ''}
																			</td>
																			{/* <td data-label="Action"><button onClick={() => this.onRemoveFromCart(product.id)} className="btn btn-dark">Remove</button></td> */}
																		</tr>
																	)) : 
																		<tr>
																			<td>Cart is empty</td>
																		</tr>
																	}
																</tbody>
															</table>
														</div>
														<Link to={{pathname: '/orders/customer/view/' + orderID}} className="text-center underlink font-weight-bold">View Order Details</Link>
													</div>
													<hr />
													<div className="orderTotal text-right font-weight-bold">
														<p>Order Total: £{parseFloat(order.total).toFixed(2)} (including any discounts)</p>
													</div>
													{/* {parse(settings.bankTransferInstructions)} */}
												</div>
									{/* {order && order.paymentOption === 'Bank Transfer' &&
										<div>
											{settings && 
											}
										</div>
									} */}
									
									{order && order.paymentOption === 'Cash On Delivery' && 
										<div>
											{settings && settings.cashOnDeliveryInstructions &&
												<div>
													<div className="text-center mb-3">
														<h3 className="text-success">Order Submitted</h3>
														<Link to={{pathname: '/orders/customer/view/' + orderID}} className="text-center underlink font-weight-bold">View Order Details</Link>
													</div>
													<hr />
													{parse(settings.cashOnDeliveryInstructions)}
												</div>
											}
										</div>
									}
									
									{order && order.paymentOption === 'Paypal' && 
										<div>
										{settings && 
											<div>
												<div className="text-center mb-3">
													<h3>Thank you and good luck!</h3>
													<p>We have sent you an email with your order confirmation details below.
															<br />This can sometimes land in the spam folder so be sure to check in there!</p>
													<div className="table-responsive">
														<table className="table res-tbl">
															<thead>
																<tr>
																	<th scope="col">Competition</th>
																	<th scope="col">Ticket Quantity</th>
																	{/* <th scope="col">Shipping</th> */}
																	<th scope="col">Total</th>
																	<th scope="col"></th>
																</tr>
															</thead>
															<tbody>
																{emailData.length ? emailData.map((product, index) => (
																	<tr key={index}>
																		<td data-label="Product" className="text-left">
																			<Link to={{pathname: '/products/view/' + product.id}} className="d-block d-lg-flex">
																				<div className="d-inline-block pr-4">
																					<h5>{product.name}</h5>
																				</div>
																				<div className="mr-3 mb-2 mb-lg-0">
																					<img src={`${product.featured_image ? product.featured_image : '/placeholder.jpg'}`} width="200" alt={product.name} />
																				</div>
																			</Link>
																		</td>
																		<td data-label="Quantity">
																			{product.stock > 0 
																				?
																					<div>
																						{/* <input onChange={(event) => this.onChangeQty(event, product.id)} type="number" min="1" max={this.state.maxQty} defaultValue={product.quantity} className="form-control d-xl-inline-block d-inline-block mr-2 mb-2 mb-xl-0" style={{width: "100px"}} /> */}
																						<p className="d-inline">{product.quantity} x {product.quantity > 1 ? 'tickets' : 'ticket'}</p>
																						{/* <span className="text-success f-13">{product.stock} tickets left</span> <br />
																						<span className="text-success f-13">Max {this.state.maxQty}</span> */}
																					</div>
																				: 
																					<span className="text-danger f-13">Out of stock</span>
																			}
																		</td>
																		{/* <td data-label="Shipping">
																			{product.shippingMethod === 'Shipping options' 
																				?
																					<select onChange={(event) => this.onChangeShippingOption(event, product.id)} defaultValue={product.shippingType} className="form-control" style={{maxWidth: '220px'}}>
																						{product.shippingStandard && 
																							<option value="shippingStandard">
																								+{settings && settings.currencySymbol} {product.shippingStandard * product.quantity} on {shippingOptionsObject['shippingStandard']}
																							</option>
																						}
																						{product.shippingExpedited && 
																							<option value="shippingExpedited">
																								+{settings && settings.currencySymbol} {product.shippingExpedited * product.quantity} on {shippingOptionsObject['shippingExpedited']}
																							</option>
																						}
																						{product.shippingSameDayDelivery && 
																							<option value="shippingSameDayDelivery">
																								+{settings && settings.currencySymbol} {product.shippingSameDayDelivery * product.quantity} on {shippingOptionsObject['shippingSameDayDelivery']}
																							</option>
																						}
																					</select>
																				: 
																					<em>{product.shippingMethod}</em>
																			}
																		</td> */}
																		<td data-label="Total">
																			{settings && settings.currencySymbol}{product.discount ? showDiscount((product.purchasePrice * product.quantity) + (product.quantity * product.shippingValue), product.discount) : (product.purchasePrice * product.quantity) + (product.quantity * product.shippingValue)}
																			{product.discount ? <p className="discount">Was <span>{settings && settings.currencySymbol}{(product.purchasePrice * product.quantity) + (product.quantity * product.shippingValue)}</span> {product.discount}% Off</p> : ''}
																		</td>
																		{/* <td data-label="Action"><button onClick={() => this.onRemoveFromCart(product.id)} className="btn btn-dark">Remove</button></td> */}
																	</tr>
																)) : 
																	<tr>
																		<td>Cart is empty</td>
																	</tr>
																}
															</tbody>
														</table>
													</div>
													<Link to={{pathname: '/orders/customer/view/' + orderID}} className="text-center underlink font-weight-bold">View Order Details</Link>
												</div>
												<hr />
												{/* {parse(settings.bankTransferInstructions)} */}
											</div>
										}</div>
									}
									
									
								</div>
							</div>
							{/* <div className="card mb-3">
								{filteredProducts && filteredProducts.length ? <div className="add-ons">
									<h2 className="my-4">Others you may like</h2>
									<Slider {...sliderSettings}>
									{filteredProducts.map((prod, key) => <div className="px-2" key={key}>
										<div className="card mb-3 add-on-prod p-3">
											<h4>{prod.name}</h4>
											<Link to={`/products/view/${prod.pid}`}>
												<img src={prod.featured_image} alt={prod.name} />
											</Link>
											<button className="btn-atb" onClick={() => this.onAddToCart(prod)}>Add a £{prod.price} ticket</button>
										</div>
									</div>)}
									</Slider>
								</div> : ''}
							</div> */}
						</div>
					</div>
				}
			</div>
		);
	}
}

const condition = authUser =>
	authUser && authUser.roles.includes(ROLES.SELLER);

export default compose(
	// withAuthorization(condition),
	withFirebase,
)(CheckoutSuccess);