import React from 'react';
import ReactDOM from 'react-dom';
import cogoToast from 'cogo-toast';
import './index.css';
import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';
import * as serviceWorker from './serviceWorker';
import { ErrorBoundary } from 'react-error-boundary'

function ErrorFallback({error, resetErrorBoundary}) {


	let newErr = JSON.stringify(error);
	let errorsRef = this.props.firebase.errors();
	let errCode = Math.floor(100000 + Math.random() * 900000);

	var today = new Date();
	var dd = String(today.getDate()).padStart(2, '0');
	var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
	var yyyy = today.getFullYear();

	today = dd + '/' + mm + '/' + yyyy;

	let time = new Date().toLocaleTimeString();
	
	// Add it to erros DB.
	errorsRef.add({
		placing: 'App General',
		code: errCode,
		message: newErr,
		date: today,
		time,
		// userData: {
		// 	uid: authUser.uid ? authUser.uid : 'null',
		// 	paymentOption: paymentOption ? paymentOption : 'null',
		// 	cart,
		// },
		meta: navigator.userAgent ? navigator.userAgent : 'null',
	})
	.then((docRef) => {
		// Add friendly front end message that it could fail. Set expectations. 
		cogoToast.error(<div className="payment-err">
			<h3>Sorry, there was an error!</h3>
			<p>We've logged the issue and will look into it ASAP.</p>
			<p>Please <a href="mailto:josh@hotdraw.co.uk">email us</a> quoting number <strong>{errCode}</strong></p>
		</div>, {
			hideAfter: 10
		})
	
	})
	.catch((error) => {
		console.error("Error adding document: ", error);
	});

	return (
	  <div role="alert">
		<p>Something went wrong:</p>
		<pre>{error.message}</pre>
		<button onClick={resetErrorBoundary}>Let's try a refresh</button>
	  </div>
	)
}

ReactDOM.render(
	<FirebaseContext.Provider value={new Firebase()}>
		<ErrorBoundary
			FallbackComponent={ErrorFallback}
			onReset={() => {
				window.location.reload();
			}}
		>
			<App />
		</ErrorBoundary>
	</FirebaseContext.Provider>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
