import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import Navigation from '../Navigation';
import CollectionsPage from '../Collections';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AboutPage from '../About';
import FAQPage from '../FAQ';
import Merch from '../Merch';
import AccountPage from '../Account';
import AccountSellerPage from '../Account/seller';
import Products from '../Products';
import CreateProduct from '../Products/create';
import UpdateProduct from '../Products/update';
import ViewProduct from '../Products/view';
import Cart from '../Cart';
import Checkout from '../Checkout';
import CheckoutSuccess from '../Checkout/success';
import CookieMessage from '../Cookies/index';
import AdminSettingsGeneral from '../Admin/settings/general';
import AdminSettingsProduct from '../Admin/settings/product';
import AdminSettingsCheckout from '../Admin/settings/checkout';
import AdminUsers from '../Admin/users/index';
import AdminUsersSingle from '../Admin/users/single';
import CustomerOrders from '../Orders/customer';
import CustomerOrdersView from '../Orders/customerView';
import { getCookie, setCookie } from '../../helpers/global';
import SellerOrders from '../Orders/seller';
import SellerOrdersView from '../Orders/sellerView';
import Terms from '../Terms/';
import PrivacyPolicy from '../Privacy';
import NotFound from '../NotFound';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import { withSettings, SettingsContext } from '../Settings';
import ScrollToTop from '../ScrollToTop/index';
import ScrollUp from '../ScrollToTop/button';
import { compose } from 'recompose';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import ChatIcon from '@material-ui/icons/Chat';
import LockIcon from '@material-ui/icons/Lock';
// import Newsletter from '../Newsletter/index';
import AddToHome from '../AddToHome/index';
// import AddToHomeScreen from 'add-to-homescreen-react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import LogRocket from 'logrocket';
import CookieConsent from "react-cookie-consent";

import UsePolicy from '../UsePolicy';
import TermsUse from '../TermsUse';

import './styles.scss';


const authU = JSON.parse(localStorage.getItem('authUser'));




LogRocket.init('mnurtb/hotdraw-uk');

LogRocket.identify(authU ? authU.uid : 'null', {
	// name: authU ? authU.name : 'no-name',
	// email: authU ? authU.email : 'no-email',
	// Add your own custom user variables here, ie:
	userType: authU ? 'member' : 'non-member'
});

LogRocket.getSessionURL(function (sessionURL) {
	ReactGA.ga('send', {
	  hitType: 'event',
	  eventCategory: 'LogRocket',
	  eventAction: sessionURL,
	});
});

// Facebook Pixel
const pixelOptions = {
	autoConfig: true, // set pixel's autoConfig
	debug: false, // enable logs
};


// GA
ReactGA.initialize('UA-154853655-1', {
	gaOptions: {
		userId: authU ? authU.uid : null,
	}
});


// Pixel
if(typeof fbq === 'undefined') {
	ReactPixel.init('420179085633480', {
		em: authU ? authU.email : null,
		fn: authU ? authU : null,
	}, pixelOptions);
	ReactPixel.pageView();
}






const App = () => {
	
	const [mobMenu, setMenu] = useState(false);
	// const [tempAuth, setTempAuth] = useState(null);

	const [cookieMessage, showCookie] = useState(getCookie('acceptedCookies') ? false : true);

	const toggleMenu = () => {
		if (!mobMenu) {
			return setMenu(true);
		} else {
			return setMenu(false);
        }
	}

	const closeMenuFunc = () => {
		return setMenu(false);
	}

	const closedCookie = () => {
		
		showCookie(false);

		// Set Cookie
		setCookie('acceptedCookies', 'true', '999');
	}
	
	
	

	if (!window.location.href.indexOf('/terms') > 0) {
		window.scrollTo(0, 0);
	}


	return(
		<Router basename="/">
			<ScrollToTop />
			<div className="row top-wrap no-gutters d-flex justify-content-around">
				
				<div className="col-lg-12 col-xs-12 flex-fill header-wrap">
					
					<a href="#collapseNav" 
					data-toggle="collapse" 
					id="menuToggle"
					className="btn btn-link d-none d-inline text-left text-dark" 
					role="button" 
					aria-expanded="false" 
					aria-controls="collapseNav"
					onClick={toggleMenu}
					>
						<i className="material-icons align-middle">menu</i>
					</a>
					
					<nav id="headerNav" className="navbar navbar-light d-inline-block pb-1 pl-0">
						<SettingsContext.Consumer>
							{settings => (
								<Link className="navbar-brand d-block text-center mr-0" to="/">
									<div>{settings && settings.siteLogoUrl ? <img src="https://firebasestorage.googleapis.com/v0/b/staging-ac5f0.appspot.com/o/icons%2FnewHotDrawLogoOpt.png?alt=media&token=9eebd585-e459-4046-ab80-4a07267eae62" alt="Logo" className="brand-img img-responsive" /> : <i className="material-icons align-middle">whatshot</i>}</div>
									<div className="brand-text hide">{settings && settings.siteName}</div>
								</Link>
							)}
						</SettingsContext.Consumer>	
					</nav>
					<div className={mobMenu ? "nav-sidebar clearfix collapse brand-container show" : "nav-sidebar clearfix collapse brand-container"} id="collapseNav">
						{/* <div className="blank col-md-1 d-inline-block">&nbsp;</div> */}
						
						<Navigation closeMenu={closeMenuFunc} />
					
					</div>

					<div className="header-cart">
						<Navigation justCart={true} />
						
						<div className="secure-checkout">
							<LockIcon />
							<p>Secure Checkout</p>
						</div>
					</div>

					<div className="border"></div>
					
					{/* <div className="discount-banner">
						<h3>Code: BLK10 - 10% Off Everything</h3>
					</div> */}
				</div>
				
				<div className="col-xl-10 col-md-12">
					{/* <div className="black-banner">
						<p>Black Friday 10% Off Code: BLACKFRIDAY10</p>
					</div> */}
					
					<Switch>
						<Route exact path={ROUTES.COLLECTIONS} component={CollectionsPage} />
						<Route path={ROUTES.SIGN_UP} component={SignUpPage} />
						<Route path={ROUTES.SIGN_IN} component={SignInPage} />
						<Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
						<Route path={ROUTES.HOME} component={HomePage} />
						<Route path={ROUTES.ABOUT} component={AboutPage} />
						<Route path={ROUTES.MERCH} component={Merch} />
						<Route path={ROUTES.FAQ} component={FAQPage} />
						<Route exact path={ROUTES.ACCOUNT} component={AccountPage} />
						<Route path={ROUTES.ACCOUNT_SELLER} component={AccountSellerPage} />
						<Route exact path={ROUTES.PRODUCTS} component={Products} />
						<Route path={ROUTES.CREATE_PRODUCT} component={CreateProduct} />
						<Route path={ROUTES.UPDATE_PRODUCT} component={UpdateProduct} />
						<Route path={ROUTES.VIEW_PRODUCT} component={ViewProduct} />
						<Route path={ROUTES.ADMIN_SETTINGS_GENERAL} component={AdminSettingsGeneral} />
						<Route path={ROUTES.ADMIN_SETTINGS_PRODUCT} component={AdminSettingsProduct} />
						<Route path={ROUTES.ADMIN_SETTINGS_CHECKOUT} component={AdminSettingsCheckout} />
						<Route exact path={ROUTES.ADMIN_USERS} component={AdminUsers} />
						<Route path={ROUTES.ADMIN_USERS_SINGLE} component={AdminUsersSingle} />
						<Route path={ROUTES.CART} component={Cart} />
						<Route exact path={ROUTES.CHECKOUT} component={Checkout} />
						<Route exact path={ROUTES.CHECKOUT_SUCCESS} component={CheckoutSuccess} />
						<Route exact path={ROUTES.CUSTOMER_ORDERS} component={CustomerOrders} />
						<Route path={ROUTES.VIEW_CUSTOMER_ORDER} component={CustomerOrdersView} />
						<Route exact path={ROUTES.SELLER_ORDERS} component={SellerOrders} />
						<Route path={ROUTES.VIEW_SELLER_ORDER} component={SellerOrdersView} />
						<Route path={ROUTES.TERMS} component={Terms} />
						<Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} /> 
						<Route path={ROUTES.USE_POLICY} component={UsePolicy} /> 
						<Route path={ROUTES.TERMS_USE} component={TermsUse} /> 
						<Route component={NotFound} />
					</Switch>


					{/* <AddToHome /> */}
					
				</div>

			    <ScrollUp />
							
				{/* {getCookie('acceptedCookies') && <Newsletter />} */}
				
				<footer className="d-block">

				    {/* {cookieMessage && <CookieMessage closeCookie={closedCookie } />} */}

					<div className="col-md-12">
						<a href="/" className="logo"><img src="https://firebasestorage.googleapis.com/v0/b/staging-ac5f0.appspot.com/o/icons%2FnewHotDrawLogoOpt.png?alt=media&token=9eebd585-e459-4046-ab80-4a07267eae62" alt="Logo" className="footer-logo brand-img img-responsive" /></a>

						<Navigation inFooter={true} />

						<div className="signup-footer">
						
							{/* <link href="//cdn-images.mailchimp.com/embedcode/slim-10_7.css" rel="stylesheet" type="text/css" /> */}
						
							<div className="signup-form">
								<form action="https://hotdraw.us18.list-manage.com/subscribe/post?u=00c35bc7a8e4e5ad8e9315ac8&amp;id=c6475e1a8e" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
									<div id="mc_embed_signup_scroll" className="form-group">
										<label htmlFor="mce-EMAIL">Join our mailing list and be the first to see our latest competition drops!</label>
										<input type="email" name="EMAIL" className="email form-control" id="mce-EMAIL" placeholder="email address" required />
										{/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--></input> */}
										<div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
											<input type="text" name="b_00c35bc7a8e4e5ad8e9315ac8_c6475e1a8e" tabIndex="-1" defaultValue="" />
										</div>
										<div className="clear">
											<input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button btn btn-atb" />
										</div>
									</div>
								</form>
							</div>

							
						</div>
						
						<div className="footer-social-banner">
							<div className="fb-like mb-3" data-href="https://www.facebook.com/hotdrawuk/" data-width="" data-layout="button_count" data-action="like" data-size="large" data-share="true"></div>
							<p>Need help?</p>
							<a href="mailto:help@hotdraw.co.uk" className="d-block">help@hotdraw.co.uk</a>
							<a href="https://www.facebook.com/hotdrawuk/" className="d-block facebook">
								<FacebookIcon />
								Facebook</a>
							<a href="https://www.instagram.com/hotdrawuk/" className="d-block instagram">
								<InstagramIcon />
								Instagram</a>
							<a href="https://m.me/hotdrawuk?fbclid=IwAR0pkjFjXBhX7JG-NO7zGUAKSDRcOvGeHGvbYWo7OBwxCdw6N_4sPIBqwPc" className="d-block messenger">
								<ChatIcon />
								Messenger</a>

						</div>
						<div className="copyright">
							<Link to="/terms">Terms &amp; Conditions</Link><span> | </span>
							<Link to="/privacy">Privacy Policy</Link><span> | </span>
							<Link to="/usePolicy">Acceptable Use Policy</Link><span> | </span>
							<Link to="/termsUse">Terms of use</Link>
							<p>HotDraw UK Ltd <br />
							Registration Number #12524803</p>
							
						</div>
					</div>
				</footer>
			
				<CookieConsent
					location="bottom"
					buttonText="I Understand"
					containerClasses="cookie"
					cookieName="acceptCookies"
					// enableDeclineButton
					// declineButtonText="Count me out"
					// declineCookieValue="noCookies"
					style={{ background: "#222222",  }}
					buttonStyle={{ backgroundColor: "#e71837", color: "#ffffff", fontSize: "13px", margin: "20px auto", display: "block" }}
					expires={150}
					overlay
					overlayClasses="d-overlay"
					onAccept={() => {
						document.cookie="userCookies=true";
					}}
					onDecline={() => {
						document.cookie="userNoCookies=true";

						ReactGA.set({ anonymizeIp: true });
					}}
				>
				We use cookies to track site usage in order for us to make improvements to the user experience, monitor ads via Facebook Pixel and analyse site usage via Google Analytics.
				</CookieConsent>
			</div>
		</Router>
	);
};

const AppBase = compose(
	withAuthentication, /* Set auth in localstorage  */
	withSettings, /* Set settings in localstorage  */
)(App);

export default AppBase;