import React from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase }) => (
	<Link className="list-group-item list-group-item-action border-0" to="#" onClick={firebase.doSignOut}>
		{/* <i className="material-icons align-middle mr-3">person</i>  */}
		Sign Out
	</Link>
);

export default withFirebase(SignOutButton);