import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import AccountBasicForm from './basic';
import { AuthUserContext, withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import ReactGA from 'react-ga';

import { Accordion } from '@material-ui/core';
import { AccordionSummary } from '@material-ui/core';
import { AccordionDetails } from '@material-ui/core';

// import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './styles.scss';

const SIGN_IN_METHODS = [
	{
		id: 'password',
		provider: null,
	},
	{
		id: 'google.com',
		provider: 'googleProvider',
	},
	{
		id: 'facebook.com',
		provider: 'facebookProvider',
	},
	{
		id: 'twitter.com',
		provider: 'twitterProvider',
	},
];

const Account = () => {
	const [expanded, setExpanded] = React.useState(false);

	const handleChange = panel => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
		
		ReactGA.event({
			category: 'Account',
			action: `Toggle Dropdown`,
			label: `User toggled a login / password reset panel`,
		});
	};


	return(
		<AuthUserContext.Consumer>
		{authUser => (
			<div>
				<nav className="navbar navbar-dark bg-blue">
					<span className="navbar-brand">Account</span>
				</nav>
				
				<div className="container-fluid py-3" style={{backgroundColor: "#f2f2f2"}}>
					<div className="card mb-3">
						<div className="card-body">
							<AccountBasicForm />
						</div>
					</div>
					
					
					
					<Accordion className="card mb-4" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
						<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel1bh-content"
						id="panel1bh-header"
						// className="card-title"
						>
						<Typography>Forgot Password?</Typography>
						
						</AccordionSummary>
						
						<AccordionDetails className="card-body">
							<PasswordForgetForm />
						</AccordionDetails>
					</Accordion>


					<Accordion className="card mb-4" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
						<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel2bh-content"
						id="panel2bh-header"
						// className="card-title"
						>
						<Typography>Reset Password</Typography>
						
						</AccordionSummary>
						
						<AccordionDetails className="card-body">
							<PasswordChangeForm />
						</AccordionDetails>
					</Accordion>

					<Accordion className="card mb-4" expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
						<AccordionSummary
						expandIcon={<ExpandMoreIcon />}
						aria-controls="panel3bh-content"
						id="panel3bh-header"
						// className="card-title"
						>
						<Typography>Sign In Methods</Typography>
						
						</AccordionSummary>
						
						<AccordionDetails className="card-body">
							<LoginManagement authUser={authUser} />
						</AccordionDetails>
					</Accordion>

				</div>
			</div>
		)}
	</AuthUserContext.Consumer>
	);
	
};


class LoginManagementBase extends Component {
	_isMounted = false;
	
	constructor(props) {
		super(props);

		this.state = {
			activeSignInMethods: [],
			error: null,
		};
	}

	componentDidMount() {
		document.title = 'Account';
		
		this.fetchSignInMethods();

		ReactGA.ga('send', 'pageview', '/account');
	}
	
	componentWillUnmount() {
		this._isMounted = false;
	}

	fetchSignInMethods = () => {
		this._isMounted = true;
		
		this.props.firebase.auth
			.fetchSignInMethodsForEmail(this.props.authUser.email)
			.then(activeSignInMethods => {
				if (this._isMounted) {
					this.setState({ activeSignInMethods, error: null });
				}
			})
			.catch(error => this.setState({ error }));
	};

	onSocialLoginLink = provider => {
		this.props.firebase.auth.currentUser
			.linkWithPopup(this.props.firebase[provider])
			.then(this.fetchSignInMethods)
			.catch(error => this.setState({ error }));
	};

	onUnlink = providerId => {
		this.props.firebase.auth.currentUser
			.unlink(providerId)
			.then(this.fetchSignInMethods)
			.catch(error => this.setState({ error }));
	};

	onDefaultLoginLink = password => {
		const credential = this.props.firebase.emailAuthProvider.credential(
			this.props.authUser.email,
			password,
		);

		this.props.firebase.auth.currentUser
			.linkAndRetrieveDataWithCredential(credential)
			.then(this.fetchSignInMethods)
			.catch(error => this.setState({ error }));
	};

	render() {
		const { activeSignInMethods, error } = this.state;

		return (
			<div className="col-xs-12">
				<ul className="d-block text-left list-unstyled signInMethods">
					{SIGN_IN_METHODS.map(signInMethod => {
						const onlyOneLeft = activeSignInMethods.length === 1;
						const isEnabled = activeSignInMethods.includes(
							signInMethod.id,
						);

						return (
							<li key={signInMethod.id} className="d-block mb-2">
								{signInMethod.id === 'password' ? (
									<DefaultLoginToggle
										onlyOneLeft={onlyOneLeft}
										isEnabled={isEnabled}
										signInMethod={signInMethod}
										onLink={this.onDefaultLoginLink}
										onUnlink={this.onUnlink}
									/>
									) : (
									<SocialLoginToggle
										onlyOneLeft={onlyOneLeft}
										isEnabled={isEnabled}
										signInMethod={signInMethod}
										onLink={this.onSocialLoginLink}
										onUnlink={this.onUnlink}
									/>
								)}
							</li>
						);
					})}
				</ul>
				{error && error.message}
			</div>
		);
	}
}

const SocialLoginToggle = ({
	onlyOneLeft,
	isEnabled,
	signInMethod,
	onLink,
	onUnlink,
	}) =>
		isEnabled ? (
			<Link
				to="#"
				onClick={() => onUnlink(signInMethod.id)}
				disabled={onlyOneLeft}
			>
				Deactivate {signInMethod.id}
			</Link>
		) : (
			<Link
				to="#"
				onClick={() => onLink(signInMethod.provider)}
			>
				Link {signInMethod.id}
			</Link>
		);

class DefaultLoginToggle extends Component {
	constructor(props) {
		super(props);

		this.state = { passwordOne: '', passwordTwo: '' };
	}

	onSubmit = event => {
		event.preventDefault();

		this.props.onLink(this.state.passwordOne);
		this.setState({ passwordOne: '', passwordTwo: '' });
	};

	onChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {
		const {
			onlyOneLeft,
			isEnabled,
			signInMethod,
			onUnlink,
		} = this.props;

		const { passwordOne, passwordTwo } = this.state;

		const isInvalid =
		passwordOne !== passwordTwo || passwordOne === '';

		return isEnabled ? (
			<Link
				to="/"
				onClick={() => onUnlink(signInMethod.id)}
				disabled={onlyOneLeft}
			>
				Deactivate {signInMethod.id}
			</Link>
		) : (
			<form onSubmit={this.onSubmit} className="text-left">
				<input
					name="passwordOne"
					value={passwordOne}
					onChange={this.onChange}
					type="password"
					placeholder="New Password"
					autoComplete="off"
					className="form-control mb-2"
				/>
				<input
					name="passwordTwo"
					value={passwordTwo}
					onChange={this.onChange}
					type="password"
					placeholder="Confirm New Password"
					autoComplete="off"
					className="form-control mb-2"
				/>

				<button disabled={isInvalid} type="submit" className="btn btn-atb mb-2">
					Link {signInMethod.id}
				</button>
			</form>
		);
	}
}

const LoginManagement = withFirebase(LoginManagementBase);

const condition = authUser => !!authUser; // "!!authUser" is shorthand for: "authUser != null"

export default withAuthorization(condition)(Account);