import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import TrustBoxContainer from '../Trustpilot/index';
import { poll } from '../../helpers/global';
import Countdown from '../Countdown/index';
import cogoToast from 'cogo-toast';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import Equalizer from 'react-equalizer';
import { Animated } from "react-animated-css";
import './style.scss';


const INITIAL_STATE = {
	loading: false,
	loadingBtn: false,
	totalItemsCount: 0,
	lastItem: null,
	showMoreBtn: true,
	products: [],
	searchKeyword: '',
	searchCategory: '',
	searchFilter: '',
	searchEnabled: false,
	showFilters: true,
	justAcitve: true,
	justEnded: false,
	showBadge: false,
	totalCount: 0,
	totalEnded: 0,
	shuffled: false,
}

function equalHeight(resize) {
	var elements = document.getElementsByClassName("equalHeight"),
		allHeights = [],
		i = 0;
	if(resize === true){
	  for(i = 0; i < elements.length; i++){
		elements[i].style.height = 'auto';
	  }
	}
	for(i = 0; i < elements.length; i++){
	  var elementHeight = elements[i].clientHeight;
	  allHeights.push(elementHeight);
	}
	for(i = 0; i < elements.length; i++){
	  elements[i].style.height = Math.max.apply( Math, allHeights) + 'px';
	  // Optional: Add show class to prevent FOUC
	  if(resize === false){
		elements[i].className = elements[i].className + " show";
	  }
	}
}
	
class Collections extends Component {
	constructor(props) {
		super(props);

		this.state = {
			...INITIAL_STATE,
			settings: JSON.parse(localStorage.getItem('settings')),
			largeLayout: false,
		};
		
		this.timer = null;
	}
	
	async componentDidMount() {
		this.mounted = true;

		if (window.location.href === 'https://hotdraw.co.uk/' || window.location.href.indexOf('https://hotdraw.co.uk/?') > -1) {
			document.title = 'Live Competitions';
		}
		if (window.location.href === 'https://hotdraw.co.uk/home') {
			document.title = 'HotDraw UK Winners';
		}
		let filters = this.props.filters;
		if (filters !== false) {
			filters = true;
		}
		let productsNum = this.props.amount ? this.props.amount : 0;
		if (productsNum === -1) { // Show all
			productsNum = 99;
		}
		
		if (this.mounted) {
			this.loadProducts(false, true);
		}


		// Categories
		let cats = null;
		if (this.state.settings) {
			cats = await this.state.settings.productCategories;
		}
		// console.log({cats})
		this.setState({ 
			totalItemsCount: productsNum, 
			largeLayout: this.props.size ? this.props.size : false,
			showFilters: filters,
			justAcitve: this.props.active,
			justEnded: this.props.ended,
			loading: true,
			prodCats: cats ? cats : null,
		});

		ReactGA.modalview('collections');

	}

	componentWillUnmount() {
		this.setState({
			loading: false,
			loadingBtn: false,
			totalItemsCount: 0,
			lastItem: null,
			showMoreBtn: true,
			products: [],
			searchKeyword: '',
			searchCategory: '',
			searchFilter: '',
			searchEnabled: false,
			showFilters: true,
			justAcitve: true,
			justEnded: false, 
			showBadge: false,
		})
		this.mounted = false;
	}
	
	loadProducts(loadmore, fromComponent) {
		const { settings, lastItem, searchKeyword, searchCategory, searchFilter, searchEnabled, justAcitve, justEnded, showBadge } = this.state;
		const self = this;
		
		var pageLimit = settings && settings.perPageCollections ? parseInt(settings.perPageCollections) : 8;
		
		this.setState({ loadingBtn: true });
		
		/* Load first */
		var productQuery = this.props.firebase.products();
		// console.log('prod qry = ', productQuery);
		/* Search */
		if( searchEnabled ){
			/* If request is from a search (onChangeSearch();), we clear out the product list then load the new search results */
			/* We identify weather the trigger is from a search or a load more button click using "searchEnabled" state */
			this.setState({
				products: [],
				searchEnabled: false
			});
		}
		if(searchKeyword){
			productQuery = productQuery.where('keywords', 'array-contains', searchKeyword.toLowerCase());
		}
		if(searchCategory){
			productQuery = productQuery.where('category', '==', searchCategory);
		}

		// If settings such as justActive & justEnded are present, filter by them too.
		
		
		if (this.props.ended) {
			// Only active comps
			productQuery = productQuery.where('stock', '<=', 0).orderBy('stock');
			// productQuery = productQuery.where('winnerImage', '==', true).orderBy('stock');
			productQuery.get().then(function(querySnapshot) {      
				
				self.setState({
					totalEnded: querySnapshot.size ? querySnapshot.size : 0,
				});
			});
			
			// productQuery = productQuery.orderBy('winnerImage');
			// productQuery = productQuery.orderBy("createdAt", "desc");

			
		} else {
			// Only active comps
			productQuery = productQuery.where('winnerChosen', '==', false).orderBy('createdAt');
			// productQuery = productQuery.where('stock', '>', 0).orderBy('stock');

			// productQuery = productQuery.orderBy('highlight');
			productQuery.get().then(function(querySnapshot) {      
				self.setState({
					totalCount: querySnapshot.size ? querySnapshot.size : 0,
				});
			});
		}
 
		
		switch(searchFilter) {
			case 'price-descening':
				productQuery = productQuery.orderBy('price', 'desc');
				break;
			case 'price-ascending':
				productQuery = productQuery.orderBy('price');
				break;
			case 'on-sale':
				productQuery = productQuery.where('salePrice', '>', 0);
				break;	
				// Add in-stock!
			default: 
				productQuery = productQuery.orderBy('price', 'desc');
		}


		

		productQuery = productQuery.limit(pageLimit);
		
		/* If there's a last item set, we start the query after that item using startAfter() method */
		if( loadmore && lastItem ){
			productQuery = productQuery.startAfter(lastItem); 
		}
		
		productQuery.onSnapshot(snapshot => { /* The onSnapshot() method registers a continuous listener that triggers every time something has changed, use get() to only call it once (disable realtime) */
			let productChunk = [];
			
			snapshot.docChanges().forEach(function(change) {
				if (change.type === "added") {
					/* Add more items to the screen... */
					productChunk.push({ ...change.doc.data(), pid: change.doc.id });
				} else if (change.type === "modified") {
					/* If there is a change in realtime... */
					/* Apply the modification to the item directly without changing the current item index. */
					self.setState({
						products: self.state.products.map(el => (el.pid === change.doc.id ? {...change.doc.data(), pid: change.doc.id} : el)),
					});
				}

				// if (!showBadge) {
				// 	self.setState({ showBadge: true });
				// 	cogoToast.info(
				// 		<svg className="flame-icon"
				// 			xmlns="http://www.w3.org/2000/svg"
				// 		width="28"
				// 		height="28"
				// 		x="0"
				// 		y="0"
				// 		shapeRendering="auto"
				// 		version="1.1"
				// 		viewBox="0 0 100 100"
				// 		xmlSpace="preserve"
				// 			style={{ height: "100%", width: "100%", background: "none" }}
				// 			>
				// 			<g
				// 			className="ldl-scale"
				// 			style={{
				// 			WebkitTransformOrigin: "50% 50%",
				// 			MsTransformOrigin: "50% 50%",
				// 			transformOrigin: "50% 50%",
				// 			}}
				// 		>
				// 			<g className="ldl-ani">
				// 			<g className="ldl-layer">
				// 				<path
				// 				fill="#E71837"
				// 				d="M80.8 22c-3.7 1.6-7.4 3.9-10.8 6.7-5.5-6.7-10.2-13.4-9.3-21.2C49.5 12.1 38.6 18.9 30.3 27c-.6-2-.9-4.1-1-6.4-12 10.9-16.3 30.6-12.7 46 8 38.6 64.6 31.8 67.5-6.2C87 45.6 77.4 34.7 80.8 22z"
				// 				transform="scale(.8) translate(0 47.76)"
				// 				className="0"
				// 				opacity="0.05"
				// 				></path>
				// 			</g>
				// 			<g className="ldl-layer">
				// 				<path
				// 				fill="#F47E60"
				// 				d="M65.9 47c-2 1-3.6 7.7-5.4 9.4-3.3-3.4-6.5-12.1-6.4-16.3-6.1 3-11.5 12.4-15.7 17.1-.4-1-1.1-7.5-1.2-8.7-6.2 6.3-7.8 17-5.1 25 6.1 20.2 37.2 14 37.2-6.3 1.1-8-4.7-13.4-3.4-20.2z"
				// 				transform="scale(.8) translate(0 47.76)"
				// 				className="0"
				// 				opacity="0.05"
				// 				></path>
				// 			</g>
				// 			<g className="ldl-layer">
				// 				<ellipse
				// 				cx="49.5"
				// 				cy="76"
				// 				fill="#F8B26A"
				// 				opacity="0.05"
				// 				rx="8.2"
				// 				ry="4.8"
				// 				className="0"
				// 				transform="scale(.8) translate(0 47.76)"
				// 				></ellipse>
				// 			</g>
				// 			</g>
				// 		</g>
				// 		</svg>
				// 	).then((res) => {
				// 		if (res) {
				// 			self.setState({ showBadge: false })
				// 		}
				// 	})
				// }
			});

			this.setState((prevState) => ({
				products: prevState.products && fromComponent ? [...prevState.products, ...productChunk]: productChunk,
				loading: false,
				loadingBtn: false,
				lastItem: snapshot.docs[snapshot.docs.length - 1], 
				showMoreBtn: productChunk.length < pageLimit ? false : true, 
			}));
			
		});


	}
	
	onChangeSearch = event => {
		/* Save state but do not trigger firestore search not until user stops typing. */
        clearTimeout(this.timer);
		this.setState({searchKeyword: event.target.value, searchEnabled: true});
        this.timer = setTimeout(this.loadProducts.bind(this), 500);
	}
	
	onChangeCategory = event => {
		clearTimeout(this.timer);
		this.setState({searchCategory: event.target.value, searchEnabled: true});
		this.timer = setTimeout(this.loadProducts.bind(this), 100);

		let { value } = event.target;

		if (value) {
			ReactGA.event({
				category: 'Products',
				action: 'Click',
				label: `Clicked ${value}`
			});
		} else {
			ReactGA.event({
				category: 'Products',
				action: 'Click',
				label: `Clicked View All`
			});
		}
		
		const fetchProds = () => {
			const { products } = this.state;
			
			// let productsInThisCat = await products.filter((el) => {
			// 	if (value) {
			// 		if (el.category === value) {
			// 			return el.pid;
			// 		}
			// 	}
			// });
			let productIDs = products.map((prod) => prod.pid);
			// console.log('these prods =', productIDs);
			return productIDs;
		}
		
		
		setTimeout(() => {
			const IDs = fetchProds();
			
			ReactPixel.trackCustom('ViewCategory', {
				content_name: value ? value : 'All Products',
				content_category: value ? value : 'All',
				content_type: 'product',
				content_ids: IDs,
			});
		}, 500);
		
	}
	
	onChangeFilter = event => {
		clearTimeout(this.timer);
		this.setState({searchFilter: event.target.value, searchEnabled: true});
		this.timer = setTimeout(this.loadProducts.bind(this), 100);

		ReactGA.event({
			category: 'Products',
			action: 'Click',
			label: 'Changed Filter'
		});
	}
	
	// getNodes(equalizerComponent, equalizerElement) {
	// 	return [
	// 	  this.refs.node1,
	// 	  this.refs.node2,
	// 	  this.refs.node3
	// 	]
	//   }
	  
	render() {
		const { products, loading, loadingBtn, settings, showMoreBtn, searchCategory, searchKeyword, searchFilter, showFilters, justEnded, prodCats, totalCount, totalEnded } = this.state;
		
		let minHeight = 0;

		// function shuffle(array) {
		// 	var currentIndex = array.length, temporaryValue, randomIndex;
		  
		// 	// While there remain elements to shuffle...
		// 	while (0 !== currentIndex) {
		  
		// 	  // Pick a remaining element...
		// 	  randomIndex = Math.floor(Math.random() * currentIndex);
		// 	  currentIndex -= 1;
		  
		// 	  // And swap it with the current element.
		// 	  temporaryValue = array[currentIndex];
		// 	  array[currentIndex] = array[randomIndex];
		// 	  array[randomIndex] = temporaryValue;
		// 	}
		  
		// 	return array;
		// }
		
		
		// if (!this.state.shuffled) {
		// 	productsArr = shuffle(productsArr);
		// 	this.setState({shuffle: true})
		// }
		
		let productsArr = products;
		// Check for highlighted product
		const hasHighlight = productsArr.filter((prod) => prod.highlight);
		
		
		if (hasHighlight && hasHighlight[0]) {
			

			productsArr.unshift(hasHighlight[0]);
		}

		productsArr = [...new Map(productsArr.map(item => [item.createdAt, item])).values()]

	

		const getPercentage = (product) => {
			let percentLeft = 100;
			let urgencyClass = 'red';
			let message = '';
			if (product && product.stock && product.initStock) {
				// Percentage of tickets left
				percentLeft = ((product.stock / product.initStock) * 100).toFixed(2);
				
				if (percentLeft >= 55) {
					urgencyClass = 'green';
					message = 'Good news! You\'ve caught this competition early. Secure your tickets now to avoid missing out!';
				}
				if (percentLeft > 40 && percentLeft < 55) {
					urgencyClass = 'amber';
				}
				if (percentLeft <= 20) {
					urgencyClass = 'red';
					message = 'Tickets are hot for this draw! Enter now whilst tickets are still available!';
				}
			}
			return {
				urgencyClass,
				message,
			};
		}

		const showWinnerName = (name, productName) => {
			if (!name || !productName) return;
			return (
				<p>The winner has been selected, <strong>{name}</strong> has won the <strong>{productName}!</strong></p>
			)
		};


		const winners = productsArr.filter((item) => item.winnerImage)
		const others = productsArr.filter((item) => !item.winnerImage)
		const sortedProducts = [...winners, ...others];
		
		return (
			
			<div>
				{!justEnded && <nav className="navbar navbar-dark bg-blue">
					<a href="https://www.facebook.com/hotdrawuk/" target="_blank">
						<span className="navbar-brand">Live Competitions Drawn every Monday on <span>Facebook @ 7:30pm</span></span>
					</a>
				</nav>}

				<div className="donations-banner">
					{/* <h5>We've donated £{settings.donationTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} so far, thanks to your support!</h5> */}
					{totalCount > 0 ? <h5>{totalCount} Live Competitions</h5> : ''}
					{totalEnded > 0 ? <h5>{totalEnded} Previous Winners</h5> : ''}
				</div>

				<div className="container-fluid px-lg-3" style={{backgroundColor: "#f2f2f2"}}>
				<TrustBoxContainer />
				{loading ? <div>Loading ...</div> : 
					<div className="row">
						{showFilters ? <div className="col-12 pt-3 bg-white pb-0">
							<div className="form-group col-12 d-block categories px-0">
								{/* <label className="font-weight-bold">Category:</label>
								<select className="form-control form-control-sm" name="category" onChange={this.onChangeCategory} value={searchCategory}>
									<option value="">Show All</option>
									{settings && settings.productCategories && settings.productCategories.map((cat, index) => (
										<option key={index} value={cat.slag}>{cat.name}</option>
									))}
								</select> */}
								<div>
									{prodCats && <button value="" onClick={this.onChangeCategory}>View all</button>}
									{prodCats && prodCats.map((cat, index) => (
										<button key={index} value={cat.slag} onClick={this.onChangeCategory}>{cat.name}</button>
									))}
								</div>
							</div>
							{/* <div className="form-group col-4 d-inline-block">
								<select className="form-control form-control-sm" name="category" onChange={this.onChangeFilter} value={searchFilter}>
									<option value="">Sort By</option>
									<option value="price-descening">Price Descening</option>
									<option value="price-ascending">Price Ascending</option>
									<option value="on-sale">On Sale Items</option>
								</select>
							</div> */}
						</div> : ''}
							
						<div className="col-12">
							{/* <div className="form-group mt-3">
								<input type="text" className="form-control w-50 border-0" placeholder="Search for anything" onChange={this.onChangeSearch} value={searchKeyword} />
							</div> */}
							<Animated animationIn="fadeIn" animationOut="fadeOut" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
							<div className="row collections-list bg-white justify-content-around">
							{sortedProducts ? sortedProducts.map((product, index) => (
								<div className={this.state.largeLayout ? 'col-12 col-md-8 my-4' : `${product.highlight && window.innerWidth < 649 ? 'col-12 my-2 px-2 highlight-product' : 'col-6 col-lg-4 col-md-6 col-sm-6 my-2 px-2'}`} id={product.stock === 0 ? 'out-of-stock' : ''} key={index}>
								{product.stock === 0 && product.winnerChosen && <div className="out-of-stock-banner"><p>Competition Finished</p></div>}
								{product.stock === 0 && product.winnerChosen === false && <div className="out-of-stock-banner"><p>Winner To Be Drawn</p></div>}
								{product.highlight && <div className="highlight-banner"><p>{product.name}</p></div>}
								<div className={getPercentage(product).urgencyClass ? `${getPercentage(product).urgencyClass} px-2 card flex-match` : 'px-2 card flex-match'} id={product.highlight ? 'highlight' : ''}>
									<Link to={{pathname: '/products/view/' + product.pid}} className={product.winnerChosen ? 'winner-chosen d-block' : 'd-block'}>
										<div className="card-body">
											{!product.highlight ? <h5 className="card-title px-2">{product.name}</h5> : ''}
											<div className="lead text-dark">
												
												{product.salePrice > 0
													? <span><strike className="mr-2">£{product.price}</strike> £{product.salePrice}</span> 
													: <span className="perticket">£{product.price} <span>per ticket</span></span>
												}

												{product.stock > 0 ? <p className={`${getPercentage(product).urgencyClass} d-inline stock-left`}>{product.stock}<span>/{product.initStock} <sup>tickets remaining</sup></span></p> : ''}
											</div>

											<div className="text-center card-img-top equalHeight">
												<img src={`${product.featured_image ? product.featured_image : '/placeholder.jpg'}`} className="img-fluid mb-2" alt={product.name} />
											</div>
										</div>
										<div className="text-center">
											
											{/* <div className="progress">
												<div className="progress-bar progress-bar-success" role="progressbar" aria-valuenow="40"
													aria-valuemin="0" aria-valuemax="100" style="width:40%">
													40% Complete (success)
												</div>
											</div> */}

											{getPercentage(product).message ? <p className="urgent-message">{getPercentage(product).message}</p> : ''}


											{showWinnerName(product.winnerName, product.name) ? showWinnerName(product.winnerName, product.name) : ''}

											{product.stock > 0 ? 
												<button className="btn-atb mb-3">Enter</button> :

												product.winnerChosen ? <button className="btn-winner"><span></span>Winner Chosen<span></span></button>	:  <button className="btn-atb mb-3">Sold out</button>
											}
											{product.stock > 0 && <Countdown date={product.endDate} showLabel={true} inDays={true} />}

											
										</div>
									</Link>
								</div>
							</div>
							))  : <div>No products</div>}
							</div>
							<div className="col text-center mb-3">
								{showMoreBtn 
									? 
										<button 
											className="btn btn-purple font-weight-bold btn-lg mt-3"  disabled={loadingBtn}
											onClick={() => this.loadProducts(true, true)}
										>
											{loadingBtn ? <span>Loading...</span> : <span className="btn-atb">Load More</span>}
										</button>
									:
										<div className="mt-3">{products.length ? <span>More competitions added soon!</span> : <span>No competitions found, but we add new ones daily so check back!</span>}</div>
								}
							</div>
							</Animated>
						</div>
					</div>
				}
				</div>
			</div>

		);
	}
}

export default withFirebase(Collections);