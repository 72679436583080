import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import cogoToast from 'cogo-toast';
import parse from 'html-react-parser';
import ImageGallery from 'react-image-gallery';
import QuantityTwo from '../Quantity/index';
import Countdown from '../Countdown/index';
import TrustBox from '../Trustpilot/index';
import Collections from '../Collections/index';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import ProdSEO from '../../helpers/metaSchema';
import USP from '../USP/index';

import "react-image-gallery/styles/css/image-gallery.css";
import './styles.scss';

const INITIAL_STATE = {
	shippingType: 'Free Shipping',
	error: null,
	loading: true,
	upload_files: [],
	authUser: null,
	cart: [],
	seller: null,
	quantity: 5,
	showOffers: false,
	discount: 5,
	currentTotal: null,
	ogTotal: null,
	saving: null,
};

// const shippingOptionsObject = {
// 	'shippingStandard': 'Standard Shipping',
// 	'shippingExpedited': 'Expedited Shipping',
// 	'shippingSameDayDelivery': 'Same Day Delivery',
// 	'Free shipping': 'Free shipping',
// 	'Local pickup': 'Local pickup'
// }

// const percentage = (num, per) => {
// 	return (num/100)*per;
// }

class ViewProduct extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			...INITIAL_STATE,
			authUser: JSON.parse(localStorage.getItem('authUser')),
			settings: JSON.parse(localStorage.getItem('settings')),
		};
	}
	
	componentDidMount() {
		const { authUser } = this.state;
		
		document.title = "Loading...";

		let product_id = this.props.match.params.id;
		
		if(product_id){
			/* Fetch product from our Firebase realtime database  */
			/* this.props.firebase.product() -- the "product" is taken from firebase.js  */
			this.unsubscribeProduct = this.props.firebase.product(product_id).onSnapshot(snapshot => { /* Use once() to only call it once (disable realtime) */
				const productObject = snapshot.data();
				
				if(productObject){
					
					document.title = productObject.name;
					
					this.setState({
						product: productObject,
						id: product_id,
						loading: false
					});

					this.props.firebase.user(productObject.author).onSnapshot(snapshotSeller => {
						this.setState({seller: snapshotSeller.data()});
					});

					ReactGA.ga('send', 'pageview', `${productObject.name}`);
					
					
					if (productObject.stock < 5) {
						this.setState({
							quantity: productObject.stock,
							discount: 0,
						});
					}
					
					let tempObj = productObject;
					tempObj.id = product_id;
					tempObj.quantity = productObject.stock;
					tempObj.link = `https://hotdraw.co.uk/products/view/${product_id}`;
					
					ReactPixel.track('ViewContent', {
						content_ids: product_id,
						content_category: tempObj.category,
						content_name: tempObj.name,
						content_type: 'product',
						contents: [tempObj],
						value: parseFloat(productObject.price, 10).toFixed(2),
						currency: 'GBP',
						product_catalog_id: '2777510749188479',
					});
					
					let tempOgTotal = parseFloat(productObject.price * this.state.quantity).toFixed(2);
					let tempCurrentTotal = parseFloat(tempOgTotal / 100 * (100 - this.state.discount)).toFixed(2);
					let tempSaving = parseFloat(tempOgTotal - tempCurrentTotal).toFixed(2);
					this.setState({
						ogTotal: tempOgTotal,
						currentTotal: tempCurrentTotal,
						saving: tempSaving,
					});

					if (productObject.stock < 5) {
				
						this.setState({
							ogTotal: null,
							saving: null,
							currentTotal: parseFloat(productObject.price * productObject.stock).toFixed(2),
						});
						
					}


				} else {
					this.props.history.push(ROUTES.NOT_FOUND);

					ReactGA.ga('send', 'pageview', 'Not Found');
				}

				
			});
			
			if(authUser){
				/* Listen to realtime cart changes */
				this.unsubscribeCartItems = this.props.firebase.cartItems().doc(authUser.uid).onSnapshot(snapshotCart => {
					if(snapshotCart.data()){
						this.setState({cart: snapshotCart.data()});
					} else {
						this.setState({cart: []});
					}
				});
			}
		}	
		
		// window.FB.api(
		// 	"/2777510749188479/items_batch",
		// 	"POST",
		// 	{
		// 		"name": "Test Feed",
		// 	},
		// 	function (response) {
		// 	  if (response && !response.error) {
		// 		/* handle the result */
		// 		console.log('response = ', response);
		// 	  }
		// 	}
		// );		
		
	}
	
	/* On unmount, we remove the listener to avoid memory leaks from using the same reference with the off() method: */
	componentWillUnmount() {
		this.unsubscribeProduct && this.unsubscribeProduct();
		this.unsubscribeCartItems && this.unsubscribeCartItems();
	}
	
	onAddToCart() {
		const { id, product, authUser, cart, shippingType, quantity, discount } = this.state;
		// console.log('on add to cart ', {
		// 	discount,
		// 	product,
		// 	quantity,
		// });
		
		if(authUser){ 
			let is_existing = Object.keys(cart).length && Object.values(cart).find(item => id === item.id); /* Check if item already exists in cart from state */
			if(!is_existing){
				// let price = product.salePrice > 0 ? product.salePrice : product.price;
				// if (discount) {
				// 	console.log('price ', price);
				// 	console.log('discount ', discount);
				// 	let t = percentage(price, discount);
				// 	console.log(t);
				// 	price = price - (price * parseFloat(`.${discount}`));
				// 	console.log(price);
				// }
				let newItem = {
					'id': id, 
					'quantity': quantity ? quantity : '1', 
					'sellerID': product.author, 
					'purchasePrice': product.salePrice > 0 ? product.salePrice : product.price, 
					'discount': discount ? discount : 0,
					'shippingType': shippingType, 
					'shippingValue': product[shippingType] > 0 ? product[shippingType] : 0 /* Get shipping option value base on key */
				};
				let updatedCart = Object.values(cart); /* Clone it first */
				let cartRef = this.props.firebase.cartItems();
				
				/* Push new cart item */
				updatedCart.push(newItem); 
				
				/* Set updated cart in firebase, no need to use setState because we already have a realtime cart listener in the componentDidMount() */
				cartRef.doc(authUser.uid).set(Object.assign({}, updatedCart)).then(() => {
					cogoToast.success("Added to cart");
				});

				ReactGA.event({
					category: 'Products',
					action: 'Click Add To Bag',
					label: `${product.name} for ${quantity} tickets ${discount ? `with ${discount}% discount` : ''}`,
				});

				
				let totalPrice = 0;
				let tempOgTotal = parseFloat(newItem.purchasePrice * newItem.quantity).toFixed(2);
				if (newItem.discount) {
					totalPrice = parseFloat(tempOgTotal / 100 * (100 - newItem.discount)).toFixed(2);
				} else {
					totalPrice = tempOgTotal;
				}

				let tempProd = product;
				tempProd.id = id;

				ReactPixel.track('AddToCart', {
					id: id,
					content_ids: [id],
					content_name: product.name,
					content_type: "product",
					// contents: tempProd,
					value: parseFloat(totalPrice).toFixed(2),
					currency: 'GBP',
					product_catalog_id: '2777510749188479',
				});

				
				// const updateCatologuItem = async () => {
				// 	let response = await fetch('https://graph.facebook.com/<API_VERSION>/2777510749188479/batch?requests=<REQUESTS>')
				// }

			}
			
		} else {
			this.props.history.push(ROUTES.SIGN_IN);
			cogoToast.info("Sign in or register to continue");
			
			ReactGA.event({
				category: 'Products',
				action: 'Click Add To Bag',
				label: `Logged out user clicked add to bag`
			});

		}
	}
	
	onRemoveFromCart() {
		const { id, authUser, cart, product, discount } = this.state;
		
		let is_existing = Object.keys(cart).length && Object.values(cart).find(item => id === item.id); /* Check if item exists in cart from state */
		if(is_existing){
			let updatedCart = Object.values(cart); /* Clone it first */
			let itemIndex = updatedCart.findIndex(item => id === item.id); /* Get the index of the item we want to delete */
			let cartRef = this.props.firebase.cartItems();
			
			/* Remove item from the cloned cart state */
			updatedCart.splice(itemIndex, 1); 
			
			/* Set updated cart in firebase, no need to use setState because we already have a realtime cart listener in the componentDidMount() */
			cartRef.doc(authUser.uid).set(Object.assign({}, updatedCart)).then(() => {
				cogoToast.success("Removed from cart");
			});

			let tempOgTotal = parseFloat(product.price * (product.stock < 5 ? product.stock : 5)).toFixed(2);
			let tempCurrentTotal = parseFloat(tempOgTotal / 100 * (100 - (5))).toFixed(2);
			let tempSaving = parseFloat(tempOgTotal - tempCurrentTotal).toFixed(2);

			// Set discount back to 0
			this.setState({
				discount: product.stock >= 5 ? 5 : 0,
				quantity: product.stock < 5 ? product.stock : 5,
				ogTotal: tempOgTotal,
				saving: tempSaving,
				currentTotal: tempCurrentTotal,
			});


		}

		ReactGA.event({
			category: 'Products',
			action: 'Click Remove Item',
			label: `Removed item ${document.title}`
		});
		  
	}
	
	onChange = event => {
		this.setState({ [event.target.name]: event.target.value });
		// console.log('event! ', event.target.name);

		if (this.state.quantity < 5) {
			this.setState({discount: 0});
		}
		if (this.state.quantity >= 5) {
			this.setState({discount: 5});
		}
		if (this.state.quantity >= 10) {
			this.setState({discount: 10});
		}
		if (this.state.quantity >= 20) {
			this.setState({discount: 15});
		}
		if (this.state.quantity >= 40) {
			this.setState({discount: 20});
		}
	};


	quantChange = qty => {
		// console.log('Qty ', qty);
		this.setState({ quantity: parseInt(qty, 10) });
		
		const run = () => {
			const { ogTotal, currentTotal, saving, product, discount } = this.state;
			
			if (discount || qty > 4) {
			
				let tempOgTotal = parseFloat(product.price * qty).toFixed(2);
				let tempCurrentTotal = parseFloat(tempOgTotal / 100 * (100 - discount)).toFixed(2);
				let tempSaving = parseFloat(tempOgTotal - tempCurrentTotal).toFixed(2);
				// console.log('Qty ', {
				// 	tempOgTotal,
				// 	tempCurrentTotal,
				// 	tempSaving
				// });
				this.setState({
					ogTotal: tempOgTotal,
					currentTotal: tempCurrentTotal,
					saving: tempSaving,
				});
				// ogTotal = parseFloat(product.price * quantity).toFixed(2);
				// console.log('Og TOTAL = ,',ogTotal);
				// currentTotal = parseFloat(ogTotal / 100 * (100 - quantity)).toFixed(2);
				// console.log('Current TOTAL = ,',currentTotal);
				// saving = parseFloat(ogTotal / 100 * discount).toFixed(2);
				// console.log('Saving TOTAL = ,', saving);
			} else {
				this.setState({
					ogTotal: null,
					saving: null,
					currentTotal: parseFloat(product.price * qty).toFixed(2),
				});
				// ogTotal = null;
				// saving = null;
				// currentTotal = null;
				// currentTotal = parseFloat(product.price * quantity).toFixed(2);
			} 
			if (qty < 5) {
				this.setState({
					ogTotal: null,
					saving: null,
					currentTotal: parseFloat(product.price * qty).toFixed(2),
				})
			}
		}

		if (qty < 5) {
			this.setState({discount: 0}, run);
		}
		if (qty >= 5) {
			this.setState({discount: 5}, run);
		}
		if (qty >= 10) {
			this.setState({discount: 10}, run);
		}
		if (qty >= 20) {
			this.setState({discount: 15}, run);
		}
		if (qty >= 40) {
			this.setState({discount: 20}, run);
		}

		
	}


	addDiscount = amnt => {
		this.setState({ discount: amnt })	
		
	}

	
	render() {
		const { id, seller, product, loading, error, cart, settings, shippingType, quantity, discount, currentTotal, ogTotal, saving } = this.state;
		
		let imagesSlider = [];
		if(product && product.images.length){
			product.images.forEach((image, _index, array) => {
				let newImage = {'original': image.url, 'thumbnail': image.url};
				imagesSlider.push(newImage); /* Push new image to the imagesSlider state */
			});
		}

		// console.log('state: ', this.state);

		// Current total with discount
		// let currentTotal = null;
		// let ogTotal = null;
		// let saving = null;
		if (product && product.showOffer && product.price && product.showOffer && product.stock) {
			// currentTotal = product.price;
			
			// if (quantity == '1') {
				// currentTotal = parseFloat(product.price * 1).toFixed(2);
			// }
			// if (quantity == '2') {
			// 	currentTotal = parseFloat(product.price * 2).toFixed(2);
			// }
			// if (quantity == '3') {
			// 	currentTotal = parseFloat(product.price * 3).toFixed(2);
			// }
			// if (quantity >= '5' && quantity < '10') {
			// 	ogTotal = parseFloat(product.price * 5).toFixed(2);
			// 	currentTotal = parseFloat(ogTotal / 100 * 95).toFixed(2);
			// 	saving = parseFloat(ogTotal / 100 * 5).toFixed(2);
			// }
			// if (quantity == '10') {
			// 	ogTotal = parseFloat(product.price * 10).toFixed(2);
			// 	currentTotal = parseFloat(ogTotal / 100 * 90).toFixed(2);
			// 	saving = parseFloat(ogTotal / 100 * 10).toFixed(2);
			// }
			// if (quantity == '20') {
			// 	ogTotal = parseFloat(product.price * 20).toFixed(2);
			// 	currentTotal = parseFloat(ogTotal / 100 * 87.5).toFixed(2);
			// 	saving = parseFloat(ogTotal / 100 * 12.5).toFixed(2);
			// }
			// if (quantity == '50') {
			// 	ogTotal = parseFloat(product.price * 50).toFixed(2);
			// 	currentTotal = parseFloat(ogTotal / 100 * 87.5).toFixed(2);
			// 	saving = parseFloat(ogTotal / 100 * 12.5).toFixed(2);
			// }
			
			
			
		}
		

		// currentTotal += parseFloat(currentTotal).toFixed(2);
		// ogTotal += parseFloat(ogTotal).toFixed(2);
		// saving += parseFloat(saving).toFixed(2);


		let percentLeft = 100;
		let urgencyClass = 'red';
		if (product && product.stock && product.initStock) {
			// Percentage of tickets left
			percentLeft = ((product.stock / product.initStock) * 100).toFixed(2);
			
			if (percentLeft >= 55) {
				urgencyClass = 'green';
			}
			if (percentLeft > 40 && percentLeft < 55) {
				urgencyClass = 'amber';
			}
			if (percentLeft <= 40) {
				urgencyClass = 'red';
			}
		}
		

		let startDate = '';
		if (product && product.createdAt) {
			startDate = (new Date(product.createdAt ? product.createdAt : 0 * 1000)).toLocaleString();
			if (startDate.length > 0) {
				let dateMatch = startDate.match(/\d+\/\d+\/\d+/);
				if (dateMatch && dateMatch[0]) {
					startDate = dateMatch[0];
				}
			}
		};
		
		return (
			<div style={{backgroundColor: "#f2f2f2"}} className={`urgency-${urgencyClass}`}>
				
				{loading ? <div>Loading...</div> : 
					<div className="container-fluid py-3">
						<ProdSEO data={{
							id: id,
							name: product.name,
							description: product.excerpt ? product.excerpt : product.name,
							price: product.price,
							stock: product.stock,
							image: product.images[0].url,
							category: product.category,
							keywords: product.keywords,
						}} id={id} pageUrl={`https://hotdraw.co.uk/products/view/${id}`} />
						<div className="row">
							
							<div className="col-xs-12 text-left breadcrumb">
								<Link to="/" className="font-weight-bol2">Back to the competitions</Link>
							</div>
							
							{/* {product.video ? <div className="col-lg-5">
								<video controls="controls" className="video-stream" x-webkit-airplay="allow" src={product.video}></video>
							</div> : ''} */}
							
							<div className="col-lg-5">
								<button className="btn-ticket mb-2">
									{product.salePrice > 0
										? <span><strike className="mr-2 text-muted">{settings && settings.currencySymbol} {product.price}</strike> {settings && settings.currencySymbol} {product.salePrice}</span> 
										: <span>{settings && settings.currencySymbol}{product.price} per ticket</span>
									}
								</button>
								{imagesSlider.length ?
									<ImageGallery items={imagesSlider} defaultImage="/placeholder.jpg" showFullscreenButton={false} useBrowserFullscreen={false} showPlayButton={false}/>
									: 
									<img src="/placeholder.jpg" width="100%" alt="Not available" />
								}

								{/* <Collections /> */}
							</div>
							<div className="col-lg-7">
								<div className="card mb-3">
									<div className="card-body">
										<h4 className="text-secondary">{product.name}</h4>
										
										{product.stock > 0 && <div>
											<h4 className='stock'><span className={`stock-${urgencyClass}`}>{product.stock ? product.stock : 0}/<sup>{product.initStock ? product.initStock : 0}</sup></span></h4>
											<span className="sub">tickets remaining</span>
										</div>}
										
										<div className="mb-3">
											{/* {product.sku && <div>SKU: {product.sku}</div>}
											<div>Category: {product.category}</div> */}
											
											{/* <div className="media p-2 bg-light w-50 mt-3">
												<Link to={'/seller/' + product.author} className="mr-2">
													{seller && seller.avatarUrl 
														? <img src={seller.avatarUrl} alt="Avatar" height="80" />
														: <img src="/placeholder-avatar.jpg" alt="Avatar" height="80" />
													}
												</Link>
												<div className="media-body">
													<h6 className="pt-2"><Link to={'/seller/' + product.author}>{seller && (seller.storeName ? seller.storeName : seller.firstName + ' ' + seller.lastName)}</Link></h6>
													<p>Joined: {seller && new Date(seller.createdAt).toLocaleDateString('en-US')}</p>
												</div>
											</div> */}
										</div>
										
										<hr />
										
										{product.stock > 0 ? 
											<div>
												
												{Object.keys(cart).length && Object.values(cart).find(item => id === item.id) 
													? 
														<div className="mb-4">
															

															<Link to="/cart" onClick={() => {
																return ReactGA.event({
																	category: 'Products',
																	action: 'Click View Cart',
																	label: `User clicked View Cart`,
																})
															}} className="btn btn-atb btn-purple rounded-0 font-weight-bold px-4 py-2">View Cart</Link>
															<Link to="/" onClick={() => {
																return ReactGA.event({
																	category: 'Products',
																	action: 'Click View More',
																	label: `User clicked view more`,
																})
															}} className="btn btn-winner rounded-0 font-weight-bold px-4 py-2">View More</Link>
															<button className="btn btn-purple rounded-0 font-weight-bold px-4 py-2 mr-2" onClick={() => { 
																this.onRemoveFromCart()
																 
																return ReactGA.event({
																	category: 'Products',
																	action: 'Click Change Quantity',
																	label: `User clicked change Quantity`,
																})		 
															}}>Change Quantity</button>
															<button className="btn btn-purple rounded-0 font-weight-bold px-4 py-2 mr-2" onClick={() => { 
																this.onRemoveFromCart()

																return ReactGA.event({
																	category: 'Products',
																	action: 'Click Remove From Cart',
																	label: `User clicked remove from cart`,
																})
															}}>Remove From Cart</button>
														</div>
													: 
														<div>
															{/* <div className="mb-4">
																<select name="shippingType" onChange={this.onChange} value={shippingType} className="form-control ml-1 w-auto d-inline-block">
																	<option value="">Select Shipping</option>
																	{product.shippingMethod === 'Shipping options' 
																		?
																			<React.Fragment>
																				{product.shippingStandard && <option value="shippingStandard">{settings && settings.currencySymbol} {product.shippingStandard} on {shippingOptionsObject['shippingStandard']}</option>}
																				{product.shippingExpedited && <option value="shippingExpedited">{settings && settings.currencySymbol} {product.shippingExpedited} on {shippingOptionsObject['shippingExpedited']}</option>}
																				{product.shippingSameDayDelivery && <option value="shippingSameDayDelivery">{settings && settings.currencySymbol} {product.shippingSameDayDelivery} on {shippingOptionsObject['shippingSameDayDelivery']}</option>}
																			</React.Fragment>
																		: 
																			<React.Fragment>
																				<option value={product.shippingMethod}>{product.shippingMethod}</option>
																			</React.Fragment>
																	}
																</select>
															</div> */}
															
															<div className={product.showOffer ? 'show-offers mb-2' : 'mb-2'}>
																<p>1 ticket = £{product.price} with discounts on 5+</p>
																<QuantityTwo ticketsLeft={product.stock} change={(e) => this.quantChange(e)} discount={(amount) => this.addDiscount(amount)} showOffers={product.showOffer} />
															</div>
															<div className="total mb-3">
																{saving && <p className="old-total d-block">Was: £{ogTotal}</p>}
																<p className="new-total d-inline">Total: £{currentTotal ? currentTotal : quantity * 2}</p>
																{saving && <p className="saving-banner d-inline">Saving of £{saving}</p>}
															</div>
															<button 
																className="btn btn-atb btn-purple rounded-0 font-weight-bold px-4 py-2" 
																onClick={() => { 
																	/* Do not allow add to cart if option selected is not in list of available shipping options */
																	// if(shippingType in shippingOptionsObject){ 
																		this.onAddToCart();
																	// }
																}} 
																disabled={shippingType === ''}>
																Add To Cart
															</button>
															{shippingType === '' && <em className="text-danger ml-2">Select a shipping option</em>}
														</div>
												}
											</div>
											:
											<button className="btn btn-atb btn-sold-out btn-purple rounded-0 font-weight-bold px-4 py-2" disabled>Sold Out</button>
										}
											<Countdown date={product.endDate} showLabel={true} inDays={true}/>

											<p>Free entry route available, <br />please <a href="/terms#free">click here</a> for details</p> 
										{/*<hr />
										{Object.keys(cart).length ? 
											<div className="carousel-inner" >
												{Object.values(cart).map((item, index) => (
													<div key={index}>
														<div>{item.id}</div>
													</div>
												))}
											</div>
											:
											<div>Empty Cart</div>
										}*/} 
										{/* <USP showTitle={true} /> */}
										
										
										<hr />
										<div className="see-more text-left">
											{/* <p>{product.excerpt}</p> */}
											{parse(product.description)}
										</div>
										<TrustBox />
										<div className="ProductTerms">
												<h4>Competition Details</h4>
												<p><strong>For free entry route see our T&amp;C's</strong></p>

												<p>Start Date: </p><p>{startDate}</p>

												<p>Closing Date: </p>
												<Countdown date={product.endDate} showLabel={false}/>

												{/* <p>If all tickets do not sell out prior to the competition close date, then a 7 day extension may be added to the competition, this extension may be applied up to 3 times. If all tickets have not been sold out after 3 extensions, then the draw will be carried out with a cash alternative of 75% of ticket sales going to the winner instead of the prize.</p> */}
												<Link className="list-group-item list-group-item-action border-0" to="/terms">
													{/* <i className="material-icons align-middle mr-3">person</i>  */}
													View our full terms and conditions here
												</Link>
										</div>
									</div> 
								</div>
							</div>
						</div>
					</div>
				}
				
				{error && <div>{error.message}</div>} 
						
			</div>
		);
	}
}

/* We use compose() because each higher order component bellow dont depend on each other
   so instead of doing:
		withRouter(withFirebase(ViewProduct));
   we just organize it like bellow:
*/
const ViewProductPage = compose(
	withRouter,
	withFirebase,
)(ViewProduct);

export default ViewProductPage;