import React, { Component } from "react";
import { AboutList } from './list';
import ReactGA from 'react-ga';

class About extends Component {
	constructor(props) {
		super(props);
		this.state = {
			authUser: JSON.parse(localStorage.getItem('authUser')) 
		};
	}

	componentDidMount() {
		ReactGA.ga('send', 'pageview', '/about');
	}
	
	render() {
		// const { authUser } = this.state;
		return (
			<div className="row clearfix">
				<nav className="navbar navbar-dark bg-blue col-md-12"><span className="navbar-brand px-2">How it works</span></nav>
				<div className="card col-md-8 offset-md-2 py-3 px-4 my-2">
					<h4 className="card-title">Entering a Competition is Quick, Simple and Secure</h4>
					
					<AboutList />
					
				</div>
			</div>
		);
	}
}

export default About;