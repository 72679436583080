import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const TermsUse = () => {
    document.title = 'Terms of use';
    return (
        <div className="terms row">
            <div className="terms-text col-xs-12 col-sm-10 offset-sm-1 text-left py-4">
                <div className="col-xs-12 text-left mb-4">
                    <Link to="/" className="font-weight-bol2">Back to the competitions</Link>
                </div>
                <p><strong>Last updated: 22/11/2021</strong></p>
                
                <h1>Website Terms of Use</h1>
                
                <p>
                    <strong>
                        PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE
                    </strong>
                </p>
                <p>
                These terms tell you the rules for using our website<a href="https://hotdraw.co.uk">https://hotdraw.co.uk</a> (    <strong>our site</strong>).
                </p>
                <p>
                    <strong>Who we are and how to contact us</strong>
                </p>
                <p>
                    Our site is a site operated by HotDraw UK Ltd ("We"). We are registered in
                    England and Wales under company number 12524803 and have our registered
                    office at 2 Glamis Road, Newquay. TR7 2RY.
                </p>
                <p>
                    We are a limited company.
                </p>
                <p>
                    To contact us, please email help@hotdraw.co.uk
                </p>
                <p>
                    <strong>By using our site you accept these terms</strong>
                </p>
                <p>
                    By using our site, you confirm that you accept these terms of use and that
                    you agree to comply with them.
                </p>
                <p>
                    If you do not agree to these terms, you must not use our site.
                </p>
                <p>
                    We recommend that you print a copy of these terms for future reference.
                </p>
                <p>
                    <strong>There are other terms that may apply to you</strong>
                </p>
                <p>
                    These terms of use refer to the following additional terms, which also
                    apply to your use of our site:
                </p>
                <ul>
                    <li>
                        Our Privacy Policy (
                        <a href="https://hotdraw.co.uk/privacy">
                            https://hotdraw.co.uk/privacy
                        </a>
                        ), which sets out the terms on which we process any personal data we
                        collect from you, or that you provide to us. By using our site, you
                        consent to such processing and you warrant that all data provided by
                        you is accurate.
                    </li>
                    <li>
                        <Link to="/usePolicy">Our Acceptable Use Policy</Link>,
                        which sets out the permitted uses and prohibited uses of our site. When
                        using our site, you must comply with this Acceptable Use Policy.
                    </li>
                </ul>
                <p>
                    If you purchase goods or services from our site, participate in any
                    promotions or enter any of our competitions, other terms and conditions
                will apply and which you must accept and abide by. (    <a href="https://hotdraw.co.uk/terms">https://hotdraw.co.uk/terms</a>)
                </p>
                <p>
                    <strong>We may make changes to these terms</strong>
                </p>
                <p>
                    We may amend these terms from time to time. Every time you wish to use our
                    site, please check these terms to ensure you understand the terms that
                    apply at that time.
                </p>
                <p>
                    <strong>We may make changes to our site</strong>
                </p>
                <p>
                    We may update and change our site from time to time to reflect changes to
                our products, services, our users' needs and our business priorities    <strong>.</strong>
                </p>
                <p>
                    <strong>We may suspend or withdraw our site</strong>
                </p>
                <p>
                    Our site is made available free of charge but you may have to pay to enter
                    our competitions.
                </p>
                <p>
                    We do not guarantee that our site, or any content on it, will always be
                    available or be uninterrupted. We may suspend or withdraw or restrict the
                    availability of all or any part of our site for business and operational
                    reasons. We will try to give you reasonable notice of any suspension or
                    withdrawal.
                </p>
                <p>
                    You are also responsible for ensuring that all persons who access our site
                    through your internet connection are aware of these terms of use and other
                    applicable terms and conditions, and that they comply with them.
                </p>
                <p>
                    <strong>who can use our site?</strong>
                </p>
                <p>
                    <strong>Our site is only for users in the United Kingdom </strong>
                </p>
                <p>
                    Our site is directed to people residing in United Kingdom. We do not
                    represent that the content available on or through our site is appropriate
                    for use or available in other locations.
                </p>
                <p>
                    <strong>You must keep your account details safe</strong>
                </p>
                <p>
                    If you choose, or you are provided with, a user identification code,
                    password or any other piece of information as part of our security
                    procedures, you must treat such information as confidential. You must not
                    disclose it to any third party.
                </p>
                <p>
                    We have the right to disable any user identification code or password,
                    whether chosen by you or allocated by us, at any time, if in our reasonable
                    opinion you have failed to comply with any of the provisions of these terms
                    of use.
                </p>
                <p>
                    If you know or suspect that anyone other than you knows your user
                    identification code or password, you must promptly notify us at
                    help@hotdraw.co.uk.
                </p>
                <p>
                    <strong>How you may use material on our site</strong>
                </p>
                <p>
                    We are the owner or the licensee of all intellectual property rights in our
                    site, and in the material published on it. Those works are protected by
                    copyright laws and treaties around the world. All such rights are reserved.
                </p>
                <p>
                    You may print off one copy, and may download extracts, of any page(s) from
                    our site for your personal use and you may draw the attention of others
                    within your organisation to content posted on our site.
                </p>
                <p>
                    You must not modify the paper or digital copies of any materials you have
                    printed off or downloaded in any way, and you must not use any
                    illustrations, photographs, video or audio sequences or any graphics
                    separately from any accompanying text.
                </p>
                <p>
                    Our status (and that of any identified contributors) as the authors of
                    content on our site must always be acknowledged.
                </p>
                <p>
                    You must not use any part of the content on our site for commercial
                    purposes without obtaining a licence to do so from us or our licensors.
                </p>
                <p>
                    If you print off, copy or download any part of our site in breach of these
                    terms of use, your right to use our site will cease immediately and you
                    must, at our option, return or destroy any copies of the materials you have
                    made.
                </p>
                <p>
                    <strong>Do not rely on information on this site</strong>
                </p>
                <p>
                    The content on our site is provided for general information only. It is not
                    intended to amount to advice on which you should rely. You must obtain
                    professional or specialist advice before taking, or refraining from, any
                    action on the basis of the content on our site.
                </p>
                <p>
                    Although we make reasonable efforts to update the information on our site,
                    we make no representations, warranties or guarantees, whether express or
                    implied, that the content on our site is accurate, complete or up to date.
                </p>
                <p>
                    <strong>We are not responsible for websites we link to</strong>
                </p>
                <p>
                    Where our site contains links to other sites and resources provided by
                    third parties, these links are provided for your information only. Such
                    links should not be interpreted as approval by us of those linked websites
                    or information you may obtain from them.
                </p>
                <p>
                    We have no control over the contents of those sites or resources.
                </p>
                <p>
                    <strong>User-generated content is not approved by us</strong>
                </p>
                <p>
                    This website may include information and materials uploaded by other users
                    of the site, including posts made to our social media accounts. This
                    information and these materials have not been verified or approved by us.
                    The views expressed by other users on our site do not represent our views
                    or values.
                </p>
                <p>
                    If you wish to complain about information and materials uploaded by other
                    users please contact us at help@hotdraw.co.uk.
                </p>
                <p id="cookie">
                    <strong>Information about our use of cookies</strong>
                </p>
                <p>
                    We use cookies on our site for a couple of reasons. To anonymously monitor
                    site usage and to help us personalise the content delivered to you.
                </p>
                <p>
                    We use Google analytics to help us see how users interact with our website
                    and highlight points for improvement or errors.
                </p>
                <p>
                    In terms of personalising the website, we would use a’cookie’ to track
                    which category of prize you click on. If we know you’re interested in the
                    ‘Gaming’ category for example, we would then show more of those.
                </p>
                <p>
                    Cookies are stored on your own device and within the browsers storage and
                    usually persist until you reset your stored data within the browsers
                    settings. Cookies stored on your device are only accessible by your device
                    and the website that had assigned them as this would violate the
                    cross-domain policy of most browsers.
                </p>
                <p>
                    By continuing to browse the site, you are agreeing to our use of cookies.
                </p>
                <p>
                    We use the following cookies:
                </p>
                <ul>
                    <li>
                        <strong>Strictly necessary cookies.</strong>
                        These are cookies that are required for the operation of our website.
                        They include, for example, cookies that enable you to log into secure
                        areas of our website, use a shopping cart or make use of e-billing
                        services.
                    </li>
                    <li>
                        <strong>Analytical/performance cookies.</strong>
                        They allow us to recognise and count the number of visitors and to see
                        how visitors move around our website when they are using it. This helps
                        us to improve the way our website works, for example, by ensuring that
                        users are finding what they are looking for easily.
                    </li>
                    <li>
                        <strong>Functionality cookies.</strong>
                        These are used to recognise you when you return to our website. This
                        enables us to personalise our content for you, greet you by name and
                        remember your preferences (for example, your choice of language or
                        region).
                    </li>
                    <li>
                        <strong>Targeting cookies.</strong>
                        These cookies record your visit to our website, the pages you have
                        visited and the links you have followed. We will use this information
                        to make our website and the advertising displayed on it more relevant
                        to your interests. We may also share this information with third
                        parties for this purpose.
                    </li>
                </ul>
                <p>
                    Please note that third parties (including, for example, advertising
                    networks and providers of external services like web traffic analysis
                    services) may also use cookies, over which we have no control. These
                    cookies are likely to be analytical/performance cookies or targeting
                    cookies.
                </p>
                <p>
                    You can block cookies by activating the setting on your browser that allows
                    you to refuse the setting of all or some cookies. However, if you use your
                    browser settings to block all cookies (including essential cookies) you may
                    not be able to access all or parts of our site.
                </p>
                <p>
                    <strong>Our responsibility for loss or damage suffered by you</strong>
                </p>
                <ul>
                    <li>
                        We do not exclude or limit in any way our liability to you where it
                        would be unlawful to do so. This includes liability for death or
                        personal injury caused by our negligence or the negligence of our
                        employees, agents or subcontractors and for fraud or fraudulent
                        misrepresentation.
                    </li>
                    <li>
                        Different limitations and exclusions of liability will apply to
                        liability arising as a result of the supply of any products or services
                        to you or if you enter our competitions, which will be set out in our
                Terms and Conditions. (<a href="https://hotdraw.co.uk/terms">https://hotdraw.co.uk/terms</a>).
                    </li>
                    <li>
                        Please note that we only provide our site for domestic and private use.
                        You agree not to use our site for any commercial or business purposes,
                        and we have no liability to you for any loss of profit, loss of
                        business, business interruption, or loss of business opportunity.
                    </li>
                </ul>
                <p>
                    <strong>Uploading content to our site</strong>
                </p>
                <p>
                    Whenever you make use of a feature that allows you to upload content to our
                    site, post to our social media accounts or to make contact with other users
                    of our site, you must comply with the content standards set out in our
                    <Link to="/usePolicy">Acceptable Use Policy</Link>.
                </p>
                <p>
                    You warrant that any such contribution does comply with those standards,
                    and you will be liable to us and indemnify us for any breach of that
                    warranty. This means you will be responsible for any loss or damage we
                    suffer as a result of your breach of warranty.
                </p>
                <p>
                    Any content you upload to our site will be considered non-confidential and
                    non-proprietary. You retain all of your ownership rights in your content,
                    but you are required to grant us a limited licence to use, store and copy
                    that content and to distribute and make it available to third parties. The
                    rights you license to us are described below.
                </p>
                <p>
                    We also have the right to disclose your identity to any third party who is
                    claiming that any content posted or uploaded by you to our site constitutes
                    a violation of their intellectual property rights, or of their right to
                    privacy.
                </p>
                <p>
                    We have the right to remove any posting you make on our site if, in our
                    opinion, your post does not comply with the content standards set out in
                    our <Link to="/usePolicy">Acceptable Use Policy</Link>.
                </p>
                <p>
                    You are solely responsible for securing and backing up your content.
                </p>
                <p>
                    <strong>Rights you are giving us to use material you upload</strong>
                </p>
                <p>
                    When you upload or post content to our site, you grant us a perpetual,
                    worldwide, non-exclusive, royalty-free, transferable licence to use,
                    reproduce, distribute, prepare derivative works of, display, and perform
                    that user-generated content. We may also share it, quote from it and use it
                    to promote our site, products and services, particularly via social media.
                </p>
                <p>
                    <strong>
                        We are not responsible for viruses and you must not introduce them
                    </strong>
                </p>
                <p>
                    We do not guarantee that our site will be secure or free from bugs or
                    viruses.
                </p>
                <p>
                    You are responsible for configuring your information technology, computer
                    programmes and platform to access our site. You should use your own virus
                    protection software.
                </p>
                <p>
                    You must not misuse our site by knowingly introducing viruses, trojans,
                    worms, logic bombs or other material that is malicious or technologically
                    harmful. You must not attempt to gain unauthorised access to our site, the
                    server on which our site is stored or any server, computer or database
                    connected to our site. You must not attack our site via a denial-of-service
                    attack or a distributed denial-of service attack. By breaching this
                    provision, you would commit a criminal offence under the Computer Misuse
                    Act 1990. We will report any such breach to the relevant law enforcement
                    authorities and we will co-operate with those authorities by disclosing
                    your identity to them. In the event of such a breach, your right to use our
                    site will cease immediately.
                </p>
                <p>
                    <strong>Rules about linking to our site</strong>
                </p>
                <p>
                    You may link to our home page, provided you do so in a way that is fair and
                    legal and does not damage our reputation or take advantage of it.
                </p>
                <p>
                    You must not establish a link in such a way as to suggest any form of
                    association, approval or endorsement on our part where none exists.
                </p>
                <p>
                    You must not establish a link to our site in any website that is not owned
                    by you.
                </p>
                <p>
                    Our site must not be framed on any other site, nor may you create a link to
                    any part of our site other than the home page.
                </p>
                <p>
                    We reserve the right to withdraw linking permission without notice.
                </p>
                <p>
                    The website in which you are linking must comply in all respects with the
                    content standards set out in our <Link to="/usePolicy">Acceptable Use Policy</Link>.
                </p>
                <p>
                    If you wish to link to or make any use of content on our site other than
                    that set out above, please contact help@hotdraw.co.uk.
                </p>
                <p>
                    <strong>Which country's laws apply to any disputes?</strong>
                </p>
                <p>
                    These terms of use, their subject matter and their formation, are governed
                    by English law. You and we both agree that the courts of England and Wales
                    will have exclusive jurisdiction to deal with any disputes between us.
                </p>
            </div>    

        </div>
    )
};

export default TermsUse;