import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import cogoToast from 'cogo-toast';
import TextField from '@material-ui/core/TextField';

const INITIAL_STATE = {
	error: null,
	authUser: null,
	upload_avatar: ""
};

class AccountBasicFormBase extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			...INITIAL_STATE, 
			authUser: JSON.parse(localStorage.getItem('authUser')) 
		};
	}

	onSubmit = event => {
		event.preventDefault();
		
		const { authUser, upload_avatar } = this.state;
		let storageRef = this.props.firebase.storage;
		
		/* Upload images */
		if(upload_avatar.length){
			/* Delete previous avatar if existing */
			if(authUser.avatarPath){
				let imagetRef = storageRef.ref().child(`${authUser.avatarPath}`);
				imagetRef.delete();
			}

			/* Upload new avatar */
			let file = upload_avatar[0];
			let path = `images/avatar/${Math.random().toString(36).substring(7)}`;
			storageRef
				.ref(path)
				.put(file).then((snapshot) => {
					snapshot.ref.getDownloadURL().then((url) => {
						/* Apply the modification to the item directly without changing the current item index. */
						this.setState(prevState => ({
							authUser: {
								...prevState.authUser,
								avatarUrl: url,
								avatarPath: path,
							}
						}), () => {
							localStorage.setItem('authUser', JSON.stringify(this.state.authUser)); /* Update authUser from localStorage */
							this.props.firebase.user(authUser.uid).set({...this.state.authUser}, {merge: true})
						});
					})
				})
				.catch(error => {
					this.setState({ error });
				});
		}
		this.setState({upload_avatar: ""});
		
		/* Update user data in firebase */
		this.props.firebase.user(authUser.uid).update({
			firstName: authUser.firstName,
			lastName: authUser.lastName,
			// storeName: authUser.storeName ? authUser.storeName : '',
			phone: authUser.phone,
			city: authUser.city,
			country: 'United Kingdom',
			postCode: authUser.postCode,
			address: authUser.address,
			keywords: [
				authUser.firstName.toLowerCase(), 
				authUser.lastName.toLowerCase(), 
				authUser.phone, 
				authUser.email
			]
		})
		.then(() => {
			localStorage.setItem('authUser', JSON.stringify(authUser)); /* Update authUser from localStorage */
			cogoToast.success("Account updated");
			
		}).catch(error => {
			this.setState({ error });
			
		});
			
		
	};

	onChange = event => {
		event.persist(); /* Keep the original synthetic event around */
		
		/* Update the authUser state */
		this.setState(prevState => ({
			authUser: {
				...prevState.authUser,
				[event.target.name]: event.target.value
			}
		}));
	};

	onChangeInputFile(event) {
		const upload_avatar = Array.from(event.target.files);
		this.setState({ upload_avatar });   
	};
  
	render() {
		const { authUser, error } = this.state;

		const isInvalid =
			authUser.firstName === '' ||
			authUser.lastName === '' ||
			authUser.phone === '' ||
			authUser.city === '' ||
			authUser.state === '' ||
			authUser.country === '' ||
			authUser.postCode === '' ||
			authUser.address === '';
		  
		return (
			<form onSubmit={this.onSubmit} className="text-left">
				<div className="form-row">
					<div className="form-group col-sm-6">
						<label>First Name <span className="text-danger">*</span></label>
						<input
							name="firstName"
							defaultValue={authUser.firstName}
							onChange={this.onChange}
							type="text"
							className="form-control"
						/>
					</div>
					<div className="form-group col-sm-6">
						<label>Last Name <span className="text-danger">*</span></label>
						<input
							name="lastName"
							defaultValue={authUser.lastName}
							onChange={this.onChange}
							type="text"
							className="form-control"
						/>
					</div>
				</div>


				{/* {authUser.roles.indexOf('SELLER') > -1 &&
					<div className="form-group">
						<label>Store Name</label>
						<input
							name="storeName"
							defaultValue={authUser.storeName}
							onChange={this.onChange}
							type="text"
							className="form-control w-50"
						/>
					</div>
				} */}

				<div className="form-row">

					<div className="form-group col-xs-12 col-sm-6">
						<label>Mobile Number <span className="text-danger">*</span></label>
						<input
							name="phone"
							defaultValue={authUser.phone}
							onChange={this.onChange}
							type="text"
							className="form-control"
						/>
					</div>

					<div className="form-group col-xs-12 col-sm-6">
						<label>Post Code <span className="text-danger">*</span></label>
						<input
							name="postCode"
							defaultValue={authUser.postCode}
							onChange={this.onChange}
							type="text"
							className="form-control"
						/>
					</div>
					
				</div>
				
				<div className="form-group">
					<label>Address Line 1<span className="text-danger">*</span></label>
					<input
						name="address"
						defaultValue={authUser.address}
						onChange={this.onChange}
						type="text"
						className="form-control"
					>
					</input>
				</div>


				<div className="form-row">
					<div className="form-group col-sm-4">
						<label>City <span className="text-danger">*</span></label>
						<input
							name="city"
							defaultValue={authUser.city}
							onChange={this.onChange}
							type="text"
							className="form-control"
						/>
					</div>
					<div className="form-group col-sm-4">
						<label>County</label>
						<input
							name="county"
							defaultValue={authUser.county}
							onChange={this.onChange}
							type="text"
							className="form-control"
						/>
					</div>
					<div className="form-group col-sm-4">
						<label>Country <span className="text-danger">*</span></label>
						<select 
							name="country"
							value="United Kingdom"
							onChange={this.onChange}
							className="form-control"
						>
							<option value="United Kingdom">United Kingdom</option>
						</select>
					</div>
					

					<div className="form-group mt-3 mb-4 col-sm-4 d-none">
						<label className="d-block">Avatar <span className="text-danger">*</span></label>
						<label className="d-inline-block btn btn-atb mb-3">
							Change Avatar <input id="file" type="file" onChange={this.onChangeInputFile.bind(this)} className="d-none" multiple />
						</label>

						<div>
							{authUser.avatarUrl 
								? <img src={authUser.avatarUrl} alt="Avatar" height="150" />
								: <img src="/placeholder-avatar.jpg" alt="Avatar" height="150" />
							}
						</div>
					</div>
				</div>
				
				<button disabled={isInvalid} type="submit" className="btn btn-atb">
					Update
				</button>
				
				{error && <div>{error.message}</div>}
			</form>
		);
	}
}

/* We use compose() because each higher order component bellow dont depend on each other
   so instead of doing:
		withRouter(withFirebase(AccountBasicFormBase));
   we just organize it like bellow:
*/
const AccountBasicForm = compose(
	withRouter,
	withFirebase,
)(AccountBasicFormBase);

export default AccountBasicForm;