import React, { Component } from "react";
import { Link } from 'react-router-dom';
import FAQList from './faqList';
import TrustBox from '../Trustpilot/index';
import ReactGA from 'react-ga';

import './styles.scss';

class FAQ extends Component {
	constructor(props) {
		super(props);
		this.state = {
			authUser: JSON.parse(localStorage.getItem('authUser')) 
		};
	}

	componentDidMount() {
		document.title = 'FAQ';

		ReactGA.ga('send', 'pageview', '/faq');
	} 

	
	render() {
		
		return (
			<div>
				<nav className="navbar navbar-dark bg-blue">
					<span className="navbar-brand">Frequently Asked Questions</span>
				</nav>

				<div className="faq-wrap">
					<div className="my-4">
						<h4>We appreciate things aren't always clear at first</h4>
						<p className="mt-3 text-center">So we've gathered together our most frequently asked questions to help.</p>

						<FAQList />

						<div className="my-5">
							<h5 className="mb-4">Still unsure?</h5>

							<p className="mb-0">Check out our reviews on </p>
							<TrustBox />

							<p><strong>or</strong></p>
							
							<p>View our <Link to="/home">happy winners</Link></p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default FAQ;