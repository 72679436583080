export const COLLECTIONS = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const FAQ = '/faqs';
export const ABOUT = '/about';
export const ACCOUNT = '/account';
export const ACCOUNT_SELLER = '/seller/:id';
export const ADMIN_SETTINGS_GENERAL = '/admin/settings/general';
export const ADMIN_SETTINGS_PRODUCT = '/admin/settings/product';
export const ADMIN_SETTINGS_CHECKOUT = '/admin/settings/checkout';
export const ADMIN_USERS = '/admin/users';
export const ADMIN_USERS_SINGLE = '/admin/users/:id';
export const PASSWORD_FORGET = '/pw-forget';
export const PRODUCTS = '/products';
export const CART = '/cart';
export const CHECKOUT = '/checkout';
export const CHECKOUT_SUCCESS = '/checkout/success/:id';
export const CREATE_PRODUCT = '/products/new';
export const UPDATE_PRODUCT = '/products/update/:id';
export const VIEW_PRODUCT = '/products/view/:id';
export const NOT_FOUND = '/404-not-found';
export const CUSTOMER_ORDERS = '/orders/customer';
export const VIEW_CUSTOMER_ORDER = '/orders/customer/view/:id';
export const SELLER_ORDERS = '/orders/seller';
export const VIEW_SELLER_ORDER = '/orders/seller/view/:id';
export const TERMS = '/terms';
export const PRIVACY_POLICY = '/privacy';
export const MERCH = '/merch';
export const USE_POLICY = '/usePolicy';
export const TERMS_USE = '/termsUse';