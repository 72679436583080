import React, { Component } from "react";
import { withFirebase } from '../Firebase';
import ReactGA from 'react-ga';
import Slider from "react-slick";
import './styles.scss';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

var settings = {
	dots: true,
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1
};

class MerchCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			authUser: JSON.parse(localStorage.getItem('authUser')) 
		};
    }

	componentDidMount() {
        const { merchObj } = this.props;

        ReactGA.ga('send', 'pageview', '/merch');
        
        // document.title = merchObj.name ? merchObj.name : 'HD Merchandise';
	}
	
	render() {
        const { merchObj } = this.props;
        const dataObj = merchObj.data;
		console.log(dataObj);
		

		return (
			<div className="row clearfix">
				{/* <nav className="navbar navbar-dark bg-blue col-md-12"><span className="navbar-brand px-2">HD Merch</span></nav> */}
				<div className="card px-4 my-2">
					
					<div className="merchSlider">

						<div>
						<Slider {...settings}>
							{dataObj.images ? dataObj.images.map((img) => {
								return <img src={img} alt={dataObj.name} />
							}) : ''}
						</Slider>
						</div>

					</div>

                    <h3>{dataObj ? dataObj.name : ''}</h3>
					<p>£{dataObj.price ? parseInt(dataObj.price, 10).toFixed(2) : ''}</p>
				</div>
			</div>
		);
	}
}

export default MerchCard;