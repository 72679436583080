import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import './styles.scss';

const ScrollUp = () => {
    const [show, setShow] = useState(false);

    const onClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    useEffect(_ => {
        const handleScroll = function(_) {
            if (this.scrollY > 900) {
                setShow(true);          
            } else {
                setShow(false);
            }
        }
        // const handleScroll = _ => { 
        // }
        window.addEventListener('scroll', handleScroll)
        return _ => {
          window.removeEventListener('scroll', handleScroll)
        }
      }, [])

      return <button className={show ? 'scrollUp show' : 'scrollUp'} onClick={onClick}>Scroll to top</button>

};

export default ScrollUp;