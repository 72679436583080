import React from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

document.title = '404 Not Found';

ReactGA.ga('send', 'pageview', '/notfound');

const NotFound = () => (
	<div className="container-fluid py-3" style={{backgroundColor: "#f2f2f2"}}>
		<div className="card mb-3">
			<div className="card-body text-center py-5">
				<h1>Whoops, something's gone wrong.</h1>
				<h4>Sorry but you've stumbled into a broken link. This page doesn't seem to exist!</h4>

				<hr></hr>
				<p>To get back to the main show, either click <Link to="/">this link here</Link>, or by clicking the logo at the top!</p>


				<p>If this problem persists, please let us know,<br /> by emailing <a href="mailto:help@hotdraw.co.uk">help@hotdraw.co.uk</a> and we can get our tech team right on it!</p>
			</div>
		</div>
	</div>
);

export default NotFound;