import React from "react";
import { Link } from "react-router-dom";

export const AboutList = () => {
    return(
        <div className="about-list my-3 card">

            <ul className="list-group list-group-flush text-left">
                <li className="list-group-item">
                <span>1</span>
                <p>Sign in or create an account with us. It's quick, simple (especially with the social logins) and it means next time you visit you'll be straight in!</p>
                </li>
                <li className="list-group-item">
                <span>2</span>
                <p>View our latest live <Link to="/">competitions</Link> to choose from. All of which are live and updated constantly.</p>
                </li>
                <li className="list-group-item">
                <span>3</span>
                <p>Select your number of entries or enter for <i>free</i> via our <Link to="/terms">postal method</Link>.</p>
                </li>
                <li className="list-group-item">
                <span>4</span>
                <p>Head through the checkout, confirming your billing details are correct and that you're happy with our <Link to="/terms">terms &amp; conditions</Link>.</p>
                </li>
                <li className="list-group-item">
                <span>5</span>
                <p>We will then send you a confirmation email with details of your purchase and information on what to do next.</p>
                </li>
                <li className="list-group-item">
                <span>6</span>
                <p>Either the tickets all sell out, or the deadline arrives and we draw a winner! Randomly selected with <Link to="https://wheelofnames.com/">Wheelofnames.com</Link> live streamed on our <Link to="http://facebook.com/hotdrawuk">Facebook page</Link>.</p>
                </li>
                <li className="list-group-item">
                <span>7</span>
                <p>The winner will be contacted by email and phone from one of our team to arrange delivery, as soon as the draw is done! We aim to get your prize to you ASAP, and have done so far for all of our previous <Link to="/home">winners!</Link></p>
                </li>
            </ul>
        </div> 
    );
};