import React from 'react';
import { convertDate, convertDateToDays } from '../../helpers/global';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
// import Moment from 'react-moment';
// import moment from 'moment'
// import 'moment-timezone';

import './styles.scss';


export default function Countdown(props) {

    const { date, showLabel, inDays } = props;

    let string = `Closing date: ${convertDate(date)}`;


    var isoDate = new Date(date).toISOString();
    
    var utc = new Date().toJSON().slice(0,10).replace(/-/g,'/');
    
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // a and b are javascript Date objects
    function dateDiffInDays(a, b) {
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }


    let daysDiff = dateDiffInDays(new Date(utc), new Date(isoDate));
    return (
        <div className="end-date">
            {/* {date && daysDiff === 0 ? <div className="end-date__label">Ending</div> : null} */}
            {date && <p>
                
                {inDays ? 
                daysDiff > 0 ? `${daysDiff} ${daysDiff > 1 ? 'days' : 'day'}` : 'Ending 9PM Tonight!'
                 : string}

                <span className="hourglass rotating">
                    <HourglassEmptyIcon />
                </span>
            </p>}
        </div>
    );
}