import React, { Component } from "react";
import { withFirebase } from '../Firebase';
import MerchCard from './merchCard';
import ReactGA from 'react-ga';

let INITIAL_STATE = {
    merch: [],
    loading: true,
}

class Merch extends Component {
	constructor(props) {
		super(props);
		this.state = {
            authUser: JSON.parse(localStorage.getItem('authUser')),
            ...INITIAL_STATE,
		};
    }
    
    async fetchMerch() {
        var merchQuery = this.props.firebase.merch();

        const merchSnap = await merchQuery.where('active', '==', true).get();
        console.log(merchSnap)
        if (merchSnap.empty) {
            console.log('No matching merch.');
            return;
        }  

        merchSnap.forEach(doc => {
            let { id } = doc;
            const ob = {
                id,
                data: doc.data()
            }
            this.setState({
                merch: this.state.merch.concat(ob)
            })
        });
        
    }

	componentDidMount() {
        ReactGA.ga('send', 'pageview', '/merch');
        
        document.title = 'HD Merch';

        this.fetchMerch().then(() => {
            this.setState({
                loading: false,
            })
        });

        
	}
	
	render() {
        const { loading, merch } = this.state;
        
        const MerchCards = merch.map((ob, index) => {
            return (
                <MerchCard merchObj={ob} key={index} />
            )
        });

        // console.log('card, ', merchCards);

		return (
            <div className="row clearfix">    
                {loading ? <div>Loading...</div> : 
                <div className="col-md-12">
                <nav className="navbar navbar-dark bg-blue"><span className="navbar-brand px-2">HD Merch</span></nav>
                    <div className="col-md-10 offset-md-1 py-3 px-4 my-2">
                        
                        <h1>HotDraw UK Clothing</h1>
                        <p>Using the finest quality garments, we have teamed up with our clothing partner Nigel who's bringing the best clothing to you.</p>

                        {MerchCards ? MerchCards : ''}
                    </div>    
                </div>}
            </div>
		);
	}
}

export default withFirebase(Merch);