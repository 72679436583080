import React, { useState } from 'react';
import ReactGA from 'react-ga';
import SearchIcon from '@material-ui/icons/Search';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AddIcon from '@material-ui/icons/Add';
import HttpsIcon from '@material-ui/icons/Https';
import CallIcon from '@material-ui/icons/Call';
import './styles.scss';

function USP(props) {
    const [showTooltip, setShowTooltip] = useState(false);
    const { showTitle } = props;
    return(
        <div className="usp-banner">
            {showTitle ? <h4>How it works</h4> : ''}
            
            <ul>
                {/* <li>
                    <div>
                        <SearchIcon />

                        <h5>Find the competition you want to enter</h5>
                    </div>
                </li> */}
                <li>
                    <div>
                        <AddIcon />

                        <h5>Add up to 50 tickets (20% off)</h5>
                        
                    </div>
                </li>
                <li>
                    <div>
                        <HttpsIcon />

                        <h5>Checkout securely using PayPal or Stripe</h5>
                    </div>
                </li>
                <li>
                    <div>
                        <ConfirmationNumberIcon />

                        <h5>Your name will be entered X the number of tickets bought</h5>
                    </div>
                </li>
                <li>
                    <div>
                        <CallIcon />

                        <h5>We call the winner right after the live Facebook draw!</h5>
                    </div>
                </li>
            </ul>
        </div>   
    )
}

export default USP;