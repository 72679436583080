import React from 'react';
import { Helmet } from 'react-helmet';

export default function ProdSEO({ data, id, pageUrl }) {

    const getPageMetadata = () => {
        
        return {
            title: data.name ? data.name : 'no name',
            description: data.description ? data.description : 'no description', 
            keywords: data.keywords ? data.keywords : 'no keywords',
            robots: '',
            canonicalUrl: 'https://hotdraw.co.uk',
        };
    };
    
    const meta = getPageMetadata();
    let s = {
        "@context": "http://schema.org",
        "@type": "Product",
        "name": data.name,
        "productID": id,
        "sku": id,
        "id": id,
        "image": data.image,
        "image_link": data.image,
        "price": parseFloat(data.price).toFixed(2),
        "availability": data.stock > 0 ? 'in stock' : 'out of stock',
        "description": data.description,
        "link": pageUrl,
        "fb_product_category": "sporting goods > exercise & fitness",
        "google_product_category": "sporting goods > exercise & fitness",
        "title": data.name,
        "offers": [
            {
              "@type": "Offer",
              "url": pageUrl,
              "price": parseFloat(data.price).toFixed(2),
              "priceCurrency": "GBP",
              "itemCondition": "https://schema.org/NewCondition",
              "availability": data.stock > 0 ? "https://schema.org/InStock" : "https://schema.org/OutOfStock"
            }
        ],
        "additionalProperty": [{
            "@type": "PropertyValue",
            "propertyID": "2777510749188479",
            "value": "hd_prizes"
        }],
        "identifier_exists": "no"
    };

    
    
    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(s)}</script>

            <meta
            name="description"
            content={data.description}
            />

            {/* <!-- Google / Search Engine Tags --> */}
            <meta itemprop="name" content={data.name} />
            <meta
            itemprop="description"
            content={data.description}
            />
            <meta
            itemprop="image"
            content={data.image}
            />

            {/* <!-- Facebook Meta Tags --> */}
            <meta property="og:url" content={pageUrl} />
            <meta property="og:type" content="product" />
            <meta property="og:title" content={data.name} />
            <meta
            property="og:description"
            content={data.description}
            />
            <meta
            property="og:image:source"
            content={data.image}
            />

            <meta http-equiv="X-Frame-Options" content="deny" />
            {/* <meta http-equiv="Content-Security-Policy" content="script-src 'self'; style-src 'self'; ..." /> */}
            
            {/* <meta name="brand" content="hotdraw" />
            <meta name="og:type" content="Product" />
            <meta name="og:title" content={data.name} />
            <meta name="name" content={data.name} />
            <meta name="og:description" content={data.description} />
            <meta name="productID" content={data.id} />
            <meta name="url" content={data.link} />
            <meta name="og:image" content={data.image} /> 
            <meta name="image" content={data.image} /> 
            <div itemscope itemtype="http://schema.org/Product">
                <div itemprop="value" itemscope itemtype="http://schema.org/PropertyValue">
                    <span itemprop="name">item_group_id</span>
                    <meta itemprop="value" content="True">{data.name}</meta>
                </div>
                <div itemprop="offers" itemscope itemtype="http://schema.org/Offer">
                    <link itemprop="availability" href={data.stock > 0 ? 'in stock' : 'out of stock'} />
                    <link itemprop="itemCondition" href="http://schema.org/NewCondition" />
                    <meta itemprop="price" content={s.price} />
                    <meta itemprop="priceCurrency" content="GBP" />
                </div>
            </div> */}
        </Helmet>
    );
  }