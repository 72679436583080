import React from 'react';
import ReactGA from 'react-ga';
import './styles.scss';

class Quantity extends React.Component {
    constructor(props) {
      super(props);
      
      this.state = {value: 1}
    }
    

    render() {
      const { change, ticketsLeft, showOffers, discount } = this.props;

      const onClick = (e) => {
        const activeButton = document.querySelector('button.qty-active');
        if (activeButton) {
          activeButton.classList.remove('qty-active');
        }

        const { target } = e;
        const text = target.textContent;
        
        if (target) {
          target.classList.add('qty-active');

          change(e);

          if (target.getAttribute('discount')) {
            discount(target.getAttribute('discount'));
          } else {
            discount(0);
          }

          return ReactGA.event({
            category: 'Quantity',
            action: 'Click Quantity',
            label: `User selected ${text} with ${target.getAttribute('discount') ? target.getAttribute('discount') : 0}% discount`,
          });

        }
      };


      

      return (
        <div>
          <h5>Number of Entries</h5>

          <div className="product-qty">
            {ticketsLeft >= 1 && <button value="1" name="quantity" className="qty-active" onClick={(e) => onClick(e)}>1</button>}
            {ticketsLeft >= 2 && <button value="2" name="quantity" onClick={(e) => onClick(e)}>2</button>}
            {ticketsLeft >= 3 && <button value="3" name="quantity" onClick={(e) => onClick(e)}>3</button>}
            {ticketsLeft >= 5 && <button value="5" name="quantity" discount={showOffers ? 5 : null} onClick={(e) => onClick(e)}>
              5 {showOffers && <sup>5% Off</sup>}
            </button>}
            {ticketsLeft >= 10 && <button value="10" className="btn-lessXPad" name="quantity" discount={showOffers ? 10 : null} onClick={(e) => onClick(e)}>
            10 {ticketsLeft < 30 && <span>(most popular)</span>} {showOffers && <sup>10% Off</sup>}
            </button>}
            {ticketsLeft >= 20 && <button value="20" className="btn-topQty" name="quantity" discount={showOffers ? 12.5 : null} onClick={(e) => onClick(e)}>
              20 <span>(best chance)</span> {showOffers && <sup>12.5% Off</sup>}
            </button>}
          </div>
        </div>
      );
    }
  }
  
  const QuantityTwo = (props) => {
    const { change, ticketsLeft, showOffers, discount } = props;
  
    const [value, setValue] = React.useState(ticketsLeft < 5 ? ticketsLeft : 5);
    
    const inputRef = React.useRef(null);


    // Check stock
    // if (ticketsLeft < 5 || ticketsLeft < value) {
    //   setValue(ticketsLeft)
    // }

    const update = (val) => change(val);

    

    // const discountCheck = (val) => {
    //   if (val < 5) {
    //     return discount(0);
    //   }
    //   console.log('val ', val);
    //   if (val >= 5 && val < 10) {
    //     return discount(5);
    //   } else if (val >= 10 && val < 20) {
    //     return discount(10);
    //   } else if (val >= 20 && val < 40) { 
    //     return discount(15);
    //   } else if (val >= 40) {
    //     return discount(20);
    //   }
    // }

    const increase = () => {
      let val = parseInt(value, 10);
      if (ticketsLeft > val && val < 50) {
        update(val + 1);
        setValue(val + 1);

        // discountCheck(val + 1);
      }
      
    }

    const decrease = () => {
      let val = parseInt(value, 10);
      if (val > 1) {
        update(val - 1);
        setValue(val - 1);

        // discountCheck(val - 1);
      }

      
    }

    const changeInput = (e) => {
      if (e && e.target.value) {
        setValue(e.target.value);
        update(e.target.value);
        // discountCheck(e.target.value)
      }
    }

    const ShowBadge = () => <div className="discount-badge">
      {value >= 5 && value < 10 && <p>5% Off</p>}
      {value >= 10 && value < 20 && <p>10% Off, Nice.</p>}
      {value >= 20 && value < 40 && <p>Whoop! 15% Off</p>}
      {value >= 40 && <p>Big Savings... 20% Off!</p>}
    </div>

    return(
      <div className="quantity-new">

        <div className="number">
          <span className="minus" onClick={decrease}>
            <svg version="1.1" x="0px" y="0px" viewBox="0 0 512 512" style={{enableBackground: "new 0 0 512 512"}} xmlSpace="preserve"><g><g><path d="M492,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,236,492,236z"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
          </span>
          <input type="number" ref={inputRef} onChange={(e) => changeInput(e)} value={value} min="1" max="50" maxLength={2} onKeyDown={(e) => e.preventDefault(e)}/>
          <span className="plus" onClick={increase}>
            <svg version="1.1" x="0px" y="0px" viewBox="0 0 512 512" style={{enableBackground: "new 0 0 512 512"}} xmlSpace="preserve"><g><g><path d="M492,236H276V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v216H20c-11.046,0-20,8.954-20,20s8.954,20,20,20h216v216c0,11.046,8.954,20,20,20s20-8.954,20-20V276h216c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
          </span>
        </div>

        <ShowBadge />

      </div>
    )
  }


  export default QuantityTwo;