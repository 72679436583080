import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import cogoToast from 'cogo-toast';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import './styles.scss';


class Navigation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			authUser: JSON.parse(localStorage.getItem('authUser')),
			cartItemsCount: 0,
			inFooter: false,
		};

	}

	componentDidMount() {		
		const { inFooter, cartAmount } = this.props;
		
		this.setState({
			inFooter: inFooter,
			authUser: JSON.parse(localStorage.getItem('authUser')),
		});

		const { authUser } = this.state;
		
		if(authUser){
			
			this.unsubscribeCartItems = this.props.firebase.cartItems().doc(authUser.uid).onSnapshot(snapshotCart => { /* The onSnapshot() method registers a continuous listener that triggers every time something has changed, use get() to only call it once (disable realtime) */
				
				this.setState({
					cartItemsCount: snapshotCart.data() ? Object.keys(snapshotCart.data()).length: 0,
				});
			});
			// if (!cartAmount) {
			// } else {
			// 	this.setState({
			// 		cartItemsCount: cartAmount
			// 	});
			// }

		}
		
		
	}
	


	/* On unmount, we remove the listener to avoid memory leaks from using the same reference with the off() method: */
	componentWillUnmount() {
		this.unsubscribeCartItems();
	}

	
	render() {			
		const { loading, cartItemsCount } = this.state;
		const { match, location, history } = this.props;
		

		let bodyClasses = document.body.classList;
		bodyClasses.forEach(elClass => {
			if (elClass.match('page')) {
				document.body.classList.remove(elClass);
			}
		});
		

		switch (location.pathname) {
			case '/':
				document.body.classList.add('page-home');
				break;
			case '/cart':
				document.body.classList.add('page-cart');
				break;
			case '/checkout':
				document.body.classList.add('page-checkout');
				break;
			default:
				break;
		}

		return (
			<AuthUserContext.Consumer>
				
				{authUser =>
					authUser ? (
						<NavigationAuth authUser={authUser} cartItemsCount={cartItemsCount} inFooter={this.state.inFooter} justCart={this.props.justCart} closeMenu={this.props.closeMenu} />
					) : (
						this.props.justCart ? <NavLink className="text-center header-signin" exact to={ROUTES.SIGN_IN}>
						<i className="material-icons align-middle md-30">person</i> 
						{/* Sign in<br />Register */}
					</NavLink> : <NavigationNonAuth inFooter={this.state.inFooter} closeMenu={this.props.closeMenu}/>
					)
				}
			</AuthUserContext.Consumer>
		);
	}
}

const toggleNav = (evt) => {
	if (!evt.target) return;
	const { target } = evt;
	const evtParent = target.closest('a');
	
	const dTarget = evtParent.getAttribute('data-target');
	const nextSibling = document.querySelector(dTarget);
	
	if (nextSibling) {
		evtParent.classList.toggle('collapsed');
		evtParent.classList.toggle('show');
		nextSibling.classList.toggle('collapse');
		nextSibling.classList.toggle('show');
	}
}



const NavigationAuth = ({ authUser, cartItemsCount, inFooter, justCart, closeMenu }) => (
	<div className={justCart ? 'list-group accordion border-right navigation col-md-10 text-right d-inline-block text-right just-cart' : 'list-group accordion border-right navigation col-md-10 text-right d-inline-block text-right'} id="navAccordion" >
		<NavLink exact className="list-group-item text-center list-group-item-action border-0" to={ROUTES.COLLECTIONS} onClick={closeMenu}>
			{/* <i className="material-icons align-middle mr-3">inbox</i>  */}
			Home
		</NavLink>
		<NavLink exact className="list-group-item text-center list-group-item-action border-0" to={ROUTES.HOME} onClick={closeMenu}>
			{/* <i className="material-icons align-middle mr-3">inbox</i>  */}
			Winners
		</NavLink>
		{/* <NavLink exact className="list-group-item text-center list-group-item-action border-0" to={ROUTES.COLLECTIONS} onClick={() => {
			cogoToast.info("Coming soon! Watch this space for more.");
			closeMenu();
			ReactGA.event({
				category: 'Merch',
				action: 'Click',
				label: 'User clicked merch link'
			});
		}}>
			Merch
		</NavLink> */}
		<NavLink exact className="comp-link list-group-item text-center list-group-item-action border-0" to={ROUTES.COLLECTIONS} onClick={closeMenu}>
			{/* <i className="material-icons align-middle mr-3">inbox</i>  */}
			Competitions
		</NavLink>
		<NavLink exact className="list-group-item text-center list-group-item-action border-0" to={ROUTES.FAQ} onClick={closeMenu}>
			{/* <i className="material-icons align-middle mr-3">inbox</i>  */}
			FAQ's
		</NavLink>
		<NavLink exact className="list-group-item text-center list-group-item-action border-0" to={ROUTES.ABOUT} onClick={closeMenu}>
			{/* <i className="material-icons align-middle mr-3">inbox</i>  */}
			How It Works
		</NavLink>
		{authUser.roles.includes(ROLES.CUSTOMER) && (
			<NavLink className="list-group-item text-center list-group-item-action border-0 icon-menu-item" to={ROUTES.CART} onClick={closeMenu}>
				{!inFooter ? <i className="material-icons align-middle mr-3">shopping_cart</i> : 'Cart '} 
				({cartItemsCount})
			</NavLink>
		)}
		
		<div className="d-block account-nav profile rel icon-menu-item" >
			
			<NavLink to="#" className="hide-mobile list-group-item text-center list-group-item-action border-0 collapsed" onClick={(e) => toggleNav(e)} data-toggle="collapse" data-target="#profile" aria-expanded="false">
				<div className="d-inline-block">
				<i className="material-icons align-middle mr-3">person</i> 
				{/* Profile */}
				<i className="material-icons float-right">expand_more</i>
				</div>
			</NavLink>
			
			<div className="list-group list-group-flush collapse text-center d-inline-block" id="profile" data-parent="#navAccordion">
				<NavLink className="list-group-item text-center px-5 py-3 list-group-item-action border-1 bg-light" to={ROUTES.ACCOUNT} onClick={closeMenu}>
					{/* <i className="material-icons align-middle mr-3">panorama_fish_eye</i>  */}
					Account
				</NavLink>
				<NavLink exact className="list-group-item text-center px-5 py-3 list-group-item-action border-1 bg-light" to={ROUTES.CUSTOMER_ORDERS} onClick={closeMenu}>
					{/* <i className="material-icons align-middle mr-3">panorama_fish_eye</i>  */}
					Orders
				</NavLink>

			</div>
		</div>
		
		
		{authUser.roles.includes(ROLES.SELLER) && (
			<div className="d-inline-block account-nav rel text-right hide" onClick={(e) => toggleNav(e)}>
				<NavLink to="#" className="list-group-item text-center list-group-item-action border-0 collapsed" data-toggle="collapse" data-target="#seller" aria-expanded="false">
					<i className="material-icons align-middle mr-3">store</i> 
					Seller
					<i className="material-icons float-right">expand_more</i>
				</NavLink>
				
				<div className="list-group list-group-flush collapse" id="seller" data-parent="#navAccordion">
					<NavLink exact className="list-group-item text-center list-group-item-action border-0 bg-light" to={ROUTES.PRODUCTS}>
						<i className="material-icons align-middle mx-3">panorama_fish_eye</i> 
						Products
					</NavLink>
					<NavLink exact className="list-group-item text-center list-group-item-action border-0 bg-light" to={ROUTES.CREATE_PRODUCT}>
						<i className="material-icons align-middle mx-3">panorama_fish_eye</i> 
						New Product
					</NavLink>
					<NavLink exact className="list-group-item text-center list-group-item-action border-0 bg-light" to={ROUTES.SELLER_ORDERS}>
						<i className="material-icons align-middle mx-3">panorama_fish_eye</i> 
						Orders
					</NavLink>
				</div>
			</div>
		)}
		{authUser.roles.includes(ROLES.ADMIN) && (
			<div className="d-inline-block account-nav rel text-right hide" onClick={(e) => toggleNav(e)}>
				<NavLink to="#" className="list-group-item text-center list-group-item-action border-0 collapsed" data-toggle="collapse" data-target="#admin" aria-expanded="false">
					<i className="material-icons align-middle mr-3">timeline</i> 
					Admin
					<i className="material-icons float-right">expand_more</i>
				</NavLink>
				
				<div className="list-group list-group-flush collapse" id="admin" data-parent="#navAccordion">
					<NavLink to={ROUTES.ADMIN_USERS} className="list-group-item text-center list-group-item-action border-0 bg-light">
						<i className="material-icons align-middle mx-3">panorama_fish_eye</i> 
						Users
					</NavLink>
					<NavLink to={ROUTES.ADMIN_SETTINGS_GENERAL} className="list-group-item text-center list-group-item-action border-0 bg-light">
						<i className="material-icons align-middle mx-3">panorama_fish_eye</i> 
						Settings
					</NavLink>
				</div>
			</div>
		)}
		<div className="list-group d-block signout">
			<SignOutButton />
		</div>
		
	</div>
);

const NavigationNonAuth = ({closeMenu}) => (
	<div className="list-group borderless navigation col-md-10 text-right justify-content-end d-inline-block" id="navAccordion" onClick={closeMenu}>
		<NavLink exact className="list-group-item text-center list-group-item-action border-0" to={ROUTES.COLLECTIONS} onClick={closeMenu}>
			{/* <i className="material-icons align-middle mr-3">inbox</i>  */}
			Home
		</NavLink>
		<NavLink exact className="list-group-item text-center list-group-item-action border-0" to={ROUTES.HOME} >
			{/* <i className="material-icons align-middle mr-3">inbox</i>  */}
			Winners
		</NavLink>
		{/* <NavLink exact className="list-group-item text-center list-group-item-action border-0" to={ROUTES.COLLECTIONS} onClick={() => {
			cogoToast.info("Coming soon! Watch this space for more.");
			closeMenu();
			ReactGA.event({
				category: 'Merch',
				action: 'Click',
				label: 'User clicked merch link'
			});
		}}>
			Merch
		</NavLink> */}
		<NavLink exact className="comp-link list-group-item text-center list-group-item-action border-0" to={ROUTES.COLLECTIONS} onClick={closeMenu}>
			{/* <i className="material-icons align-middle mr-3">inbox</i>  */}
			Competitions
		</NavLink>
		<NavLink exact className="list-group-item text-center list-group-item-action border-0" to={ROUTES.FAQ} onClick={closeMenu}>
			{/* <i className="material-icons align-middle mr-3">inbox</i>  */}
			FAQ's
		</NavLink>
		<NavLink exact className="list-group-item text-center list-group-item-action border-0" to={ROUTES.ABOUT} onClick={closeMenu}>
			{/* <i className="material-icons align-middle mr-3">inbox</i>  */}
			How It Works
		</NavLink> 
		<NavLink className="list-group-item text-center list-group-item-action border-0" exact to={ROUTES.SIGN_IN} onClick={closeMenu}>
			{/* <i className="material-icons align-middle mr-3">person</i>  */}
			Sign In
		</NavLink>
		
	</div>
);


export default withFirebase(withRouter(Navigation));