import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

const ProductTerms = () => {
    document.title = 'Terms and Conditions';
    return (
        <div className="terms row">
            <div className="terms-text col-xs-12 col-sm-10 offset-sm-1 text-left py-4">
                <div className="col-xs-12 text-left mb-4">
                    <Link to="/" className="font-weight-bol2">Back to the competitions</Link>
                </div>
                <p><strong>Last updated: 16/11/2021</strong></p>
                <h4>Standard Competition Terms and Conditions</h4>
                <p>These standard competition terms and conditions (these &ldquo;<strong>terms</strong>&rdquo;) set out the rules which relate to all competitions organised by us. The competition specific terms will be set out on the competition details page where you will apply to enter each competition (or accepted in your online account if you are submitting a free postal entry). The contract between us will incorporate these terms and the competition specific terms set out on the competition details page (&ldquo;<strong>Contract</strong>&rdquo;).</p>
                <p>By clicking the &ldquo;accept&rdquo; button in your online account, you confirm that you agree to these terms.</p>
                <p>You will also be asked to click the &ldquo;accept&rdquo; button in your online account to confirm that you agree to the competition specific terms which are set out in the competition details page.</p>
                <p>Please retain a copy of these terms for future reference.</p>
                
                <ul className="no-pad">
                    <li>
                        <strong>1. The Promoter</strong>
                    </li>
                </ul>
                <div>
                The promoter is: HotDraw UK Ltd - trading as HotDraw UK (the “	<strong>Promoter</strong>”), Company Number 12524803 and whose registered
                    office is at 2 Glamis Road, Newquay. TR7 2RY.
                </div>
                <div>
                If you wish to contact us for any reason, please email <a href="mailto:help@hotdraw.co.uk" target="_blank">help@hotdraw.co.uk</a>
                </div>
                <ul className="no-pad">
                    <li>
                        <strong>2. The competition</strong>
                    </li>
                    <ul>
                        <li>
                            1. These terms and conditions apply to all competitions listed on the
                            Promoter’s website at <a href="http://www.hotdraw.co.uk/" target="_blank">
                                www.hotdraw.co.uk
                            </a> (the “<strong>Website</strong>”)
                        </li>
                        <li>
                            2. To be in with a chance of winning, everyone who enters the
                            competition (an “<strong>Entrant</strong>”) will be required:
                        </li>
                        <ul>
                            <li>
                                a. Purchase a ticket in accordance with clause 3.6; or
                            </li>
                            <li>
                                b. Follow the free entry route set out at clause 3.11.
                            </li>
                        </ul>
                    </ul>
                </ul>
                <div>
                    <br/>
                </div>
                <ul className="no-pad">
                    <li>
                        <strong>3. How to enter</strong>
                    </li>
                    <ul>
                        <li>
                            1. The competition will run from and including the opening and closing
                            dates specified on the Website. These dates shall be referred to as
                the "<strong>Opening Date</strong>" and "			<strong>Closing Date</strong>" respectively. All times and dates
                            referred to are the times and dates in London, England.
                        </li>
                        <li>
                            2. If it is absolutely necessary to do so, the Promoter reserves the
                            right to change the Opening and Closing Dates. If the Promoter does
                            change the Opening Date and/or the Closing Date of a competition,
                            the new details will be displayed on the Website. The Promoter will
                            not extend the Closing Date simply to sell more entries.
                        </li>
                        <li>
                            3. All competition entries must be received by the Promoter by no
                            later than the specified time on the Closing Date. All competition
                            entries received after the specified time on the Closing Date are
                            automatically disqualified and no refunds will be given.
                        </li>
                        <li>
                            4. The maximum number of entries per competition its limited to 50.
                            The number of entries you are able to make may be limited if the
                            maximum number of entries is reached.
                        </li>
                        <li>
                            5. Entrants submitting free entries must submit each entry separately.
                            Bulk entries, if received, will not be accepted and will only be
                            counted as one single entry.
                        </li>
                        <li>
                            6. To enter a competition through the Website you must:
                        </li>
                        <ul>
                            <li>
                                a. Create an account on the Website by providing your name, postal
                                address, email address and contact number (you acknowledge that
                                these details will be used to contact you in the event you win
                                the competition);
                            </li>
                            <li>
                                b. following the creation of an account, you may either:
                            </li>
                            <li>
                                c. purchase a competition ticket for the sum set out in the
                                relevant entry application (payable in pounds sterling); or
                            </li>
                            <li>
                                d. enter the competition via the free entry route in accordance
                                with clause 3.11.
                            </li>
                        </ul>
                        <li>
                            7. All entries must be submitted in the English language. Entries in
                            languages other than English will automatically be disqualified.
                        </li>
                        <li>
                            8. Unless you are using the free entry method, the Promoter will send
                            confirmation that your entry has been received.
                        </li>
                        <li>
                            9. The Promoter will <strong>not</strong> accept responsibility for
                            competition entries that are not successfully completed, are lost
                            or are delayed regardless of cause, including, for example, as a
                            result of any equipment failure, technical malfunction, systems,
                            satellite, network, server, computer hardware or software failure
                            of any kind.
                        </li>
                        <li>
                            10. By purchasing entries and submitting a competition entry, you are
                            entering into a contract with the Promoter and are agreeing to be
                            bound by these terms and conditions.
                        </li>
                        <li id="free">
                            11. You may enter the competition for free by complying with the
                            following conditions:
                        </li>
                        <ul>
                            <li>
                                a. send your entry by first or second class post to the Promoter
                                at the following address: 2 Glamis Road, Newquay. TR7 2RY
                            </li>
                            <li>
                                b. hand delivered entries will not be accepted and will not be
                                entered into the random draw;
                            </li>
                            <li>
                                c. include with your entry the following information:
                            </li>
                            <ul>
                                <li>
                                    your full name;
                                </li>
                                <li>
                                    your address;
                                </li>
                                <li>
                                    a contact telephone number and email address.
                                </li>
                            </ul>
                            <li>
                                d. incomplete or illegible entries will be disqualified;
                            </li>
                            <li>
                                e. you may make multiple free entries for any competition (up to
                                any limit placed on entries by the Promoter) but each free
                                entry must be submitted and posted to the Promoter separately.
                                Bulk entries in one envelope will not be accepted as multiple
                                entries and if a bulk entry is received, it will be counted as
                                one single entry;
                            </li>
                            <li>
                                f. by entering the competition, you are confirming that you are
                                eligible to enter and accept these terms and conditions;
                            </li>
                            <li>
                                g. your entry must be received by the Promoter prior to the
                                Closing Date. Entries received after the Closing Date will not
                                be entered into the random draw. Proof of posting does not
                                guarantee that you will be entered into the random draw;
                            </li>
                            <li>
                                h. if the number of entries received reaches any cap or limit
                                before your free entry is received, you will not be entered
                                into the random draw.
                            </li>
                        </ul>
                    </ul>
                    <li>
                        <strong>4. Choosing a winner</strong>
                    </li>
                    <ul>
                        <li>
                            1. All Entrants will be placed into a draw and the winner will be
                            chosen by a Random Online Entrant Generator. The random draw will
                            take place as soon as reasonably possible and, in any event, within
                            14 days of the Closing Date ("<strong>Draw Date</strong>").
                        </li>
                        <li>
                            2. All Entrants will have their names include on a spreadsheet which
                            may be published on the Website and may be visible during the live
                            draw. If you do not wish to have your name included on this
                            spreadsheet you must contact the Promoter via email at <a href="mailto:help@hotdraw.co.uk" target="_blank">help@hotdraw.co.uk</a> as soon as reasonably possible after you have completed your entry
                            and in any event, at least 48 hours before the live draw takes
                            place.
                        </li>
                    </ul>
                    <li>
                        <strong>5. Eligibility</strong>
                    </li>
                    <ul>
                        <li>
                            1. The competition is only open to all residents in the United Kingdom
                            aged 18 years or over, <strong>except</strong>:
                        </li>
                        <ul>
                            <li>
                                a. employees of the Promoter;
                            </li>
                            <li>
                                b. employees of agents or suppliers of the Promoter, who are
                                professionally connected with the competition or its
                                administration; or
                            </li>
                            <li>
                                c. members of the immediate families or households of (a) and (b)
                                above.
                            </li>
                        </ul>
                        <li>
                            2. By entering the competition, you confirm that you are eligible to
                            do so and eligible to claim any prize you may win. The Promoter may
                            require you to provide proof that you are eligible to enter the
                            competition and claim the prize. If you fail to provide the
                            Promoter with any such proof or other information that they may
                            require within a reasonable time, you may be disqualified from the
                            competition.
                        </li>
                        <li>
                            3. The Promoter will not accept competition entries that are:
                        </li>
                        <ul>
                            <li>
                                a. automatically generated by computer; or
                            </li>
                            <li>
                                b. incomplete.
                            </li>
                        </ul>
                        <li>
                            4. The Promoter reserves all rights to disqualify you if your conduct
                            is contrary to the spirit or intention of the prize competition.
                            This includes if you are rude or abusive to the Promoter or anyone
                            associated with them.
                        </li>
                        <li>
                            5. Subject to clause 11.3 below, no refunds of the entry fee will be
                            given in any event, including;
                        </li>
                        <ul>
                            <li>
                                a. if, following your entry into the competition, you subsequently
                                find out that you are not eligible to enter the competition or
                                claim the Prize;
                            </li>
                            <li>
                                b. if, following your entry into the competition the eligibility
                                criteria for entering the competition or claiming the Prize
                                changes and you are no longer eligible; or
                            </li>
                            <li>
                                c. if you are disqualified from the competition by the Promoter
                                for any reason.
                            </li>
                        </ul>
                        <li>
                            6. If the Entrant engages in:
                        </li>
                        <ul>
                            <li>
                                a. any form of fraud (actual or apparent);
                            </li>
                            <li>
                                b. fraudulent misrepresentation;
                            </li>
                            <li>
                                c. fraudulent concealment;
                            </li>
                            <li>
                                d. hacking or interference with the proper functioning of the
                                Website; or
                            </li>
                            <li>
                                e. amending, or unauthorised use of, any of the code that
                                constitutes the website
                            </li>
                        </ul>
                    </ul>
                </ul>
                <div>
                    all of their entries will be declared void, no refunds will be given and
                    they may be prevented from participating in any future competitions.
                </div>
                <ul className="no-pad">
                    <li>
                        <strong>6. The prize</strong>
                    </li>
                    <ul>
                        <li>
                            1. The prize for each competition is described on the Website (the “<strong>Prize</strong>”). Details of the Prize are, to the best of
                            the Promoter’s knowledge, information and belief, correct as at the
                            Opening Date.
                        </li>
                        <li>
                            2. Prizes are subject to availability. The Promoter reserves the right
                            to substitute any prize with a prize of equal or greater value. If
                            any details of the Prize change, the Promoter will endeavour to
                            update the Website as soon as reasonably possible.
                        </li>
                        <li>
                            3. The Promoter makes no representations and gives no warranties about
                            the Prize, its value, its condition or any other information
                            provided on the Website. The Promoter makes no representations and
                            gives no warranties that the information provide on the Website is
                            accurate, complete or up to date.
                        </li>
                        <li>
                            4. The Prize may be supplied by a third-party supplier (the “			<strong>Supplier</strong>”). Details of the Supplier (if any) will
                            be provided on the Website.
                        </li>
                        
                    </ul>
                </ul>
                <div>
                    The Promoter reserves the right to substitute the Prize for an alternative
                    cash prize (“<strong>Cash Prize</strong>”) in the following circumstances:
                </div>
                <ul className="no-pad">
                    <ul>
                        <ul>
                            <li>
                                a. the Prize becomes unavailable;
                            </li>
                            <li>
                                b. other circumstances beyond the reasonable control of the
                                Promoter make it necessary to do so.
                            </li>
                        </ul>
                        <li>
                            1. The prize is not negotiable or transferable.
                        </li>
                    </ul>
                    <li>
                        <strong>7. Winners</strong>
                    </li>
                    <ul>
                        <li>
                            1. The decision of the Promoter<strong> </strong>is final and no
                            correspondence or discussion will be entered into.
                        </li>
                        <li>
                            2. The Promoter will contact the winner personally as soon as
                            practicable after the Draw Date, using the telephone number or
                            email address provided with the competition entry. If the winner
                            cannot be contacted or is not available, or has not claimed the
                            Prize within 14 of days of the Draw Date, the Promoter reserves the
                            right to carry out the draw again from the eligible entrants of the
                            original competition.
                        </li>
                        <li>
                            3. The Promoter must either publish or make available information that
                            indicates that a valid award took place. To comply with this
                            obligation the Promoter will publish the name of major prize
                            winners on the Website and across the social media platforms.
                        </li>
                        <li>
                            4. If you object to name being published or made available, please
                            contact the Promoter at <a href="mailto:help@hotdraw.co.uk" target="_blank">
                                help@hotdraw.co.uk
                            </a>
                            prior to the Closing Date. In such circumstances, the Promoter must
                            still provide the information to the Advertising Standards
                            Authority on request.
                        </li>
                    </ul>
                    <li>
                        <strong>8. Claiming the prize</strong>
                    </li>
                    <ul>
                        <li>
                            1. You must claim the Prize personally. The Prize may not be claimed
                            by a third party on your behalf. Details of how the Prize will be
                            delivered to you (or made available for collection) are published
                            on the Website.
                        </li>
                        <li>
                            2. If your personal details, including contact information, changes at
                            any time you should notify the Promoter as soon as reasonably
                            possible. Notifications should be sent to the Promoter via email to
                            <a href="mailto:help@hotdraw.co.uk" target="_blank">
                                help@hotdraw.co.uk
                            </a>
                            . Notifications must include details of the competition you have
                            entered, your old details and your new details. If your details
                            change within 10 days of the Closing Date, the Promoter will use
                            your old details if it needs to try to contact you.
                        </li>
                        <li>
                            3. Any Cash Prize will be transferred directly to the winners
                            nominated bank account. The winner must provide evidence that it is
                            the sole or joint beneficiary of the bank account. Failure to do so
                            within 14 days will result in disqualification from the competition
                            and the winner forfeiting the prize. In such circumstances, the
                            Promoter reserves the right to carry out the draw again from the
                            eligible entrants of the original competition.
                        </li>
                        <li>
                            4. The Promoter does not accept any responsibility and is not liable
                            to pay any compensation if you are unable to or do not take up the
                            prize.
                        </li>
                    </ul>
                    <li>
                        <strong>9. Limitation of liability</strong>
                    </li>
                </ul>
                <div>
                    Insofar as is permitted by law, the Promoter, its agents or distributors
                    will not in any circumstances be responsible or liable to compensate the
                    winner or accept any liability for any loss, damage, personal injury or
                    death occurring as a result of taking up the prize except where it is
                    caused by the negligence of the Promoter, its agents or distributors or
                    that of their employees. Your statutory rights are not affected.
                </div>
                <ul className="no-pad">
                    <li>
                        <strong>10. Data protection and publicity</strong>
                    </li>
                    <ul>
                        <li>
                            1. By entering the competition, you agree that any personal
                            information provided by you with the competition entry may be held
                            and used only by the Promoter or its agents and suppliers to
                            administer the competition or as otherwise set out in the
                            Promoter’s Privacy Policy (
                            <a href="https://hotdraw.co.uk/privacy" target="_blank">
                                <em>https://hotdraw.co.uk/privacy</em>
                            </a>), a copy of which is available on the Website.
                            
                        </li>
                        <li>
                            2. If you are the winner of the competition, you agree that the
                            Promoter may use your name, image and town or county of residence
                            to announce the winner of this competition. You further agree to
                            participate in any reasonable publicity required by the Promoter.
                        </li>
                        <li>
                            3. If you do not wish to participate in any publicity, you must notify
                            the Promoter prior to the Closing Date. This will not affect your
                            chances of winning the Prize. If you do not agree to participate in
                            any publicity about the competition we may still provide your
                            details to the Advertising Standards Authority. This is a legal
                            requirement that we must comply with to prove that the competition
                            has been properly administered and the Prize awarded.
                        </li>
                        <li>
                            4. If you are the winner of the competition, you may be required to
                            provide further personal information and proof of your identity in
                            order to confirm your eligibility to claim the Prize and transfer
                            ownership of the Prize to you. You consent to the use of your
                            information in this way. You are entitled to request further
                            details about how your personal information is being used. You may
                            also withdraw your consent to your personal information being used
                            in such way but by doing so you may prevent the Prize being
                            transferred to you. In such circumstances, you will be deemed to
                            have withdrawn from the competition and forfeit the Prize. You will
                            not be entitled to any refund of your entry fee. The Promoter
                            reserves the right to offer the Prize to the next eligible Entrant
                            selected from the correct entries that were received before the
                            Closing Date.
                        </li>
                        <li>
                            5. Please note that under data protection laws you are entitled to
                            request that the Promoter does not contact you and removes your
                            details from its database. If you make such a request you will be
                            withdrawing from the competition as it will not be possible to
                            contact you in the event that you are the winner. You will not be
                            entitled to any refund of any entry fee if you withdraw from the
                            competition. If you do not wish any of your personal details to be
                            used by the Promoter for promotional purposes, please email the
                            Promoter at <a href="mailto:help@hotdraw.co.uk" target="_blank">
                                help@hotdraw.co.uk
                            </a> prior to the Closing Date.
                        </li>
                    </ul>
                    <li>
                        <strong>11. General</strong>
                    </li>
                    <ul>
                        <li>
                            1. The Promoter reserves the right to amend these terms and conditions
                            from time to time. The latest version of these terms and conditions
                            will be available on the Website.
                        </li>
                        <li>
                            2. If there is any reason to believe that there has been a breach of
                            these terms and conditions, the Promoter may, at its sole
                            discretion, reserve the right to exclude you from participating in
                            the competition and any future competitions.
                        </li>
                        <li>
                            3. The Promoter reserves the right to hold void, suspend, cancel, or
                            amend the prize competition where it becomes necessary to do so for
                            circumstances out of its control. In such circumstances, the
                            Promoter will refund any entry fees you have paid.
                        </li>
                        <li>
                            4. There is no minimum number of entries and the Promoter will not
                            hold void, suspend, cancel, extend the Closing Date or amend the
                            prize competition due to a lack of entries. The draw will take
                            place and the Prize will be awarded regardless of the number of
                            entries received.
                        </li>
                        <li>
                            5. The competitions on the Website are in no way sponsored, endorsed,
                            administered by or associated with Facebook. By entering the
                            competitions, Entrants agree that Facebook has no liability and is
                            not responsible for the administration or promotion of the
                            competitions.
                        </li>
                        <li>
                            6. These terms and conditions shall be governed by English law, and
                            the parties submit to the exclusive jurisdiction of the courts of
                            England and Wales.
                        </li>
                        <li>
                            7. You should print a copy of these terms and conditions and keep them
                            for your records.
                        </li>
                    </ul>
                </ul>
            </div>    

        </div>
    )
};

export default ProductTerms;