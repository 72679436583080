import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import cogoToast from 'cogo-toast';
// import PaypalButton from '../Paypal';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import CheckoutForm from '../Stripe/index';
import TrustBox from '../Trustpilot/index';
import HttpsIcon from '@material-ui/icons/Https';
import { PayPalButton } from "react-paypal-button-v2";


import {
	Elements,
  } from '@stripe/react-stripe-js';
// import { Checkbox } from '@material-ui/core';
import './style.scss';
import DiscountForm from '../Discount';


// const PAYPAL_CLIENT = {
// 	sandbox:  process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX,
// 	production: process.env.REACT_APP_PAYPAL_CLIENT_ID_PRODUCTION,
// };

const PAYPAL_CLIENT = process.env.REACT_APP_PAYPAL_CLIENT_ID_PRODUCTION

// const STRIPE_PUBLIC_KEY = process.env.STRIPE_PUBLIC_KEY;
const STRIPE_CLIENT = {
	production: process.env.STRIPE_PUBLIC_KEY,
};

const PAYPAL_ENV = process.env.REACT_APP_PAYPAL_ENV === 'production' ? 'production' : 'sandbox';
// const PAYPAL_ENV = 'production'

const STRIPE_ENV = STRIPE_CLIENT.production;



const INITIAL_STATE = {
	error: null,
	loading: true,
	authUser: null,
	billing: null,
	paymentOption: '',
	customerNotes: '',
	cart: [],
	acceptTerms: false,
	stripe: null,
	totalDiscount: null,
	totalDiscountId: null,
	CART_TOTAL: 0,
};

const percentage = (percent, total) => {
	return ((percent/ 100) * total).toFixed(2)
}

const showDiscount = (total, discount) => {
	return total - percentage(discount, total);
}

class Checkout extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			...INITIAL_STATE,
			authUser: JSON.parse(localStorage.getItem('authUser')),
			settings: JSON.parse(localStorage.getItem('settings')),
			paymentLoading: false,
		};

	}
	
	componentDidMount() {
		document.title = 'Checkout';
		
		const { authUser, cart } = this.state;
		const self = this;

		const getStripe = self.props.firebase.functions().httpsCallable('getStripeKey');
		// console.log({getStripe})
		getStripe().then((res) => {
			if (res && res.data) {
				const stripe = window.Stripe(res.data);
				
				this.setState({ 
					stripe: stripe,
				});
			}
		})

		// console.log('did mount')
		if(authUser && cart){
			/* This will also be triggered when new items are added to or removed from cart  */
			self.unsubscribeCartItems = self.props.firebase.cartItems().doc(authUser.uid).onSnapshot(snapshot => {
				let updatedCart = []; /* Set empty array cart by default */
				
				if(snapshot.data() && Object.keys(snapshot.data()).length){
					/* Loop through list of cart item IDs  */
					Object.values(snapshot.data()).forEach(function (snapshotCart, index) {
						/* Query the items based on item ID */
						self.unsubscribeProduct = self.props.firebase.product(snapshotCart.id).onSnapshot(snapshotProduct => {
							/* If we receive a change on details of our product, we first check if it exists in our current cart state */
							let is_existing = updatedCart.find(item => snapshotProduct.id === item.id);
							if(is_existing){
								/* If existing, we find the position (index) of this item in our current cart state */
								let itemIndex = updatedCart.findIndex(item => snapshotProduct.id === item.id);
								/* Then we remove the item from the current cart state because we will append the updated item in our next code bellow (updatedCart.push())*/
								updatedCart.splice(itemIndex, 1); 
							}
							
							/* Append to cart array to reload entire cart item list */
							updatedCart.push({...snapshotCart, ...snapshotProduct.data()});
							self.setState({cart: updatedCart, loading: false}); /* !!!! setState is running multiple times here, figure out how to detect when child_added completed*/
						});
					});
				} else {
					self.setState({cart: [], loading: false})
				}
			});
			
			/* Get billing user, we use firebase instead of authUser from localStorage so we can listen to 
			   any data changes on the account page, like if they decide to change their address. */
			self.unsubscribeBilling = self.props.firebase.user(authUser.uid).onSnapshot(snapshot => {
				self.setState({billing: snapshot.data()});
			});


			// Get the discounts collection
			let tempDiscountArr = [];
			self.unsubscribeDiscounts = self.props.firebase.discounts().onSnapshot(snapshot => {
				let dArr = snapshot.docs;
				dArr.map((discountDoc) => {
					let thisId = discountDoc.id;
					let thisObj = {};
					thisObj[thisId] = discountDoc.data();
					tempDiscountArr.push(thisObj);
				});
				self.setState({discounts: tempDiscountArr});
			});

			
			
			
		} else {
			self.setState({ loading: false });
		}
		if (window.Stripe) {
			
			
			
		}

		ReactGA.ga('send', 'pageview', '/checkout');


	}
	
	onChange = event => {
		this.setState({ [event.target.name]: event.target.value });
	}
	
	addUserDiscount = async code => {
		const { authUser } = this.state;

		let userRef = this.props.firebase.user(authUser.uid);
		
		// let discountRef = this.props.firebase.discounts().doc(code).get();
		
		await userRef.update({
			discounts: this.props.firebase.fieldValue.arrayUnion(code)
		});

		this.setState({'discountCodeId': code})
		console.log(this.state)

		// await discountRef.update({
		// 	users: this.props.firebase.fieldValue.arrayUnion(authUser.email)
		// });

		return;
	}

	checkUserDiscount = async code => {
		const { authUser } = this.state;
		let hasCode = false;

		let userRef = this.props.firebase.user(authUser.uid);

		return await userRef.get().then(snapshotUser => {
			if (snapshotUser) {
				let data = snapshotUser.data();
				const { discounts } = data; // arr
				// console.log({discounts})
				if (discounts && discounts.includes(code)) {
					return code;
				}

				return;
			}
			return;
		}).catch((err) => {
			console.error(err);
			return;
		})
	}
	
	onPlaceOrder = () => {
		if(window.confirm('Are you sure you want to proceed placing the order?')){
			this.processOrder('Pending');
		}
	}

	throwError = (error, dataObject, showMessage = true) => {
		const { authUser, paymentOption, customerNotes, billing, cart, totalDiscount, totalDiscountId } = this.state;

		// let newErr = JSON.stringify(error);
		// if (newErr.indexOf("\"") > -1) {
		// 	newErr.replace(/\"/gi, '');
		// }
		let errorsRef = this.props.firebase.errors();
		let errCode = Math.floor(100000 + Math.random() * 900000);

		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();

		today = dd + '/' + mm + '/' + yyyy;

		let time = new Date().toLocaleTimeString();
		
		// Add it to erros DB.
		if (errorsRef) {
			errorsRef.add({
				code: errCode,
				message: error,
				date: today,
				time,
				userData: {
					uid: authUser.uid ? authUser.uid : 'null',
					paymentOption: paymentOption ? paymentOption : 'null',
					cart,
				},
				emailAddress: authUser.email,
				meta: navigator.userAgent ? navigator.userAgent : 'null',
				dataObject: dataObject ? dataObject : {},
			})
			.then((docRef) => {
				// Add friendly front end message that it could fail. Set expectations. 
				if (showMessage) {
					cogoToast.error(<div className="payment-err">
						<h3>Sorry, there was an error!</h3>
						<p>We've logged the issue and will look into it ASAP.</p>
						<p>Please <a href="mailto:josh@hotdraw.co.uk">email us</a> quoting number <strong>{errCode}</strong></p>
					</div>, {
						hideAfter: 10
					})
				}
			
			})
			.catch((error) => {
				console.error("Error adding document: ", error);
			});
		}
		
	}
	
	processOrder(status){
		const { authUser, paymentOption, customerNotes, billing, cart, totalDiscount, totalDiscountId, discountCodeId } = this.state;
		
		try {
			const self = this;
			
			let ordersRef = this.props.firebase.orders();
			let cartRef = this.props.firebase.cartItems();
			let discountsRef = this.props.firebase.discounts();
			
			
			
			if (status === 'Completed') {
				/* Get current cart contents */
				cartRef.doc(authUser.uid).get().then(snapshotCart => {
					/* Insert initial order data */
					let orderDoc = ordersRef.doc();

					if (!orderDoc) return false;

					orderDoc.set({
						customerID: authUser.uid,
						customerEmail: authUser.email,
						paymentOption: paymentOption,
						customerNotes: customerNotes,
						billing: {
							firstName: billing.firstName,
							lastName: billing.lastName,
							phone: billing.phone,
							address: billing.address,
							city: billing.city, 
							postCode: billing.postCode,
							county: billing.county ? billing.county : '',
							country: billing.country ? billing.country : 'United Kingdom'
						},
						createdAt: new Date().getTime(),
						isCancelled: false,
						codeDiscount: totalDiscount,
						codeDiscountId: totalDiscountId
					})
					.then(() => {
						let updatedCart = [];
						let sellers = [];
						let others = [];
						let total = cart.length ? cart.reduce((sum, i) => (
							sum += showDiscount((i.quantity * i.purchasePrice) + (i.quantity * i.shippingValue), i.discount)
						), 0).toFixed(2) : 0;
						
						if (totalDiscount) {
							total = showDiscount(total, totalDiscount);
						}

						
						
						Object.values(snapshotCart.data()).forEach(function (cartItem, index) {
							/* Attach order ID to each cart item array */	
							updatedCart.push({ ...cartItem, orderID: orderDoc.id });
							
							
							/* Create an array containing ids of seller owners, this will be used for retreiving seller orders later on. */
							if( ! sellers.includes(cartItem.sellerID) ){
								sellers.push(cartItem.sellerID);
								
								/* Add other information per seller. */
								others.push({sellerID: cartItem.sellerID, status: status});
							}
							
							/* Subtract stock from the items */
							self.props.firebase.product(cartItem.id).update({ 'stock': self.props.firebase.fieldValue.increment(-cartItem.quantity) });
							
						});
						
						/* Update the order by attaching the updated cart items and sellers. */
						
						ordersRef.doc(orderDoc.id)
							.update({
								cart: updatedCart, 
								others: others, 
								sellers: sellers,
								total,
								emailData: this.state.cart,
								date: new Date().toLocaleString('en-GB'),
								codeDiscount: discountCodeId ? discountCodeId : 'null'
							})
							.then(() => {
								// Send Ecommerce event
								let pixelContents = [];
								ReactGA.ga('require', 'ecommerce');
								for (let i = 0; i < this.state.cart.length; i++) {
									const cartItem = this.state.cart[i];

									ReactGA.ga('ecommerce:addItem', {
										id: cartItem.id,
										name: cartItem.name,
										sku: '--',
										category: cartItem.category,
										price: `${cartItem.price}`,
										quantity: cartItem.quantity, // We only have 1 of each item, so this is a static value
										currency: 'GBP',
									});

									// const obj = {
									// 	id: cartItem.id,
									// 	quantity: cartItem.quantity,
									// 	name: cartItem.name,
									// 	category: cartItem.category,
									// 	price: parseFloat(cartItem.price).toFixed(2),
									// }

									pixelContents.push(cartItem.id);
								}

								ReactGA.ga('ecommerce:addTransaction', {
									id: orderDoc.id,
									affiliation: 'HotDraw UK',
									revenue: `${total}`,
									shipping: '0',
									tax: '1.2',
									currency: 'GBP',
								});

								ReactGA.ga('ecommerce:send');
								ReactGA.ga('ecommerce:clear');
								
								ReactPixel.track('Purchase', {
									content_ids: pixelContents,
									content_type: 'product',
									value: parseFloat(total).toFixed(2),
									currency: 'GBP'
								});
						
								/* Delete all cart items of this user. */
								cartRef.doc(authUser.uid)
									.delete()
									.then(() => {
										self.props.history.push('/checkout/success/' + orderDoc.id);
									})
									.catch(error => {
										console.log('cartRef.doc(authUser.uid) error: ', error);
										this.setState({ error });
									});


								
							})
							.catch(error => {
								console.log('ordersRef.doc(orderDoc.id) error: ', error);
								this.setState({ error });
							});
						
						cogoToast.success("Order submitted");

						ReactGA.ga('send', 'order success', 'order was successful');
						
							
						// Add User email to discount doc if used
						if (totalDiscountId) {
							// Attach Discount Code to User
							this.addUserDiscount(totalDiscountId); 
						}

					})
					.catch(error => {
						// console.log('last one error: ', error);
						this.setState({ error });
					});
				});
			} else {
				return false;
			}

		} catch (error) {
			this.throwError(error)
		}
	}

	onRemoveFromCart = id => {
		if(window.confirm('Are you sure you want to delete this product from your cart?')){
			const { authUser, cart } = this.state;
			
			let is_existing = Object.keys(cart).length && Object.values(cart).find(item => id === item.id); /* Check if item exists in cart from state */
			if(is_existing){
				let cartRef = this.props.firebase.cartItems();
				
				/* Get current cart contents */
				cartRef.doc(authUser.uid).get().then(snapshot => {
					let updatedCart = Object.values(snapshot.data()); /* Clone it first */
					let itemIndex = updatedCart.findIndex(item => id === item.id); /* Get the index of the item we want to delete */
					
					/* Remove item from the cloned cart state */
					updatedCart.splice(itemIndex, 1); 

					/* Set updated cart in firebase, no need to use setState because we already have a realtime cart listener in the componentDidMount() */
					cartRef.doc(authUser.uid).set(Object.assign({}, updatedCart)).then(() => {
						cogoToast.success("Removed from cart");
					});
				});
			}

			ReactGA.event({
				category: 'Cart',
				action: 'Click',
				label: 'Removed item'
			});

		}
	}
	
	
	/* On unmount, we remove the listener to avoid memory leaks from using the same reference with the off() method: */
	componentWillUnmount() {
		this.unsubscribeCartItems();
		this.unsubscribeBilling();
		this.unsubscribeDiscounts();
		this.unsubscribeProduct && this.unsubscribeProduct();
	}
		
	render() {
		const { error, loading, cart, paymentOption, billing, settings, acceptTerms, acceptName, stripe, authUser, discounts, totalDiscount } = this.state;
		const isInvalid = paymentOption === '' || ! billing.firstName || ! billing.lastName || ! billing.phone || ! billing.address || ! billing.city || ! billing.postCode || ! billing.state || ! billing.country;
		const hasOutOfStock = Object.keys(cart).length && Object.values(cart).find(item => item.stock === 0) ? 1: 0;
		const validAccount = () => {
			let pass = true;
			if (!billing) {
				pass = false;
				return;
			}
			if (! billing.firstName || ! billing.lastName || ! billing.phone || ! billing.address || ! billing.city || ! billing.postCode) {
				pass = false;
			}
			return pass;
		}

		
		const onSuccessBank = (payment) => {
			this.setState({ paymentOption: 'Bank Transfer' });
			this.processOrder('Completed');
		}

		const onSuccessPaypal = (payment) => {
			this.setState({ paymentOption: 'Paypal' });
			this.processOrder('Completed');
		}

		const onError = (error) => {
			cogoToast.error(error.message, {hideAfter: 10});
			this.throwError('PayPal Cogo', {
				// data,
				error,
			}, false);

		}

		const onCancel = (data) => {
			cogoToast.warn("Payment cancelled");
		}
		
		const shippingOptionsObject = {
			'shippingStandard': 'Standard Shipping',
			'shippingExpedited': 'Expedited Shipping',
			'shippingSameDayDelivery': 'Same Day Delivery',
		}

		const checkboxToggle = (e) => {
			
			if (e.target.name === 'acceptTerms') {
				this.setState({ acceptTerms: true })
			}
		}		
		
		// Add Discount to Cart
		const addDiscount = (discountObj) => {
			
			// Check Users Discount list first.
			const hasCode = this.checkUserDiscount(discountObj.discId);
			
			hasCode.then((res) => {
			
				// Code exists. 
				if (res) {
					cogoToast.info("It seems you have already used that code once!");
					return;
				}
				// Add discount
				this.setState({
					totalDiscount: discountObj.disc,
					totalDiscountId: discountObj.discId,
				});

				cogoToast.success("Discount successfully applied!");

				ReactGA.event({
					category: 'Discount',
					action: 'Submit',
					label: `Discount code applied`
				});

			}).catch((err) => {
				console.error(err);
				return;
			})

		}

		const AMT = cart.reduce((sum, i) => (
			sum += showDiscount((i.quantity * i.purchasePrice) + (i.quantity * i.shippingValue), i.discount)
		), 0).toFixed(2);

		
		let payTotal = cart.reduce((sum, i) => (
			sum += showDiscount((i.quantity * i.purchasePrice) + (i.quantity * i.shippingValue), i.discount)
		), 0).toFixed(2);

		
		if (totalDiscount) {
			payTotal = showDiscount(payTotal, totalDiscount);
		}
		// WeFuckingLoveDiscounts
		return (
			<div style={{backgroundColor: "#f2f2f2"}}>
				{this.state.paymentLoading ? <div className="payment-loading">
					<p>Confirming your payment, <br />please do not refresh the page</p>
				</div> : ''}
				{loading ? <div>Loading...</div> : 
					<div className="container-fluid py-3">
						<div className="row">
							<div className="col-lg-8">
								<div className="mb-3">
									
									{error && <div className="alert alert-danger">Error: {error.message}</div>}
									
									<h4 className="mb-3">Your order</h4>
									<div className="table-responsive">
										<table className="table res-tbl">
											<thead>
												<tr>
													<th>Product</th>
													<th>Shipping</th>
													<th className="text-right">Total</th>
												</tr>
											</thead>
											<tbody>
												{cart.length ? cart.map((product, index) => (
													<tr key={index}>
														<td data-label="Product">
															<Link to={{pathname: '/products/view/' + product.id}} className="d-block d-lg-flex" style={{maxWidth: '300px'}}>
																<div className="mr-3 mb-2 mb-lg-0">
																	<img src={`${product.featured_image ? product.featured_image : '/placeholder.jpg'}`} width="80" alt={product.name} />
																</div>
																<div className="d-inline-block text-dark">
												<div>{product.name}<span className="mx-2">x</span><span className="font-weight-bold">{product.quantity}</span> {product.quantity > 1 ? 'tickets' : 'ticket'}</div>
																	{!product.stock > 0 && <div className="text-danger font-weight-bold">Out of stock 
																	</div>}
																</div>
															</Link>
															{!product.stock > 0 && <button className="out-stock" onClick={() => this.onRemoveFromCart(product.id)} className="btn btn-dark">Remove</button>}
														</td>
														<td data-label="Shipping">
															{product.shippingType in shippingOptionsObject
																? <span>+{settings && settings.currencySymbol} {product.shippingValue * product.quantity} on {shippingOptionsObject[product.shippingType]}</span>
																: product.shippingType
															}
														</td>
														<td data-label="Total" className="text-right">
															{/* {product.discount ? <p>{settings && settings.currencySymbol}{showDiscount((product.purchasePrice * product.quantity) + (product.quantity * product.shippingValue), product.discount)}</p> : <p>{settings && settings.currencySymbol}{(product.purchasePrice * product.quantity) + (product.quantity * product.shippingValue)}</p>} */}
															{/* {settings && settings.currencySymbol}{(product.purchasePrice * product.quantity) + (product.quantity * product.shippingValue)} */}
															{settings && settings.currencySymbol}{product.discount ? parseFloat(showDiscount((product.purchasePrice * product.quantity) + (product.quantity * product.shippingValue), product.discount)).toFixed(2) : parseFloat((product.purchasePrice * product.quantity) + (product.quantity * product.shippingValue)).toFixed(2)}
															{product.discount ? <p className="discount">Was <span>{settings && settings.currencySymbol}{(product.purchasePrice * product.quantity) + (product.quantity * product.shippingValue)}</span> {product.discount}% Off</p> : ''}
														</td>
													</tr>
												)) : 
													<tr>
														<td>Checkout is empty</td>
													</tr>
												}
												{/* <tr>
													<td colSpan="3" className="text-center px-2">
														<p>Free delivery on everything</p>
													</td>
												</tr> */}
												<tr className="font-weight-bold text-right checkout-total">
													<td colSpan="3" data-label="Cart total" className="text-right py-2">
														{settings && settings.currencySymbol + ''}{cart.length ? cart.reduce((sum, i) => (
															sum += showDiscount((i.quantity * i.purchasePrice) + (i.quantity * i.shippingValue), i.discount)
														), 0).toFixed(2) : 0}
													</td>
												</tr>
												{totalDiscount && 
												<tr className="font-weight-bold text-right checkout-total">
													<td colSpan="3" data-label="After Discount" className="text-right py-2">
														{settings && settings.currencySymbol + ''}{cart.length ? cart.reduce((sum, i) => (
															sum += showDiscount(
																showDiscount((i.quantity * i.purchasePrice) + (i.quantity * i.shippingValue), i.discount),
																totalDiscount)
														), 0).toFixed(2) : 0}
													</td>
												</tr>
												}
												{/* <tr className="font-weight-bold text-right checkout-total">
													<td colSpan="3" data-label="" className="text-right py-2">
														Inc. VAT
													</td>
												</tr> */}
											</tbody>
										</table>
									</div>
									<Link to="/cart" onClick={() => ReactGA.event({
										category: 'Checkout',
										action: 'Click',
										label: 'Back to Cart'
									})} className="btn btn-purple font-weight-bold btn-lg mt-3">Back to Cart</Link>

									<DiscountForm list={discounts} email={authUser.email} func={obj => addDiscount(obj)} />
								</div>
							</div>
							<div className="col-lg-4 details">
								<div className="card mb-3">
									<div className="card-body">
										<h5 className="mb-3 text-center d-block">Billing Details</h5>
										
										{billing && billing.firstName && billing.lastName && billing.phone && billing.address && billing.city  && billing.postCode 
											?
												<div>
													<strong>Full name:</strong> {billing.firstName} {billing.lastName} <br />
													<strong>Phone:</strong> {billing.phone} <br />
													<strong>Address:</strong> {billing.address}, {billing.city}, {billing.postCode}, {billing.state}, {billing.country}
												</div>
											:
												<div className="alert alert-warning" role="alert">
													<p>Your account is missing some information that are required for placing an order. </p>
													<ul>
														<li>First and last name</li>
														<li>Phone number</li>
														<li>Address, City, Post code and County</li>
													</ul>
													<p>Please update your account &amp; billing details using the link bellow.</p>
												</div>
										}
										<u><Link to="/account" onClick={() => {
											ReactGA.event({
												category: 'Products',
												action: 'Click',
												label: 'Edit Details'
											})
										}}>Edit account &amp; billing details</Link></u>
										
										{!hasOutOfStock 
											? 
												<div className="text-center">
													<hr />
													<h4 className="my-3">Payment</h4>

													{/* <h3 className="hide">4012888888881881</h3> */}

													{validAccount() ? <div className="form-group checkout-terms">
														{/* <h5>Payment</h5> */}
														<p className="d-block">Check the red box below to confirm you have read and agreed with the <Link to="/terms">terms &amp; conditions</Link> and that you are over the age of 18</p>
														<div className="d-block rel">
															<label className="terms" htmlFor="acceptTerms" style={{backgroundColor: `${acceptTerms ? '#4BB543' : ''}`}}>I accept the terms</label>
															<input className="terms-input" checked={acceptTerms} onChange={(e) => checkboxToggle(e)} type="checkbox" name="acceptTerms" value="acceptTerms" />
															{/* <Checkbox checked={acceptTerms} onChange={(e) => checkboxToggle(e)} name="acceptTerms" value="acceptTerms" /> */}
															
														</div>
														
													</div> : 
													<div>
														<hr />
														<em className="text-danger">Whoops, you seem to have information missing from your account details. <br />Please update your details above to proceed to payment.</em>
													</div>}
													{acceptTerms ? <div className="mb-4 pt-2">
														{/* <p className="mt-3 font-weight-bold">Select mode of payment <span className="text-danger">*</span></p> */}
														
														{/* <div className="custom-control custom-radio">
															<input type="radio" id="customRadio1" name="paymentOption" onChange={this.onChange} value="Paypal" className="custom-control-input" />
															<label className="custom-control-label" htmlFor="customRadio1">Pay via Paypal</label>
														</div>
														<div className="custom-control custom-radio">
															<input type="radio" id="customRadio2" name="paymentOption" onChange={this.onChange} value="Bank Transfer" className="custom-control-input" />
															<label className="custom-control-label" htmlFor="customRadio2">Pay by Card</label>
														</div>
														
														
														<p className="mt-4 font-weight-bold">Order notes (optional)</p>
														<textarea type="radio" className="form-control" name="customerNotes" onChange={this.onChange} placeholder="Enter a special note to seller, instructions, etc. "></textarea> */}
															<div className="secure mb-4">
																<HttpsIcon />
																<h6 className="d-inline-block mt-2 pl-3 mb-0">Secure SSL Checkout</h6>

																
															</div>

															<Elements stripe={stripe}>
																<CheckoutForm 
																	firebase={this.props.firebase}
																	billing={this.state.billing}
																	commit={true}
																	user={authUser}
																	cart={this.state.cart}
																	currency={settings.currencyCode}
																	total={cart.length ? cart.reduce((sum, i) => (
																		totalDiscount ? 
																		sum += showDiscount(
																				showDiscount((i.quantity * i.purchasePrice) + (i.quantity * i.shippingValue), i.discount),
																				totalDiscount) :
																		sum += showDiscount((i.quantity * i.purchasePrice) + (i.quantity * i.shippingValue), i.discount)
																	), 0).toFixed(2) : 0}
																	onSuccess={onSuccessBank}
																	onError={onError}
																	onCancel={onCancel}
																/>
															</Elements>
															

															<div className="paymentCards mt-2">
																<img className="d-inline-block" src="https://firebasestorage.googleapis.com/v0/b/staging-ac5f0.appspot.com/o/images%2Flogo%2Fvisa.svg?alt=media&token=94dc1115-7c04-44ff-88fe-8ce47157ade4" alt="visa" />
																<img className="d-inline-block" src="https://firebasestorage.googleapis.com/v0/b/staging-ac5f0.appspot.com/o/images%2Flogo%2Fmastercard.svg?alt=media&token=8252d794-6fa0-4605-a913-d39cb213d800" alt="mastercard"/>
															    {/* <img className="d-inline-block" src="https://firebasestorage.googleapis.com/v0/b/staging-ac5f0.appspot.com/o/images%2Flogo%2Famex.svg?alt=media&token=b9b2dcb4-87e4-436e-9dba-71f8450b0970"/> */}
															</div>
															{/* <hr /> */}
															{/* <p>Or</p> */}

															{/* <h3>Notice</h3>

															<p>PayPal is currently down whilst we work on some improvements.</p>
															<p>Please use the secure Stripe card method above. <br />Apologies for any inconvenience.</p>

															<p>For any issues, please contact us at <a href="mailto:help@hotdraw.co.uk">help@hotdraw.co.uk</a></p> */}
															{/* <h6>Pay with PayPal</h6> */}
															{/* <PaypalButton
																client={PAYPAL_CLIENT}
																env={PAYPAL_ENV}
																commit={true}
																currency={settings.currencyCode}
																total={cart.length ? cart.reduce((sum, i) => (
																	totalDiscount ? 
																	sum += showDiscount(
																			showDiscount((i.quantity * i.purchasePrice) + (i.quantity * i.shippingValue), i.discount),
																			totalDiscount) :
																	sum += showDiscount((i.quantity * i.purchasePrice) + (i.quantity * i.shippingValue), i.discount)
																), 0).toFixed(2) : 0}
																onSuccess={onSuccessPaypal}
																onError={onError}
																onCancel={onCancel}
															/> */}
															
																
															<div className="pp-err-message">
																<p>We've brought PayPal back, but we're still working on some teething issues.</p>
																<p>If an error happens, we will be in touch to get it resolved.</p>
																<p>Feel free to use our secure Stripe method above!</p>
															</div>
															<PayPalButton
																amount={cart.length ? cart.reduce((sum, i) => (
																	totalDiscount ? 
																	sum += showDiscount(
																			showDiscount((i.quantity * i.purchasePrice) + (i.quantity * i.shippingValue), i.discount),
																			totalDiscount) :
																	sum += showDiscount((i.quantity * i.purchasePrice) + (i.quantity * i.shippingValue), i.discount)
																), 0).toFixed(2) : 0}
																locale="en_GB"
																env="production"
																shippingPreference="NO_SHIPPING"
																// createOrder={(data, actions) => {
																	// this.throwError('PayPal Create', {
																	// 	// data,
																	// 	data,
																	// }, false);
																// }}
																onSuccess={(details, data) => {
																	// alert("Transaction completed by " + details.payer.name.given_name);
																	// cogoToast.info('Transaction completed')
																	// console.log({details});
																	// console.log({data});
																	// OPTIONAL: Call your server to save the transaction
																	// return fetch("/paypal-transaction-complete", {
																	// 	method: "post",
																	// 	body: JSON.stringify({
																	// 	orderId: data.orderID
																	// 	})
																	// });
																	this.setState({loading: true})

																	this.throwError('PayPal Payment', {
																		// data,
																		details
																	}, false);

																	return onSuccessPaypal();

																}}
																options={{
																	clientId: PAYPAL_CLIENT,
																	currency: "GBP"
																}}
																catchError={onError}
																onCancel={onCancel}
																onClick={() => {
																	this.throwError('PayPal Click', {
																		// data,
																		data: {
																			error: error ? error : null,
																			cart: cart ? cart : null,
																			billing: billing ? billing : null,
																			user: authUser ? authUser : null
																		}
																	}, false);
																}}
															/>
													</div> : 
													''
													// <div><p>Tick the confirmation box above to proceed to payment</p></div>
													}

													
														{/* {
															paymentOption === 'Paypal' ?
																
															: paymentOption === 'Bank Transfer' || paymentOption === 'Cash On Delivery' ? 
																// <button disabled={isInvalid} onClick={() => this.onPlaceOrder()} className="btn btn-dark btn-lg btn-block rounded-0 px-5 py-2 f-15">Place Order</button>
																
																// <StripeForm
																// 	apiKey={STRIPE_PUBLIC_KEY}
																// 	total={cart.length ? cart.reduce((sum, i) => (
																// 		sum += (i.quantity * i.purchasePrice) + (i.quantity * i.shippingValue)
																// 	), 0).toFixed(2) : 0}
																// 	handleResult={callStipeFunction}
																// />
															
															: <em className="text-danger">Please select mode of payment to proceed</em>
														}
													 */}
													
												</div>
											: 
												<div>
													<hr />
													<em className="text-danger">There are items in your cart that are currently out of stock, please remove them to proceed with the payment. You can do this on the cart page</em>
													<Link className="btn btn-atb" to="/cart">Back to Cart</Link>
												</div>
										}
									</div>
									<TrustBox />
								</div>
							</div>
						</div>
					</div>
				}
				
				{error && <div>{error.message}</div>}
						
			</div>
		);
	}
}

const condition = authUser => !!authUser; // "!!authUser" is shorthand for: "authUser != null"

/* We use compose() because each higher order component bellow dont depend on each other
   so instead of doing:
		withRouter(withFirebase(Checkout));
   we just organize it like bellow:
*/
const CheckoutPage = compose(
	withAuthorization(condition),
	withRouter,
	withFirebase,
)(Checkout);

export default CheckoutPage;