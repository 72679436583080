import React, { Component } from "react";
// import { withFirebase } from '../Firebase';
import Collections from '../Collections/index';
import ReactGA from 'react-ga';

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			authUser: JSON.parse(localStorage.getItem('authUser')) 
		};
	}

	componentDidMount() {
		ReactGA.ga('send', 'pageview', '/winners');
	}
	
	render() {
		const { authUser } = this.state;
		return (
			<div>
				<nav className="navbar navbar-dark bg-blue">
					<span className="navbar-brand">Winners of HotDraw UK Competitions</span>
					{authUser ? <p>You are now logged-in</p> : ''}
				</nav>
				
				<div className="winners-page">

					<Collections size={false} amount={12} filters={false} ended={true} active={false}/>

					{/* <div className="card mb-3">
						<div className="card-body">
							
							<h3>My Wish List</h3>
						</div>
					</div> */}
				</div>
			</div>
		);
	}
}

export default Home;