import React, { useState, useEffect } from "react";
import ReactGA from 'react-ga';
import cogoToast from 'cogo-toast';
import './styles.scss';

const checkCode = (discountArr, userCode, email) => {
    let pass = false;
    let disc = 0;
    let discId = [];
    

    console.log({discountArr})
    // Check the code against the database.
    if (!discountArr) return;

    const specialChars = /^[@#\$%\^\&*\)\(+=._-]+$/g
    
    if (userCode.match(specialChars)) {
        console.log('matched spec chars');
        cogoToast.info("Sorry that code doesn't exist.");
    }

    // Get Active codes
    const activeCodes = discountArr.map((arrObj) => {
        let returnArr;

        Object.keys(arrObj).map((key) => {            
            if (arrObj[key] && arrObj[key].active == true) {
                discId.push(key);
                returnArr = arrObj;
            }
        });
       
        return returnArr;
    });

    console.log({activeCodes});
    
    activeCodes.map((obj, key) => {

        // console.log({obj});
        // console.log({key});

        if (!obj) return;
        // console.log('before', obj[Object.keys(obj)]);

        const objecto = obj[Object.keys(obj)];
        
        if (!objecto.code) return;
        let code = objecto.code
        let users = objecto.users;
        // console.log({code})
        if (!code) return;
        
        // console.log(
        //     code,
        //     userCode
        // )

        if (code === userCode) { // Correct code
            disc = objecto.discount;
            const id = Object.keys(obj);
            pass = true;
            discId = id[0];
            return obj;
            // if (users.length && !users.includes(email)) { // Already used?
            // } else {
            //     disc = obj[discId[key]].discount;
            //     pass = true;
            //     return obj;
            // }
        }
    });
    

    if (pass) {
        return {
            disc,
            discId,
            email,
        };
    } 

    // Show Error if False
}

const DiscountForm = (props) => {
    const [discount, setDiscount] = React.useState('');
    const [attempts, setAttempts] = React.useState(0);

    const discountsArr = props.list;
    const userEmail = props.email;
    const updateTotal = props.func;
    const codeCheck = props.check;

    const submitDiscount = async (e) => {
        e.preventDefault();


        // Wait for promise
        let discountObj = await checkCode(discountsArr, discount, userEmail);

        ReactGA.event({
            category: 'Discount',
            action: 'Submit',
            label: `Discount code: ${discount}`
        });

        setDiscount('');
        
        // console.log({discountObj});
        if (!discountObj) {
            cogoToast.info("Sorry that code doesn't exist.");
            return;
        }
        
        if (discountObj && discountObj.disc > 0) { // Pass
            updateTotal(discountObj);
            
        } else {
            // Failed, show error
            if (discount.length === 0) {
                cogoToast.info("Add your code below, you can only use them once!");
            } else {
                cogoToast.info("Sorry that code doesn't exist.");
            }
        }

        setAttempts(attempts + 1);
    }

    return (
        <div className="discount-form">
            {attempts < 4 ?  
                <div>
                    <h4>Recieved a discount code?</h4>
                    <form onSubmit={submitDiscount}>
                        <label className="d-none">
                            Enter One Time Code
                        </label>
                        <input
                        type="text"
                        className="form-control"
                        placeholder="Enter One Time Code"
                        value={discount}
                        onChange={e => setDiscount(e.target.value)} />
                        <input type="submit" className="btn btn-atb" value="Add Discount" />
                    </form>
                </div>
            : <div>
                <p>Sorry it looks like we don't have any discounts live right now.</p>
                <p>Be sure to sign up to our newsletter as that's where most get sent out!</p>
            </div>}
        </div>
    )
};

export default DiscountForm;
