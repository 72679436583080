import React, { Component, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
// import Navigation from '../Navigation/index';
import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import ReactGA from 'react-ga';
import cogoToast from 'cogo-toast';
// import TextField from '@material-ui/core/TextField';

import './styles.scss';

const SignInPage = (props) => {
	const { updateAuth } = props;

	const [toggle, setToggle] = useState('');

	return (<div className="container-fluid py-4" style={{backgroundColor: "#f2f2f2"}}>
		<div className="row">
			<div className="col-xl-6 col-sm-10 offset-xl-3 offset-sm-1">
				<div className="card mb-3 question-signin">
					<div className="card-body">
						<h4 className="mb-3 text-center">Already Registered?</h4>

						<button className="btn-atb" onClick={() => setToggle('login')}>Yes</button>
						<button className="btn-atb" onClick={() => setToggle('join')}>No</button>
					</div>
				</div>
				{toggle === 'join' ? <div className="card mb-3">
					<div className="card-body">
						<h4 className="mb-3 text-center">Sign Up</h4>
						<SignUpLink />
					</div>
				</div> : ''}
				{toggle === 'login' ? <div className="card mb-3">
					<div className="card-body">
						<h4 className="text-center mb-4">Log In</h4>
						
						<SignInForm onComplete={updateAuth} />

						<div className="mt-3">
							<PasswordForgetLink />
						</div>
						
						{/* <p className="mt-3 text-center">You can also log in <strong>quickly</strong> with <br />Google!</p> */}

						{/* <SignInFacebook /> */}
						{/* <SignInTwitter /> */}
						
						{/* <h4>Create an account</h4> */}
						
					</div>
				</div> : ''}
				
				<div className="card mb-3">
					<div className="card-body">
						<h4 className="text-center mb-4">Join or Log In With Google</h4>
						<SignInGoogle onComplete={updateAuth} />
					</div>
				</div>

			</div>
		</div>
	</div>);
};

const ERROR_CODE_ACCOUNT_EXISTS =
	'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
	An account with an E-Mail address to
	this social account already exists. Try to login from
	this account instead and associate your social accounts on
	your personal account page. If the error persists, contact a 
	member of the team at help@hotdraw.co.uk
`;

const INITIAL_STATE = {
	email: '',
	password: '',
	error: null,
};


class SignInFormBase extends Component {
	constructor(props) {
		super(props);
		this.state = { ...INITIAL_STATE };
	}
	
	componentDidMount(){
		document.title = 'Sign In';

		ReactGA.ga('send', 'pageview', '/signin');
	}

	throwError = (error, dataObject, showMessage = false) => {

		let newErr = JSON.stringify(error);
		let errorsRef = this.props.firebase.errors();
		let errCode = Math.floor(100000 + Math.random() * 900000);

		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();

		today = dd + '/' + mm + '/' + yyyy;

		let time = new Date().toLocaleTimeString();
		const data = (() =>{
			try {
				return JSON.stringify({
					code: errCode,
					message: newErr,
					date: today,
					time,
					emailAddress: this.state.email,
					meta: navigator.userAgent ? navigator.userAgent : 'null',
					dataObject: dataObject ? dataObject : {},
				})
			} catch (e) {
				return JSON.stringify({error: "failed to serialise", e});
			}
		})();
		
		
		fetch("https://4fp8wot3wa.execute-api.us-east-2.amazonaws.com/Test/writeError", {
    		method: 'POST',
			body: data,
		})
		// Add it to erros DB.
		errorsRef.add({
			code: errCode,
			message: newErr,
			date: today,
			time,
			emailAddress: this.state.email,
			meta: navigator.userAgent ? navigator.userAgent : 'null',
			dataObject: dataObject ? dataObject : {},
		})
		.then((docRef) => {
			// Add friendly front end message that it could fail. Set expectations. 
			console.log('added errror!')
			if (showMessage) {
				cogoToast.error(<div className="payment-err">
					<h3>Sorry, there was an error!</h3>
					<p>We've logged the issue and will look into it ASAP.</p>
					<p>Please <a href="mailto:josh@hotdraw.co.uk">email us</a> quoting number <strong>{errCode}</strong></p>
				</div>, {
					hideAfter: 10
				})
			}
		
		})
		.catch((error) => {
			console.error("Error adding document: ", error);
		});
		
	}

	onSubmit = event => {
		const { email, password } = this.state;

		this.props.firebase
			.doSignInWithEmailAndPassword(email, password)
			.then(() => {
				this.setState({ ...INITIAL_STATE });
				this.props.history.push(ROUTES.COLLECTIONS);

			})
			.catch(error => {
				this.setState({ error });

				cogoToast.info(<div>
					<p>Having troubles accessing your account?</p>
					<p>Contact us a help@hotdraw.co.uk</p>
				</div>, {hideAfter: 59});


				this.throwError('Signin Error', {
					// data,
					...error,
				}, false);

			});
			
		// this.props.onComplete ? this.props.onComplete(localStorage.getItem('authUser')) : null;

		event.preventDefault();
	};

	onChange = event => {
		this.setState({ [event.target.name]: event.target.value.trim() });
	};

	render() {
		const { email, password, error } = this.state;

		const isInvalid = password === '' || email === '';

		return (
			<form onSubmit={this.onSubmit}>
				<div className="form-group">
					
					<label className="d-block text-left mb-0">Email</label>
					<input
						name="email"
						value={email}
						onChange={this.onChange}
						type="text"
						placeholder="Email"
						className="form-control"
					/>
				</div>
				<div className="form-group">
					
					<label className="d-block text-left mb-0">Password</label>
					<input
						name="password"
						value={password}
						onChange={this.onChange}
						type="password"
						autoComplete="off"
						placeholder="password"
						className="form-control"
					/>
				</div>
				
				<button disabled={isInvalid} type="submit" className="btn-atb">
					Sign In
				</button>
				
				{error && <div className="alert alert-danger my-3" role="alert">
					<p>{error.message}</p>
					<br />
					<p>Having troubles accessing your account?</p>
					<a href="mailto:help@hotdraw.co.uk?subject=Account%20Issues!">Contact us a help@hotdraw.co.uk</a>
				</div>}

			</form>
		);
	}
}

class SignInGoogleBase extends Component {
	constructor(props) {
		super(props);

		this.state = { error: null };
	}

	onSubmit = event => {
		this.props.firebase
			.doSignInWithGoogle()
			.then(socialAuthUser => {
				if(socialAuthUser.additionalUserInfo) { // .isNewUser 
					// Create a user in your Firebase Realtime Database too
					let backupName = socialAuthUser.user.displayName;

					return this.props.firebase
						.user(socialAuthUser.user.uid)
						.set(
							{
								firstName: socialAuthUser.additionalUserInfo.profile.given_name ? socialAuthUser.additionalUserInfo.profile.given_name : backupName,
								lastName: socialAuthUser.additionalUserInfo.profile.family_name ? socialAuthUser.additionalUserInfo.profile.family_name : '',
								email: socialAuthUser.user.email,
								roles: [ROLES.CUSTOMER], 
								createdAt: Date.now()
							},
							{merge: true} // If the document already exists, its content will be overwritten. If the document doesn’t exist, it will be created.
						);
				}
			})
			.then(() => {
				this.setState({ error: null });
				this.props.history.push(ROUTES.COLLECTIONS);

				// this.props.onComplete ? this.props.onComplete(localStorage.getItem('authUser')) : null;
			})
			.catch(error => {
				if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
					error.message = ERROR_MSG_ACCOUNT_EXISTS;
				}
				this.setState({ error });
			});

		event.preventDefault();
	};

	render() {
		const { error } = this.state;

		return (
			<form onSubmit={this.onSubmit} className="social-logins">
				<button type="submit" className="btn btn-block text-white border-0 mb-1">
					<svg id="Capa_1" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m120 256c0-25.367 6.989-49.13 19.131-69.477v-86.308h-86.308c-34.255 44.488-52.823 98.707-52.823 155.785s18.568 111.297 52.823 155.785h86.308v-86.308c-12.142-20.347-19.131-44.11-19.131-69.477z" fill="#fbbd00"/><path d="m256 392-60 60 60 60c57.079 0 111.297-18.568 155.785-52.823v-86.216h-86.216c-20.525 12.186-44.388 19.039-69.569 19.039z" fill="#0f9d58"/><path d="m139.131 325.477-86.308 86.308c6.782 8.808 14.167 17.243 22.158 25.235 48.352 48.351 112.639 74.98 181.019 74.98v-120c-49.624 0-93.117-26.72-116.869-66.523z" fill="#31aa52"/><path d="m512 256c0-15.575-1.41-31.179-4.192-46.377l-2.251-12.299h-249.557v120h121.452c-11.794 23.461-29.928 42.602-51.884 55.638l86.216 86.216c8.808-6.782 17.243-14.167 25.235-22.158 48.352-48.353 74.981-112.64 74.981-181.02z" fill="#3c79e6"/><path d="m352.167 159.833 10.606 10.606 84.853-84.852-10.606-10.606c-48.352-48.352-112.639-74.981-181.02-74.981l-60 60 60 60c36.326 0 70.479 14.146 96.167 39.833z" fill="#cf2d48"/><path d="m256 120v-120c-68.38 0-132.667 26.629-181.02 74.98-7.991 7.991-15.376 16.426-22.158 25.235l86.308 86.308c23.753-39.803 67.246-66.523 116.87-66.523z" fill="#eb4132"/></g></svg>
					{/* Enter with Google */}
				</button>

				{error && <div className="alert alert-danger my-3" role="alert">{error.message}</div>}
			</form>
		);
	}
}

class SignInFacebookBase extends Component {
	constructor(props) {
		super(props);

		this.state = { error: null };
	}

	onSubmit = event => {
		this.props.firebase
			.doSignInWithFacebook()
			.then(socialAuthUser => {
				
				if(socialAuthUser.additionalUserInfo.isNewUser){
					
					// Create a user in your Firebase Realtime Database too
					return this.props.firebase
						.user(socialAuthUser.user.uid)
						.set(
							{
								firstName: socialAuthUser.additionalUserInfo.profile.name,
								email: socialAuthUser.additionalUserInfo.profile.email,
								roles: [ROLES.CUSTOMER], 
								createdAt: Date.now()
							},
							{merge: true} // If the document already exists, its content will be overwritten. If the document doesn’t exist, it will be created.
						);
				}
			})
			.then(() => {
				this.setState({ error: null });
				this.props.history.push(ROUTES.COLLECTIONS);
			})
			.catch(error => {
				if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
					error.message = ERROR_MSG_ACCOUNT_EXISTS;
				}
				this.setState({ error });
			});

		event.preventDefault();
	};

	render() {
		const { error } = this.state;

		return (
			<form onSubmit={this.onSubmit} className="social-logins">
				<button type="submit" className="btn btn-block border-0 mb-1">
				<svg height="512pt" viewBox="0 0 512 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m483.738281 0h-455.5c-15.597656.0078125-28.24218725 12.660156-28.238281 28.261719v455.5c.0078125 15.597656 12.660156 28.242187 28.261719 28.238281h455.476562c15.605469.003906 28.257813-12.644531 28.261719-28.25 0-.003906 0-.007812 0-.011719v-455.5c-.007812-15.597656-12.660156-28.24218725-28.261719-28.238281zm0 0" fill="#4267b2"/><path d="m353.5 512v-198h66.75l10-77.5h-76.75v-49.359375c0-22.386719 6.214844-37.640625 38.316406-37.640625h40.683594v-69.128906c-7.078125-.941406-31.363281-3.046875-59.621094-3.046875-59 0-99.378906 36-99.378906 102.140625v57.035156h-66.5v77.5h66.5v198zm0 0" fill="#fff"/></svg>
					{/* <span>Enter with Facebook</span> */}
				</button>

				{error && <div className="alert alert-danger my-3" role="alert">{error.message}</div>}
			</form>
		);
	}
}

class SignInTwitterBase extends Component {
	constructor(props) {
		super(props);

		this.state = { error: null };
	}

	onSubmit = event => {
		this.props.firebase
			.doSignInWithTwitter()
			.then(socialAuthUser => {
				
				if(socialAuthUser.additionalUserInfo.isNewUser){ 
					// Create a user in your Firebase Realtime Database too
					return this.props.firebase
						.user(socialAuthUser.user.uid)
						.set(
							{
								firstName: socialAuthUser.additionalUserInfo.profile.name,
								email: socialAuthUser.additionalUserInfo.profile.email,
								roles: [ROLES.CUSTOMER], 
								createdAt: Date.now()
							},
							{merge: true} // If the document already exists, its content will be overwritten. If the document doesn’t exist, it will be created.
						);
				}
			})
			.then(() => {
				this.setState({ error: null });
				this.props.history.push(ROUTES.COLLECTIONS);
			})
			.catch(error => {
				if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
					error.message = ERROR_MSG_ACCOUNT_EXISTS;
				}
				this.setState({ error });
			});

		event.preventDefault();
	};

	render() {
		const { error } = this.state;

		return (
			<form onSubmit={this.onSubmit}>
				<button type="submit" className="btn btn-block border-0" style={{backgroundColor: '#00b6f0'}}>Enter with Twitter</button>

				{error && <div className="alert alert-danger my-3" role="alert">{error.message}</div>}
			</form>
		);
	}
}

/* We use compose() because each higher order component bellow dont depend on each other
   so instead of doing:
		withRouter(withFirebase(SignInFormBase));
   we just organize it like bellow:
*/
const SignInForm = compose(
	withRouter,
	withFirebase,
)(SignInFormBase);

const SignInGoogle = compose(
	withRouter,
	withFirebase,
)(SignInGoogleBase);

const SignInFacebook = compose(
	withRouter,
	withFirebase,
)(SignInFacebookBase);

const SignInTwitter = compose(
	withRouter,
	withFirebase,
)(SignInTwitterBase);

export default SignInPage;

export { SignInForm, SignInGoogle };