import React, { useState } from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import cogoToast from 'cogo-toast';

const firebase = require("firebase");
// Required for side-effects
require("firebase/functions");

// const authUser = JSON.parse(localStorage.getItem('authUser'));


const CheckoutForm = (props) => {
	const stripe = useStripe();
	const elements = useElements();

	const { total, user, billing, onSuccess, onError } = props;
	const [loading, showLoading] = useState(false);
	const [errMes, showError] = useState(false);

	const userObj = {
		user,
		billing,
	}

	const fullName = `${userObj.user.firstName} ${userObj.user.lastName}`;
	
	// Attach Stripe Source
	// async function attachStripeSrouce(source, userObj) {
	// 	const attatchSource = firebase.functions().httpsCallable('stripeAttachSource')
	// 	return await attatchSource({ // Pass details
	// 		source: source.id,
	// 		user: userObj,
	// 	}).then((res, rej) => {
			
	// 		return res;
	// 	}).catch((err) => console.error(err));
		
	// }

	// Attach Stripe Charge
	// async function chargeHandler(source, total, cart) {
	// 	const chargeFunction = firebase.functions().httpsCallable('stripeCreateCharge')
	// 	return await chargeFunction({
	// 		source: source.id,
	// 		amount: total ? total : '200',
	// 		cart: cart,
	// 	}).then((res, rej) => {
	// 		return res;
	// 	}).catch((error) => {
	// 		console.error(error);
	// 		throw error;
	// 	})
	// }


	async function createIntent(total) {
		// console.log('createIntent ', total);
		const createFunction = await firebase.functions().httpsCallable('paymentIntent')
		return await createFunction({
			amount: parseFloat(total.replace(/\./g, '')),
		});
	}

	

	const handleSubmit = async (event) => {
		event.preventDefault();

		const cardElement = elements.getElement('card');

		const { source, error } = await stripe.createSource(cardElement);
		
		console.log({error});

		if (error) {
			cogoToast.error(`Payment failed: ${error.message}`);
			showError(true);
			return;
		}
		showLoading(true);

		// From here we can call createPaymentMethod to create a PaymentMethod
		// See our createPaymentMethod documentation for more:
		// https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
		await stripe
			.createPaymentMethod({
			type: 'card',
			card: cardElement,
			billing_details: {
				name: fullName,
				email: userObj.user.email,
			},
			})
			.then(({paymentMethod}) => {
				
				const result = createIntent(total);

				result.then((intentObj) => {
					const { client_secret } = intentObj.data;
					if (client_secret) {
						stripe.confirmCardPayment(client_secret, {
							payment_method: {
								card: cardElement,
								billing_details: {
									name: fullName,
									email: userObj.user.email,
								},
							},
						}).then((res) => {
							
							if (res && res.paymentIntent && res.paymentIntent.status === 'succeeded') {
								// res.on('token', function(event) {
								// 	// event.token is available
								//   });
								showLoading(false);
								// const payment = {
								// 	paid: true,
								// 	cancelled: false,
								// 	payerID: authUser.id,
								// 	paymentID: res.id,
								// 	paymentToken: data.paymentToken,
								// 	returnUrl: data.returnUrl,
								// };
								
								onSuccess(res.paymentIntent);
					
							} else {
								// console.log({res})
								const errorMessage = res.error.code;
								showLoading(false);
								cogoToast.error(`Payment failed. Error Code: ${errorMessage}`);
								onError(errorMessage);	
								showError(true);
							}
						}).catch((error) => {
							showLoading(false);
							cogoToast.error(`Payment failed: ${error.message}`);
							onError(error);
							showError(true);
						});

					}
				}).catch((err) => {
					// console.log({err})
					cogoToast.error(`Payment failed: ${err}`);
					showError(true);
					return;
				})
				
			});
			
	
		
		// // You can also use confirmCardSetup with the SetupIntents API.
		// // See our confirmCardSetup documentation for more:
		// // https://stripe.com/docs/stripe-js/reference#stripe-confirm-card-setup
		// this.props.stripe.confirmCardSetup('{PAYMENT_INTENT_CLIENT_SECRET}', {
		// 	payment_method: {
		// 	card: cardElement,
		// 	},
		// });
	
		// // You can also use createToken to create tokens.
		// // See our tokens documentation for more:
		// // https://stripe.com/docs/stripe-js/reference#stripe-create-token
		// // With createToken, you will not need to pass in the reference to
		// // the Element. It will be inferred automatically.
		// this.props.stripe.createToken({type: 'card', name: 'Jenny Rosen'});
		// // token type can optionally be inferred if there is only one Element
		// // with which to create tokens
		// // this.props.stripe.createToken({name: 'Jenny Rosen'});
	
		// // You can also use createSource to create Sources.
		// // See our Sources documentation for more:
		// // https://stripe.com/docs/stripe-js/reference#stripe-create-source
		// // With createSource, you will not need to pass in the reference to
		// // the Element. It will be inferred automatically.
		// this.props.stripe.createSource({
		// 	type: 'card',
		// 	owner: {
		// 	name: 'Jenny Rosen',
		// 	},
		// });
	};


	const CARD_OPTIONS = {
		iconStyle: 'solid',
		style: {
		  base: {
			iconColor: '#27316c',
			color: '#222222',
			fontWeight: 500,
			fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
			// fontSize: '17px',
			fontSmoothing: 'antialiased',
			':-webkit-autofill': {color: '#27316c'},
			'::placeholder': {color: '#27316c'},
		  },
		  invalid: {
			iconColor: '#e71837',
			color: '#e71837',
		  },
		  valid: {
			iconColor: '#4BB543',
			color: '#4BB543',
		  }
		},
	};


	return (
		<div className="checkout-form">
			<form onSubmit={handleSubmit}>
				{stripe && <CardElement 
					options={CARD_OPTIONS}
				/>}
				{stripe && <button className={loading && errMes ? 'btn btn-atb btn-disabled' : 'btn btn-atb'}>Pay £{total}</button>}
			</form>
			{loading && 
				<div className="loading">
					<p>Payment processing. Please do not refresh the browser.</p>
				</div>
			}
			{errMes ? <div className="stripe-error">
				<p>Whoops, looks like something went wrong.</p>
				<p>Don't worry you haven't been charged. Please check your CVC and post code.</p>
				<p>{errMes}</p>
			</div> : ''}
		</div>
	);
};




export default CheckoutForm;