import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion } from '@material-ui/core';
import { AccordionSummary } from '@material-ui/core';
import { AccordionDetails } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: '40px'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15), 
    flexBasis: '100%',
    flexShrink: 0,
    textAlign: 'left'
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    textAlign: 'left',
  },
}));

export default function FAQList() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> What is HotDraw? </Typography>
          {/* <Typography className={classes.secondaryHeading}>We're an online competition business</Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
          <span>A:</span> We're an online competition business, but in a nutshell, we offer high-end prizes to the competitions we run online. Offering paid and free entry methods
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> How is a winner chosen?</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            Always random, always fair.
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span> A winner is chosen by random using https://wheelofnames.com, an online random name picking tool, whilst live streaming it to our Facebook page!
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
          id="panel3bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> Do I get a number?</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            No numbers, just names.
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span> No, we don't use numbers. Rather than making you flick through pages of numbers to choose from, we keep it simple. Choose the number of entries and that's how many times your name will be entered into the draw.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
          id="panel4bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> Are all the prizes brand new?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span> Yup! All of our products are brand new, always in their original packaging. We only store them until a winner claims it!
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
          id="panel5bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> Do you have to use my name in the draw?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span> No, it's not a problem if you don't want us to. Contact us either through our Facebook page or via email at help@hotdraw.co.uk and one of our team will be happy to help swap the name for a unique number instead.
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6bh-content"
          id="panel6bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> What if all the tickets don't sell?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span> This would be very rare, but within our terms, we have stated that we can roll the closing date over a week up to four times. However, we've never done this before and don't plan to use it in the future. Meaning we'll likely do the draw regardless!
          </Typography>
        </AccordionDetails>
      </Accordion> */}

      <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7bh-content"
          id="panel7bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> How do I enter the competition?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span> On the home page of our website you will see a box which says &ldquo;Enter&rdquo; beneath each competition. Click that and you will be taken to the entry form.

Stage 1 is to select the number of entries you require. Stage 2 is to complete your details on the entry form and pay the entry fee. You will need to click the button to confirm that you accept our terms and conditions. You can read them by <a href="https://hotdraw.co.uk/terms">clicking here</a>.<br />

Your name will then be entered in the draw with all of the other entries.<br />

You will also receive an email confirming you have been entered into the draw.<br />

There is also a free entry route available. To enter for free, send your name, address, telephone number, and email address to us at the following address:<br />

HotDraw UK, 2 Glamis Road, Newquay. TR7 2RY<br />

Entries must be submitted via first or second class post. Bulk entries are not accepted and if received will count as one single entry.<br />
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8bh-content"
          id="panel8bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> What's the deal with delivery?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span> Once a winner is drawn, we contact them usually immediately, but within 24 hours at the latest. We then ship with either Royal Mail or DPD (for larger items). These are all signed for delivery to ensure prizes get to you safely.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9bh-content"
          id="panel9bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> How will I know if I have won?</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            No numbers, just names.
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span> We will notify the winner via telephone or email within 7 days of the closing date of the competition. If you change any of your contact details prior to the closing date, you must inform us. We will try to contact you using the information you have supplied us with. If we cannot reach you within 14 days of the draw date we reserve the right to choose another winner and you will lose your right to claim the prize.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10bh-content"
          id="panel10bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> How long is the competition open for?</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            No numbers, just names.
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span> The opening and closing date of the competitions are stated on the website. If we have to change either of these dates for any reason, we will update the website accordingly. We will only change the dates if we have to for reasons outside of our control.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel11'} onChange={handleChange('panel11')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel11bh-content"
          id="panel11bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> Can anyone enter the competition?</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            No numbers, just names.
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span> The competition is open to residents of the United Kingdom only who are 18 years or older. 

We do not except entries from anyone outside of these areas as the laws for running competitions vary. This competition has been organised to comply with the laws of England, and Wales.

Also, you cannot enter these competitions if you are a relative of any of our suppliers.

          </Typography>
        </AccordionDetails>
      </Accordion>
        
      <Accordion expanded={expanded === 'panel12'} onChange={handleChange('panel12')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel12bh-content"
          id="panel12bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> What are the prizes?</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            No numbers, just names.
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span> The prizes are described fully on the website. You can find out more details by clicking on any of the prizes on our homepage (https://hotdraw.co.uk) this will take you to the competition specific details page. 

We reserve the right to offer an alternative prize of an equal or higher value if the prize is unavailable for any reason.

          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel13'} onChange={handleChange('panel13')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel13bh-content"
          id="panel13bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> Can I sell the prize if I don’t want it?</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            No numbers, just names.
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span> If you are the winner, the prize will be yours. You can do what ever you wish with it, including selling it.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel14'} onChange={handleChange('panel14')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel14bh-content"
          id="panel14bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> How do you use my personal data?</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            No numbers, just names.
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span> We need to use your data to administer the competition and award prizes. We do not use your data for any other purpose.<br />

We do not share your data with any third parties unless this is necessary for administering the competition.<br />

Full details of how we use your data are included in our Privacy Policy which you can read here <a href="https://hotdraw.co.uk/privacy">(https://hotdraw.co.uk/privacy)</a>.<br />

If you are the winner, we may have to share your details with the Advertising Standards Authority to confirm that we have administered the competition and awarded the prizes fairly.<br />

You have the right to opt out from us using your data at any time. However, if you do ask us to remove your details from our database prior to the closing date, you will be withdrawing from the competition. You will not be entitled to a refund of any entry fees you have paid.<br />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel15'} onChange={handleChange('panel15')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel15bh-content"
          id="panel15bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> If I win, do I have to participate in promotional exercises?</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            No numbers, just names.
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span>No, this is not compulsory. However, with your permission, we would love to share your excitement on our website and social media pages.

<br />Even if you do not want to participate in any promotional exercises, we may have may have to provide your details to the Advertising Standards Authority to prove we have administered the competition and awarded the prize fairly.

          </Typography>
        </AccordionDetails>
      </Accordion>
      
      <Accordion expanded={expanded === 'panel16'} onChange={handleChange('panel16')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel16bh-content"
          id="panel16bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> How is the winner decided?</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            No numbers, just names.
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span>Everyone who enters will be entered into a random name generator draw. The winner will then be chosen at random from all the entries.
          </Typography>
        </AccordionDetails>
      </Accordion>
    
      <Accordion expanded={expanded === 'panel17'} onChange={handleChange('panel17')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel17bh-content"
          id="panel17bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> What are my chances of winning?</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            No numbers, just names.
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span>The maximum number of entries is stated on each competition so your chances of winning will vary from competition to competition. As an example, if entries are capped at a maximum of 3000, this means that if you purchase 1 entry, your chances of winning will be no worse than 1 in 3,000, if the competition sells out. If the competition does not sell out then your odds of winning will be further increased. 
 <br />
 You can increase your chances of winning by purchasing more entries. For example, if you purchase 10 entries in the example above, your chances of winning will be no worse than 1 in 300.  
  <br />
 We say “no worse than” because not all of our competitions sell out. We cannot predict how many this will be but say 500 tickets do not sell. Your chances of winning with a single correct entry will now improve to 1 in 2,500. 
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel18'} onChange={handleChange('panel18')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel18bh-content"
          id="panel18bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> I haven’t received an email confirming I have entered.</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            No numbers, just names.
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span>If you haven’t received an email from us confirming your entry, please check your spam folder. If it is not in there, please email us at <a href="mailto:help@hotdraw.co.uk">help@hotdraw.co.uk</a>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion expanded={expanded === 'panel19'} onChange={handleChange('panel19')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel19bh-content"
          id="panel19bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> Can I get a refund of my entry fee?</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            No numbers, just names.
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span>We do not offer refunds of entry fees, or if you are disqualified from the competition for any reason. 
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion expanded={expanded === 'panel20'} onChange={handleChange('panel20')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel20bh-content"
          id="panel20bh-header"
        >
          <Typography className={classes.heading}><span>Q:</span> My question hasn’t been answered here</Typography>
          {/* <Typography className={classes.secondaryHeading}>
            No numbers, just names.
          </Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography className={classes.secondaryHeading}>
            <span>A:</span>If you have any questions that have not been answered here, please email us at <a href="mailto:help@hotdraw.co.uk">help@hotdraw.co.uk</a> and we will happily answer them for you.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}